import { Check, Pencil, Plus } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import Wrapper from "./Common/Wrapper";
import CharacterConflict from "./CharacterConflict";
import { useState } from "react";
import AddMarketingAttributesModal from "components/Comman/Modals/MultipleAddModals/AddMarketingAttributes";

function Audience({ details, setDetails, onUpdate }) {
  const [editMode, setEditMode] = useState(true);
  const [editModal, setEditModal] = useState({ isOpen: false, type: "" });
  return (
    <>
      <AddMarketingAttributesModal
        isOpen={editModal?.isOpen}
        closeModal={() => {
          setEditModal({ isOpen: false });
        }}
        label={editModal?.label}
        type={editModal?.type}
        onAdd={(val) => {
          let temp = [...val];
          switch (editModal?.type) {
            case "characters":
              let char = temp?.filter((c) => c?.marketingConflict?.length > 0);
              let con = [];
              char?.map((c) => {
                con = con.concat(c?.marketingConflict);
              });
              setDetails({ ...details, characters: char, conflicts: con });
              onUpdate({ ...details, characters: char, conflicts: con });
              break;
            case "objectives":
              let obj = temp?.filter((c) => c?.keyResults?.length > 0);
              let kr = [];
              obj?.map((c) => {
                kr = kr.concat(c?.keyResults);
              });
              setDetails({ ...details, objectives: obj, keyResults: kr });
              onUpdate({ ...details, objectives: obj, keyResults: kr });
              break;
            default:
              setDetails({ ...details, [editModal?.type]: val });
              onUpdate({ ...details, [editModal?.type]: val });
          }

          setEditModal({ isOpen: false });
        }}
        selected={editModal?.selected}
      />
      <div className="p-4 bg-white rounded-2xl space-y-10">
        <div className="px-0 lg:px-6 flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:items-center justify-between text-primary-aqua-medium text-lg font-poppins font-medium">
          <p className="font-lato lg:w-8/12 text-primary-neutral-500 text-xs lg:text-sm font-light">
            Here you define and edit the target audience for your campaign based
            on the characters and conflicts you want to address
          </p>
          {/* {editMode ? (
            <div className="flex flex-row items-stretch space-x-4 lg:justify-end justify-between">
              <ChronosButton
                tertiary
                underline
                text="Cancel"
                onClick={() => {
                  setEditMode(!editMode);
                }}
              />
              <ChronosButton
                text="Save Changes"
                primary
                onClick={() => {
                  if (editMode) {
                    onUpdate();
                  }
                  setEditMode(!editMode);
                }}
                iconReverse
                icon={<Check size={16} className="mr-2.5" />}
              />
            </div>
          ) : (
            <ChronosButton
              light
              text={editMode ? "Save" : "Edit Campaign Goals"}
              primary
              onClick={() => {
                if (editMode) {
                  onUpdate();
                }
                setEditMode(!editMode);
              }}
              iconReverse
              icon={<Pencil size={16} className="mr-2.5" />}
            />
          )} */}
        </div>

        <Wrapper
          heading="Which characters & conflicts do you want to target?"
          description="Identify the target audience you’d want to direct your marketing efforts towards"
        >
          {editMode && (
            <ChronosButton
              text={`${
                details?.characters?.length > 0 ? "Edit" : "Add"
              } Characters & Conflicts`}
              tertiary
              underline
              onClick={() => {
                let temp = [...details.characters] || [];
                details?.conflicts?.map((item) => {
                  if (
                    temp?.find((o) => o?.id == item?.marketingCharacterId) &&
                    temp?.find((o) => o?.id == item?.marketingCharacterId)
                      ?.marketingConflict
                  ) {
                    let idx = temp?.findIndex(
                      (o) => o?.id == item?.marketingCharacterId
                    );
                    temp[idx].marketingConflict.push(item);
                  } else if (
                    temp?.find((o) => o?.id == item?.marketingCharacterId)
                  ) {
                    let idx = temp?.findIndex(
                      (o) => o?.id == item?.marketingCharacterId
                    );
                    temp[idx]["marketingConflict"] = [item];
                  } else {
                    temp.push({
                      ...item?.marketingCharacter,
                      marketingConflict: [item],
                    });
                  }
                });
                setEditModal({
                  isOpen: true,
                  type: "characters",
                  selected: temp,
                  label: "Characters & Conflicts",
                });
              }}
              iconReverse
              icon={
                details?.characters?.length > 0 ? (
                  <Pencil size={16} className="mr-2.5" />
                ) : (
                  <Plus size={16} className="mr-2.5" />
                )
              }
            />
          )}
          <CharacterConflict
            characters={details?.characters}
            conflicts={details?.conflicts}
          />
        </Wrapper>
      </div>
    </>
  );
}

export default Audience;
