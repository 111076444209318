import EmptyState from "components/Comman/EmptyState";
import FilterDropDown from "components/Comman/FilterDropDown";
import SearchBox from "components/Comman/Inputs/SearchBox";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as MarketingAPI from "config/APIs/marketing";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import ContentCard from "./ContentCard";

function ContentIdeas({ list, onEdit, onUpdate, onDelete }) {
  const clusters = useSelector((state) => state.marketing.topicCluster);
  const buckets = useSelector((state) => state.marketing.contentBucket);
  const contentType = useSelector((state) => state.marketing.contentTypes);
  const contentPlatform = useSelector(
    (state) => state.marketing.contentPlatform
  );
  const [query, setQuery] = useState({ tab: "recent", type: "Community" });
  const [search, setSearch] = useState({ searchText: "" });
  const [offering, setOffering] = useState([]);
  const [toDelete, setToDelete] = useState();

  const getOfferingFilter = (type, list) => {
    let temp = [];
    switch (type) {
      case "all":
        temp = list;
        break;
      case "recent":
        temp = list?.filter(
          (a) =>
            moment(a?.createdAt).unix() > moment().add(-7, "days").unix() &&
            a?.type == query?.type
        );
        break;
      case "starred":
        temp = list?.filter((a) => a?.starred && a?.type == query?.type);
        break;
      case "icebox":
        temp = list?.filter(
          (a) =>
            moment(a?.createdAt).unix() <= moment().add(-7, "days").unix() &&
            a?.type == query?.type &&
            !a?.starred
        );
        break;
      default:
        temp = list?.filter(
          (a) =>
            moment(a?.createdAt).unix() > moment().add(-7, "days").unix() &&
            a?.type == query?.type &&
            !a?.starred
        );
    }
    return temp;
  };
  useEffect(() => {
    let temp = getOfferingFilter(query?.tab, list);
    setOffering(temp);
  }, [query?.tab, list]);

  useEffect(() => {
    if (localStorage.getItem("ideaTab")) {
      setQuery({ ...query, tab: localStorage.getItem("ideaTab") });
      localStorage.removeItem("ideaTab");
    }
  }, []);

  const handleUpdate = async (id, starred) => {
    try {
      const response = await MarketingAPI.updateEntity("contentOffering", id, {
        starred: starred,
      });
      if (response.data) {
        onUpdate();
      }
    } catch (err) {
      switch (err.response?.status) {
      }
    }
  };
  return (
    <>
      <ConfirmModal
        isOpen={toDelete ? true : false}
        closeModal={() => setToDelete()}
        onAccept={() => {
          onDelete(toDelete?.id);
          setToDelete();
        }}
        text={`Are you sure you want to delete: ${toDelete?.name}?`}
      />
      <div className="px-0 lg:px-6 w-full space-y-8">
        <div className="flex flex-col space-y-2">
          <SearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Start typing.."
          />
          <div className="py-2 flex flex-row items-stretch space-x-3 max-w-full overflow-x-auto">
            <FilterDropDown
              label={query?.cluster || "Clusters"}
              values={clusters?.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))}
              onSelect={(value) => {
                if (value !== "none") {
                  setQuery({ cluster: value });
                } else {
                  let q = { ...query };
                  delete q?.cluster;
                  setQuery(q);
                }
              }}
            />
            <FilterDropDown
              label={query?.bucket || "Buckets"}
              values={buckets?.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))}
              onSelect={(value) => {
                if (value !== "none") {
                  setQuery({ bucket: value });
                } else {
                  let q = { ...query };
                  delete q?.bucket;
                  setQuery(q);
                }
              }}
            />
            <FilterDropDown
              label={query?.contentType || "Content Type"}
              values={contentType?.map((item) => ({
                label: item?.type,
                value: item?.id,
              }))}
              onSelect={(value) => {
                if (value !== "none") {
                  setQuery({ contentType: value });
                } else {
                  let q = { ...query };
                  delete q?.contentType;
                  setQuery(q);
                }
              }}
            />
            <FilterDropDown
              label={query?.platform || "Platform"}
              values={contentPlatform?.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))}
              onSelect={(value) => {
                if (value !== "none") {
                  setQuery({ platform: value });
                } else {
                  let q = { ...query };
                  delete q?.platform;
                  setQuery(q);
                }
              }}
            />
          </div>{" "}
        </div>
        <div className="flex flex-col space-y-4">
          <div className="w-full max-w-full flex flex-row overflow-x-auto space-x-2 items-stretch">
            {[
              { label: "Recently Added", value: "recent" },
              { label: "Starred", value: "starred" },
              { label: "Icebox", value: "icebox" },
            ]?.map((item) => {
              return (
                <div
                  onClick={() => {
                    setQuery({
                      ...query,
                      tab: item?.value,
                    });
                  }}
                  className={`py-1.5 min-w-max cursor-pointer px-4 rounded-full border font-lato text-xs font-light ${
                    query?.tab == item?.value
                      ? "text-primary-aqua-medium font-semibold border-primary-aqua-medium bg-primary-aqua-50"
                      : "text-primary-neutral-500 border-primary-neutral-200"
                  }`}
                >
                  {item?.label}
                </div>
              );
            })}
          </div>
          {offering?.length > 0 ? (
            offering?.map((idea) => {
              return (
                <ContentCard
                  idea={idea}
                  setToDelete={setToDelete}
                  onEdit={(val) => {
                    localStorage.setItem("ideaTab", query?.tab);
                    onEdit(val);
                  }}
                  handleUpdate={handleUpdate}
                />
              );
            })
          ) : (
            <EmptyState
              text="You have no ideas!"
              image="/assets/images/empty/cigenerator.svg"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ContentIdeas;
