import { Check, Plus } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import * as MarketingAPI from "config/APIs/marketing";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { RiEditLine } from "react-icons/ri";

function Conflicts({ conflictsList, characterId, onUpdate }) {
  const [inAddMode, setInAddMode] = useState(false);
  const [newConflict, setNewConflict] = useState("");
  const [inEditMode, setInEditMode] = useState(false);
  const [selectedConflict, setSelectedConflict] = useState();
  const [editedConflict, setEditedConflict] = useState("");

  const dispatch = useDispatch();

  const handleCancel = () => {
    setNewConflict("");
    setEditedConflict("");
    setInAddMode(false);
    setInEditMode(false);
  };

  const editConflict = (item) => {
    setSelectedConflict(item.id);
    setEditedConflict(item.name);
    setInEditMode(true);
  };

  const handleAdd = async () => {
    const body = {
      marketingCharacterId: characterId,
      name: newConflict,
    };

    try {
      const response = await MarketingAPI.createEntity("conflict", body);
      dispatch(showToast({ message: response.data.message, type: "success" }));
      onUpdate();
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (id) => {
    const body = {
      marketingCharacterId: characterId,
      name: editedConflict,
    };

    try {
      const response = await MarketingAPI.updateEntity("conflict", id, body);
      dispatch(showToast({ message: response.data.message, type: "success" }));
      onUpdate();
    } catch (err) {
      console.log(err);
    } finally {
      setInEditMode(false);
      setEditedConflict("");
    }
  };

  return (
    <>
      <div className="w-full">
        {/* <div className=" px-4 sm:px-6 mb-4">
        <div
          onClick={() => {}}
          className="rounded-md px-5 py-2 flex max-w-max flex-row items-center justify-between bg-primary-aqua-100"
        >
          <p className="text-xs font-medium font-lato text-primary-aqua-darkest cursor-pointer break-all text-center">
            + Add New Conflict
          </p>
        </div>
      </div> */}
        <div className="px-4 sm:px-6 pb-6">
          <div className="grid gap-5">
            {conflictsList.length > 0 &&
              conflictsList.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className="p-5 flex flex-col w-full bg-white border-gray-100 border"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="flex flex-row items-start justify-between relative">
                      <div className="flex flex-col items-start w-11/12">
                        <p className="text-2xs font-lato text-primary-aqua-darkest cursor-pointer break-words font-bold">
                          {item?.code}
                        </p>{" "}
                        {inEditMode && selectedConflict === item?.id ? (
                          <input
                            autoFocus
                            value={editedConflict}
                            onChange={(e) => setEditedConflict(e.target.value)}
                            className="text-sm font-lato text-black cursor-pointer break-words font-bold focus:outline-none focus:border-b focus:border-gray-300 w-full"
                          />
                        ) : (
                          <p className="text-sm font-lato text-black cursor-pointer break-words font-bold">
                            {item?.name}
                          </p>
                        )}
                        <p className="text-2xs mt-1 font-lato max-w-max cursor-pointer text-primary-gray-250 font-normal">
                          {item?.description}
                        </p>
                      </div>
                      {!inAddMode && !inEditMode && (
                        <div
                          role="button"
                          className="self-center"
                          onClick={() => editConflict(item)}
                        >
                          <RiEditLine className="text-primary-aqua-medium" />
                        </div>
                      )}
                    </div>
                    {inEditMode && selectedConflict === item?.id && (
                      <div className="flex justify-between items-end mt-4">
                        <ChronosButton
                          tertiary
                          text={<span className="underline">Cancel</span>}
                          onClick={handleCancel}
                        />
                        <ChronosButton
                          disabled={
                            editedConflict.length === 0 ||
                            editedConflict === item.name
                          }
                          primary
                          onClick={() => handleEdit(item.id)}
                          text={
                            <p className="flex items-center gap-2">
                              <Check />
                              <span>Done</span>
                            </p>
                          }
                        />
                      </div>
                    )}
                    {/* <div className="mt-2.5 border-t border-primary-gray-250 pt-3 flex flex-row items-center justify-between"></div> */}
                    {/* <p className="text-2xs font-lato text-primary-aqua-darkest cursor-pointer break-words font-bold">
                    {conflictsList.length == 1 || conflictsList.length == 0
                      ? `${conflictsList.length} conflict`
                      : `${conflictsList.length} conflicts`}
                  </p> */}
                  </div>
                );
              })}
            {inAddMode && (
              <div className="px-5">
                <input
                  placeholder="Add Conflict..."
                  className="border-b w-full border-primary-gray-250 py-4 px-2"
                  value={newConflict}
                  onChange={(e) => setNewConflict(e.target.value)}
                />
              </div>
            )}

            {!inAddMode && !inEditMode && (
              <div className="flex w-full justify-end px-5">
                <ChronosButton
                  tertiary
                  onClick={() => setInAddMode(true)}
                  text={
                    <>
                      <Plus />
                      <span className="underline">Add Conflict</span>
                    </>
                  }
                />
              </div>
            )}

            {inAddMode && (
              <div className="flex justify-between items-end px-5">
                <ChronosButton
                  tertiary
                  text={<span className="underline">Cancel</span>}
                  onClick={handleCancel}
                />
                <ChronosButton
                  disabled={newConflict.length === 0}
                  primary
                  onClick={handleAdd}
                  text={
                    <p className="flex items-center gap-2">
                      <Check />
                      <span>Done</span>
                    </p>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Conflicts;
