import { instance, getHeader } from "config";
/**
 * To create Tasks
 * body={
   "students": [
   "d2b4b8c7-02d2-4757-9ae4-77b854001846",
   "7df85d43-38ff-4a8b-9824-297c896ec1e6"
   ],
   "task": {
   "title": "Latest Test Create3",
   "date": "2022-01-20T19:27:26.000Z",
   "status": "NotStarted",
   "category": "Tasks",
   "type_id": "c5db9b77-7ec7-41f8-91fd-bacf41b1fbae"
   }}
 */
export const createTasks = (body) =>
  instance.post(`/admin/task/task/create`, body, getHeader());

/**
 * To delete Tasks
 * @param Id --> Id of Tasks
 */
export const deleteTasks = (id) =>
  instance.delete(`/admin/task/task/destroy/${id}`, getHeader());

/**
 * To get all Tasks
 * @param type --> Tasks
 */

export const getAllTasks = (query) =>
  instance.get(
    `/admin/task/task/all${query && Object.keys(query).length > 0
      ? `?${new URLSearchParams(query)}`
      : ""
    }`,
    getHeader()
  );

/**
 * Gives a Tasks
 * @param id --> id of Tasks
 */
export const getTasksById = (id, query) =>
  instance.get(
    `/admin/task/task/find/${id}${query && Object.keys(query).length > 0
      ? `?${new URLSearchParams(query)}`
      : ""
    }`,
    getHeader()
  );

/**
 * To update Tasks
 * @param id --> id of Tasks
 */

export const updateTasks = (id, body) =>
  instance.patch(`/admin/task/task/update/${id}`, body, getHeader());

/**
 * The following API has Pagination
 * @param {} query adds queries to the endpoints
 * Queries --> size, page, searchItem, sort&sort, task
 * Value for searchItem --> Search strings
 * Values for task --> Task ID
 * Values for sort --> | values in the object |&ASC/DESC
 */
export const getAllComments = async (query = {}) =>
  instance.get(
    `admin/task/comment/all?${new URLSearchParams(query)}`,
    getHeader()
  );

/**
 * Add a comment to a task
 */
export const addComment = async (body) =>
  instance.post("/admin/task/comment/add", body, getHeader());

/**
 *
 * @param {} userId
 * @returns Tasks for a particular users
 */
export const getUsersTasks = (userId) =>
  instance.get(`admin/user/student/tasks/${userId}`, getHeader());

/**
 *
 * @param {} taskId
 * @returns Members of a task
 */
export const getTasksUsers = (taskId) =>
  instance.get(`admin/task/user/${taskId}`, getHeader());

/**
 * Getting Task Type for the user
 */
export const getTaskTypeForUser = () =>
  instance.get(`admin/user/student/taskType`, getHeader());
