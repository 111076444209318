import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

// APIs
import * as MarketingAPI from "config/APIs/marketing";

// Components
import Conflicts from "components/Character/Conflicts";
import Details from "components/Character/IndividualCharacter/Details";
import { ArrowLeftIcon } from "@heroicons/react/outline";

const tabs = [
  { label: "Conflicts", value: "conflicts" },
  { label: "Details", value: "details" },
];

function Character({ Id, closeModal }) {
  const { id } = { id: Id } || useParams();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("conflicts");
  const [conflicts, setconflicts] = useState([
    `Conflict 1`,
    `Conflict 2`,
    `Conflict 3`,
    `Conflict 4`,
    `Conflict 5`,
    `Conflict 6`,
    `Conflict 7`,
  ]);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchConflict();
      fetchDetails();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const fetchConflict = async () => {
    try {
      const response = await MarketingAPI.getAllEntity("conflict", {
        marketingCharacterId: id,
      });
      if (response?.data) {
        const data = response.data.data;
        setconflicts(data);
      }
    } catch (err) {
      console.log("Fetch marking get conflicts by ID", err);
    }
  };

  const fetchDetails = async () => {
    try {
      const response = await MarketingAPI.getOneEntity("character", id);
      if (response?.data) {
        const data = response.data.data;
        setconflicts(data?.marketingConflict);
        setDetails(data);
      }
    } catch (err) {
      console.log("Fetch marking get Details by ID", err);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && location?.state?.tab) {
      setSelectedTab(location?.state?.tab);
    }

    return () => {
      isMounted = false;
    };
  }, [location?.state?.tab]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="max-h-screen">
      <div className="px-5 pb-5 pt-10 flex flex-row items-center space-x-2.5">
        <ArrowLeftIcon
          onClick={() => {
            closeModal();
          }}
          className="w-4 text-black mr-2 cursor-pointer"
        />

        <p className="w-11/12 text-sm lg:text-2xl leading-4 font-lato flex flex-col space-y-0.5 text-primary-gray-1000 cursor-pointer break-words font-medium">
          <span>Viewing conflicts for </span>
          <span className="text-primary-aqua-medium font-bold pt-1.5">
            {details?.character}
          </span>
        </p>
      </div>
      {/* <TopBar title="Character Name" showBack /> */}
      {/* <div className="w-full">
        <div className="lg:hidden">
          <div className="py-5">
            <div className="px-4 grid grid-cols-2 sm:px-6">
              {tabs.map((item) => {
                return (
                  <div
                    onClick={() => {
                      setSelectedTab(item?.value);
                    }}
                    key={item?.value}
                    className=""
                  >
                    <div
                      className={`flex flex-row cursor-pointer text-xs items-end justify-center font-poppins text-center ${
                        selectedTab == item?.value
                          ? "font-bold text-primary-aqua-dark"
                          : "font-normal text-primary-gray-450"
                      }`}
                    >
                      {item?.label}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div> */}
      <div className="max-h-80vh overflow-y-auto">
        {selectedTab == "conflicts" && (
          <Conflicts
            conflictsList={conflicts}
            characterId={id}
            onUpdate={() => {
              fetchConflict();
            }}
          />
        )}

        {selectedTab == "details" && (
          <Details
            characterDetails={details}
            onUpdate={() => {
              fetchDetails();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Character;
