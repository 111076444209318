import { useEffect, useState } from "react";
//Icons
import { XIcon, SearchIcon, CheckCircleIcon } from "@heroicons/react/solid";
import Drawer from "@mui/material/Drawer";

//Components

//Axios
import * as MarketingAPI from "config/APIs/marketing";
//Redux

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ChronosButton from "components/Comman/Buttons";
import { fetchAllTagTypes, fetchWithType } from "redux/marketing";
import TagElement from "components/Comman/Cards/Tags";
import ContentCard from "components/Comman/Cards/ControlCard";
import VerticalCard from "components/Comman/Cards/ControlCard/VerticalCard";
import EmptyState from "components/Comman/EmptyState";
import { fetchQGWithType } from "redux/quarterlyGoals";
import BusinessGoalCard from "components/Comman/Cards/Objectives";
import ObjectiveKRCard from "components/Comman/Cards/Objectives/ObjectiveKR";
import CharacterConflictCard from "components/Character/Card/CharacterConflictCard";

const AddMarketingAttributesModal = ({
  closeModal,
  onAdd,
  isOpen,
  selected,
  type,
  label,
  characterId,
  length,
}) => {
  const clusters = useSelector((state) => state.marketing.topicCluster);
  const pillars = useSelector((state) => state.marketing.contentPillars);
  const buckets = useSelector((state) => state.marketing.contentBucket);
  const contentType = useSelector((state) => state.marketing.contentTypes);
  const contentPlatforms = useSelector(
    (state) => state.marketing.contentPlatform
  );
  const tagList = useSelector((state) => state.marketing.tagType);
  const characters = useSelector((state) => state?.marketing?.character);
  const quarterlyGoals = useSelector(
    (state) => state?.quarterlyGoals?.quarterlyGoals
  );
  const objectives = useSelector((state) => state?.quarterlyGoals?.objectives);
  const tags = useSelector((state) => state.marketing.tags);

  let dispatch = useDispatch();

  const [selectedItems, setSelectedItems] = useState([]);
  const [list, setList] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (clusters.length === 0) {
      dispatch(fetchWithType("topicCluster"));
    }
    if (pillars.length === 0) {
      dispatch(fetchWithType("contentPillars"));
    }
    if (buckets.length === 0) {
      dispatch(fetchWithType("contentBucket"));
    }
    if (contentType.length === 0) {
      dispatch(fetchWithType("contentTypes"));
    }
    if (contentPlatforms.length === 0) {
      dispatch(fetchWithType("contentPlatform"));
    }
    if (characters.length === 0) {
      dispatch(fetchWithType("character"));
    }
    if (quarterlyGoals.length === 0) {
      dispatch(fetchQGWithType("quarterlyGoals"));
    }
    if (objectives.length === 0) {
      dispatch(fetchQGWithType("objectives"));
    }
    if (tagList.length === 0) {
      dispatch(fetchAllTagTypes());
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      if (type === "conflict") {
        getList();
      } else {
        switch (type) {
          case "topicClusters":
            setList(clusters);
            break;
          case "contentBuckets":
            setList(buckets);
            break;
          case "contentTypes":
            setList(contentType);
            break;
          case "contentPlatforms":
            setList(contentPlatforms);
            break;
          case "contentPillars":
            setList(pillars);
            break;
          case "tags":
            setList(tagList);
            break;
          case "businessGoals":
            setList(quarterlyGoals);
            break;
          case "objectives":
            setList(objectives);
            break;
          case "characters":
            setList(characters);
            break;
          default:
        }
      }
    }
  }, [isOpen, type]);

  const getList = async () => {
    setFetching(true);
    let q = {};
    if (characterId) {
      q["marketingCharacterId"] = characterId;
    }
    try {
      const response = await MarketingAPI.getAllEntity(type, q);
      if (response.data.data) {
        let data = response.data.data;
        setList(data);
        setFetching(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isOpen && selected) {
      setSelectedItems([...selected]);
    }
    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  const addOrRemove = async (item) => {
    let tempSelected = [...selectedItems] || [];

    if (tempSelected.find((e) => e?.id == item?.id)) {
      tempSelected = tempSelected.filter((e) => e?.id !== item?.id);
    } else {
      if (length == 1) {
        tempSelected = [item];
      } else tempSelected.push(item);
    }

    setSelectedItems([...tempSelected]);
  };

  const onSave = () => {
    let temp = selectedItems;

    onAdd([...temp]);
    closeModal();
  };

  return (
    <>
      <Drawer
        anchor={window.innerWidth < 1024 ? "bottom" : "right"}
        open={isOpen}
        onClose={() => {
          closeModal();
        }}
        PaperProps={{
          style: {
            maxHeight: "100vh",
            width: window.innerWidth < 1024 ? "100vw" : "50vw",
          },
        }}
      >
        <div className="h-screen relative p-6 flex flex-col space-y-8 bg-white">
          <div className="w-full flex flex-col space-y-4">
            <div className="flex flex-row items-center text-primary-gray-800 justify-between">
              <h5 className="font-poppins font-medium text-sm">
                {selected?.length > 0 ? "Edit" : "Add"} {label}
              </h5>
              <button
                aria-label="Close"
                type="button"
                onClick={() => closeModal()}
              >
                <XIcon className="h-5 w-5" />
              </button>
            </div>
            {console.log(selectedItems)}
            <div className="flex flex-row items-center flex-wrap px-5 py-2 bg-primary-gray-50 border-b max-h-[20vh] overflow-y-auto border-primary-gray-100">
              {selectedItems?.length > 0 &&
                selectedItems?.map((item, index) => {
                  return (
                    <TagElement
                      tag={item}
                      editable
                      onRemove={() => {
                        addOrRemove(item);
                      }}
                    />
                  );
                })}
              {selectedItems.length === 0 && <p>Select ...</p>}
            </div>
          </div>
          <div className="flex flex-row items center bg-primary-gray-50 border-primary-gray-100 border rounded-full py-2 px-6">
            <input
              className="p-0 border-0 bg-transparent font-lato text-sm text-primary-gray-800 outline-none ring-0"
              placeholder="Search..."
              value={searchItem}
              onChange={(e) => setSearchItem(e?.target?.value)}
            />
          </div>
          <div className="max-h-[70vh] overflow-y-auto">
            {type == "contentPillars" ||
            type == "contentBuckets" ||
            type == "contentTypes" ||
            type == "contentPlatforms" ? (
              <div className="grid lg:grid-cols-2 gap-4">
                {!fetching &&
                  list.length > 0 &&
                  list.map((item) => {
                    if (
                      searchItem == "" ||
                      (searchItem !== "" &&
                        item?.name
                          ?.toLowerCase()
                          .includes(searchItem.toLowerCase())) ||
                      (searchItem !== "" &&
                        item?.type
                          ?.toLowerCase()
                          .includes(searchItem.toLowerCase()))
                    )
                      return (
                        <ContentCard
                          item={item}
                          onClick={() => addOrRemove(item)}
                          selected={selectedItems?.find(
                            (s) => s?.id == item?.id
                          )}
                        />
                      );
                  })}
              </div>
            ) : type == "topicClusters" ? (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                {!fetching &&
                  list.length > 0 &&
                  list.map((item) => {
                    if (
                      searchItem == "" ||
                      (searchItem !== "" &&
                        item?.name
                          ?.toLowerCase()
                          .includes(searchItem.toLowerCase())) ||
                      (searchItem !== "" &&
                        item?.type
                          ?.toLowerCase()
                          .includes(searchItem.toLowerCase()))
                    )
                      return (
                        <VerticalCard
                          item={item}
                          onClick={() => addOrRemove(item)}
                          selected={selectedItems?.find(
                            (s) => s?.id == item?.id
                          )}
                        />
                      );
                  })}
              </div>
            ) : type == "tags" ? (
              <div className="w-full space-y-12">
                {!fetching &&
                  list.length > 0 &&
                  list.map((item) => {
                    if (
                      searchItem == "" ||
                      (searchItem !== "" &&
                        item?.name
                          ?.toLowerCase()
                          .includes(searchItem.toLowerCase())) ||
                      (searchItem !== "" &&
                        item?.type
                          ?.toLowerCase()
                          .includes(searchItem.toLowerCase()))
                    )
                      return (
                        <div className="w-full space-y-6">
                          <p className="font-poppins text-left text-base font-medium text-primary-gray-800">
                            {item?.name}
                          </p>
                          <div className="flex flex-row items-center justify-start flex-wrap">
                            {item?.tags?.length > 0 ? (
                              item?.tags?.map((t) => {
                                if (
                                  (searchItem !== "" &&
                                    !t?.name
                                      ?.toLowerCase()
                                      .includes(searchItem.toLowerCase())) ||
                                  (searchItem !== "" &&
                                    !t?.type
                                      ?.toLowerCase()
                                      .includes(searchItem.toLowerCase()))
                                )
                                  return;
                                return (
                                  <TagElement
                                    tag={t}
                                    onClick={() => addOrRemove(t)}
                                  />
                                );
                              })
                            ) : (
                              <EmptyState
                                noImage
                                text={`${item?.name} has no tags!`}
                              />
                            )}
                          </div>
                        </div>
                      );
                  })}
              </div>
            ) : type == "businessGoals" ? (
              <div className="grid lg:grid-cols-2 gap-4">
                {!fetching &&
                  list.length > 0 &&
                  list.map((item) => {
                    if (
                      searchItem !== "" &&
                      !item?.title
                        ?.toLowerCase()
                        .includes(searchItem.toLowerCase())
                    )
                      return;
                    return (
                      <BusinessGoalCard
                        item={item}
                        onClick={() => addOrRemove(item)}
                        selected={selectedItems?.find((s) => s?.id == item?.id)}
                      />
                    );
                  })}
              </div>
            ) : type == "objectives" ? (
              <div className="grid gap-4">
                {!fetching &&
                  list.length > 0 &&
                  list.map((item) => {
                    if (
                      searchItem !== "" &&
                      !item?.title
                        ?.toLowerCase()
                        .includes(searchItem.toLowerCase())
                    )
                      return;
                    return (
                      <ObjectiveKRCard
                        item={item}
                        selectAble
                        onSelect={(val) => {
                          let temp =
                            selectedItems?.length > 0 ? [...selectedItems] : [];
                          if (temp?.find((s) => s?.id == item?.id)) {
                            let idx = temp?.findIndex((s) => s?.id == item?.id);
                            temp[idx]["keyResults"] = val;
                          } else {
                            let o = { ...item };
                            delete o?.keyResults;
                            temp.push({ ...o, keyResults: val });
                          }
                          setSelectedItems(temp);
                        }}
                        selected={
                          selectedItems?.find((s) => s?.id == item?.id)
                            ?.keyResults
                        }
                      />
                    );
                  })}
              </div>
            ) : type == "characters" ? (
              <div className="grid gap-4">
                {!fetching &&
                  list.length > 0 &&
                  list.map((item) => {
                    if (
                      searchItem !== "" &&
                      !item?.title
                        ?.toLowerCase()
                        .includes(searchItem.toLowerCase())
                    )
                      return;
                    return (
                      <CharacterConflictCard
                        item={item}
                        selectAble
                        onSelect={(val) => {
                          let temp =
                            selectedItems?.length > 0 ? [...selectedItems] : [];
                          if (temp?.find((s) => s?.id == item?.id)) {
                            let idx = temp?.findIndex((s) => s?.id == item?.id);
                            temp[idx]["marketingConflict"] = val;
                          } else {
                            let o = { ...item };
                            delete o?.marketingConflict;
                            temp.push({ ...o, marketingConflict: val });
                          }
                          setSelectedItems(temp);
                        }}
                        selected={
                          selectedItems?.find((s) => s?.id == item?.id)
                            ?.marketingConflict
                        }
                      />
                    );
                  })}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="flex flex-row items-center justify-center space-x-4">
            <ChronosButton
              text={selected?.length > 0 ? "Save" : "Add"}
              primary
              onClick={() => {
                onSave();
              }}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default AddMarketingAttributesModal;
