import React, { useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";

/**
 * Displays the Add Content Offer Card
 * @param {Object} content -Object containing content details
 * @param {Function} onAdd
 * @param {Function} onDelete
 * @param {Boolean} deselect -whether card deselected or not
 * @returns
 */

const ContentOfferingCard = ({
  content,
  onAdd,
  onClick,
  onDelete,
  deselect,
}) => {
  const [add, setAdd] = useState(false);

  useEffect(() => {
    if (deselect === true) {
      setAdd(false);
    }
  }, [deselect]);

  return (
    <>
      <div className="flex flex-col items-stretch modals-component bg-white">
        <div
          className={`flex flex-row items-start justify-between border-2 rounded-t-40px px-4.5 py-3.5 ${
            add ? "content-added" : "border-color-gray-600"
          }`}
        >
          <div className="flex flex-row items-center mr-4">
            <h1 className="font-lato cursor-pointer font-bold text-black text-sm leading-4 text-left">
              {content?.name}
            </h1>
          </div>
          <div className=" flex-shrink-0">
            {add ? (
              <CheckCircleIcon
                onClick={() => {
                  setAdd(false);
                  onDelete(content);
                }}
                className="w-3 h-3 cursor-pointer mb-2.5 object-none	text-active"
              />
            ) : (
              <img
                onClick={() => {
                  setAdd(true);
                  onAdd(content);
                }}
                src="/assets/images/icons/addGray.svg"
                alt=""
                className="w-3 h-3 cursor-pointer mb-2.5 object-none"
              />
            )}
            <img
              src={
                (content?.contentPlatforms?.length > 0 &&
                  content?.contentPlatforms[0]?.image?.url) ||
                "/assets/images/icons/insta.svg"
              }
              alt=""
              className="w-3 h-3 object-none"
            />
          </div>
        </div>
        <div className="px-4.5 pb-4.5 pt-2.5 space-y-1 content-offering-font text-justify">
          <div className="font-lato pb-2.5 space-y-1.5">
            <p className="font-normal leading-3 text-primary-gray-350">
              content bucket:
            </p>
            <div className="flex flex-row items-center">
              <h1 className="font-bold leading-3 text-status-InProgress-dark">
                {content?.marketingContentBuckets
                  ?.map((i) => i?.name)
                  ?.join(",")}
              </h1>
            </div>
          </div>
          <div className="font-lato pb-2.5 space-y-1.5">
            <p className="font-normal leading-3 text-primary-gray-350">
              topic clusters:
            </p>
            <div className="flex flex-row items-center font-lato">
              <img
                className="w-2.5 h2.5 mr-0.5"
                src="/assets/images/icons/contentBucketCluster.svg"
                alt=""
              />
              <h1 className="font-bold leading-3 text-status-Completed-dark">
                {content?.topicClusters?.map((i) => i?.name)?.join(",")}
              </h1>
            </div>
          </div>
          <div className="font-lato pb-2.5 space-y-1.5">
            <p className="font-normal leading-3 text-primary-gray-350">
              content pillars:
            </p>
            <div className="flex flex-row items-center">
              <img
                className="w-2.5 h2.5 mr-0.5"
                src="/assets/images/icons/contentPillar.svg"
                alt=""
              />
              <h1 className="font-bold leading-3" style={{ color: "#532A01" }}>
                {content?.contentPillars?.map((i) => i?.name)?.join(",")}
              </h1>
            </div>
          </div>
          <div className="space-y-1.5 content-offering-font leading-3">
            <h1 className="font-lato font-normal text-primary-gray-350 ">
              Tags:
            </h1>
            <p className="font-lato font-normal text-primary-gray-600">
              {content?.tags?.length > 0 ? (
                <>{content?.tags?.map((i) => i?.name)?.join(",")}</>
              ) : (
                "No Tags added!"
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentOfferingCard;
