import {
  GameController,
  LightbulbFilament,
  Megaphone,
  RocketLaunch,
  Users,
  UsersThree,
} from "@phosphor-icons/react";

const mobileBottomNavProtected = [
  {
    name: "Ideas",
    path: "/contentIdea",
    icon: <LightbulbFilament size={25} />,
  },
  {
    name: "Campaigns",
    path: "/campaign",
    icon: <UsersThree size={25} />,
  },
  {
    name: "Add Idea",
    path: "/contentIdeas/create/0",
    icon: <Megaphone size={25} />,
    image: "/assets/svg/tabbar/addFromTabbar.svg",
    selectedImage: "/assets/svg/tabbar/addFromTabbar.svg",
  },
  {
    name: "Characters",
    path: "/character",
    icon: <RocketLaunch size={25} />,
  },
  {
    name: "Controls",
    path: "/control",
    icon: <GameController size={25} />,
  },
];
const mobileBottomNav = [
  {
    name: "Ideas",
    path: "/contentIdea",
    icon: <LightbulbFilament size={25} />,
  },
  {
    name: "Wishlist",
    path: "/wishlist",
    icon: <UsersThree size={25} />,
  },
  {
    name: "Add Idea",
    path: "/contentIdeas/create/0",
    icon: <Megaphone size={25} />,
    image: "/assets/svg/tabbar/addFromTabbar.svg",
    selectedImage: "/assets/svg/tabbar/addFromTabbar.svg",
  },
  {
    name: "Characters",
    path: "/character",
    icon: <RocketLaunch size={25} />,
  },
  {
    name: "Controls",
    path: "/control",
    icon: <GameController size={25} />,
  },
];

const laptopNav = [
  {
    name: "Idea Builder",
    path: "/contentIdea",
    icon: <LightbulbFilament size={25} />,
  },
  // {
  //   name: "Strategy Hub",
  //   path: "/strategyHub",
  //   icon: <RocketLaunch size={25} />,
  // },
  {
    name: "Campaigns",
    path: "/campaign",
    icon: <Users size={25} />,
  },
];
export { mobileBottomNav, mobileBottomNavProtected, laptopNav };
