import React, { useMemo, useState, useEffect, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
// Big Calendar Styles
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";

import moment from "moment";

import * as dates from "./Dates";

//APIS
import * as MarketingAPI from "config/APIs/marketing";

import ContentOfferingCreateEdit from "./ContentOfferingCreateEdit";
import ContentOfferingEdit from "./ContentOfferingEdit";
import ContentIdeaOverlay from "./ContentIdeaOverlay";
import { showToast } from "redux/toaster";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";

/**
 * Calendar Component
 * @param { object } campaign -details of the respective campaign
 * @param { Array } offerings -Array of objects containing all the offerings
 * @param { Function } fetchOffering -API function call to get all the offerings
 * @returns
 *
 *
 */

function CalendarView({ campaign, offerings, fetchOffering, view }) {
  const localizer = momentLocalizer(moment);

  const [mark, setMark] = useState();
  const [state, setState] = useState({ value: new Date() });

  const [offeringdata, setOfferingData] = useState({ action: "", date: "" });
  const [events, setEvents] = useState();
  const [newModal, setNewModal] = useState(false);

  const dispatch = useDispatch();
  const eventColor = ["#F2FFF6", "#FFFEF5", "#FEF6F5", "#FFF8F0"];
  const [viewCal, setViewCal] = useState();

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };
  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };

  useEffect(() => {
    setMark(
      offerings?.map((item) => {
        return moment(item?.start).format("DD-MM-YYYY");
      })
    );

    var num = 0;

    const res = offerings?.map((item) => {
      let tempnum = num;
      num = num + 6;

      return {
        ...item,
        start: new Date(
          moment(item.start)
            .add((tempnum + 0.5) % 16, "hours")
            .format("YYYY-MM-DD hh:mm:ss")
        ),
        end: new Date(
          moment(item.start)
            .add((tempnum + 1) % 16, "hours")
            .format("YYYY-MM-DD hh:mm:ss")
        ),
      };
    });

    setEvents(res);
  }, [offerings]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setViewCal(Views.DAY);
      } else {
        setViewCal(Views.MONTH);
      }
    };

    handleResize();
  }, []);

  const { formats, max, views } = useMemo(
    () => ({
      max: dates.add(dates.endOf(new Date(2015, 17, 1), "day"), -1, "hours"),
      formats: {
        dayFormat: (date, culture, localizer) =>
          localizer.format(date, "MMMM D dd", culture),
        timeGutterFormat: (date, culture, localizer) =>
          localizer.format(date, "h a", culture),
      },
      views: [Views.WEEK, Views.DAY, Views.MONTH],
    }),
    []
  );

  const onDelete = async (item) => {
    try {
      const res = await MarketingAPI.deleteEntity("contentOffering", item?.id);
      if (res?.data) {
        showSuccessNotification("Deleted successfully!");
        fetchOffering();
      }
    } catch (err) {
      console.log("Delete error", err);
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
  };

  const changeFromCalendar = (val) => {
    setState({ value: val });
  };

  const onSelectSlot = (event) => {
    setOfferingData({ action: "click", date: new Date(event.start) });
    setNewModal(true);
  };

  function getCustomToolbar(toolbar) {
    const goToPrevious = () => {
      toolbar.onNavigate("PREV");
    };

    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };

    return (
      <div className="flex justify-between">
        <ArrowLeft
          className="cursor-pointer w-7 relative top-0 left-1"
          onClick={goToPrevious}
        />

        <div className="flex flex-row justify-center items-center text-primary-gray-600 w-full h-full ">
          <p className="font-bold mb-0 font-karla text-sm">
            {moment(state.value).format("MMMM, YYYY")} &nbsp;
          </p>
        </div>
        <ArrowRight
          className="cursor-pointer w-7 relative top-0 -right-1"
          onClick={goToNext}
        />
      </div>
    );
  }

  const MyEvent = (item) => {
    const [open, setOpen] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const [selectedIdea, setSelectedIdea] = useState();
    return (
      <>
        <ContentIdeaOverlay
          isOpen={selectedIdea ? true : false}
          closeModal={() => {
            setSelectedIdea();
          }}
          id={selectedIdea}
        />

        <ContentOfferingEdit
          isOpen={editModal}
          closeModal={() => {
            setEditModal(false);
          }}
          content={item.event}
          fetchOffering={fetchOffering}
        />

        <div className="absolute z-40 top-0 right-3 ml-2">
          <Menu as="div" className="relative hidden lg:inline-block">
            <Menu.Button className={`text-sm font-lato font-bold text-black`}>
              ...
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute z-50 bg-white flex flex-col space-y-2 rounded-10px p-2.5 right-0 w-20">
                <Menu.Button>
                  {({ active }) => (
                    <>
                      <h1
                        onClick={() => {
                          setEditModal(true);
                          setOpen(false);
                        }}
                        className=" text-sm font-lato font-normal text-primary-gray-600 p-0 cursor-pointer hover:text-primary-gray-500"
                      >
                        Edit
                      </h1>
                    </>
                  )}
                </Menu.Button>{" "}
                <Menu.Button>
                  {({ active }) => (
                    <>
                      <h1
                        className=" text-sm mt-2 font-lato font-normal p-0 cursor-pointer  hover:text-primary-gray-500 calendar-h1-2"
                        onClick={() => {
                          setOpen(false);
                          onDelete(item?.event);
                        }}
                      >
                        Delete
                      </h1>
                    </>
                  )}
                </Menu.Button>{" "}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div
          onClick={() => {
            setSelectedIdea(item?.event?.id);
          }}
          id={item?.id}
          className="relative z-0 p-3 rounded-xl mb-1"
          style={{
            backgroundColor:
              eventColor[moment(item?.event?.start).isoWeekday() % 4],
          }}
        >
          {/* <img className=" mb-2 " src={userIcon} alt="user-icon" /> */}

          <div className="">
            <h1 className={`text-xs font-lato font-normal calendar-h1`}>
              {moment(item?.event?.start).format("h:mm a")}
            </h1>
            <h1
              className={`text-xs font-lato font-bold text-black line-clamp-2`}
            >
              {item.title}
            </h1>
          </div>
        </div>
      </>
    );
  };

  const onNavigate = (item) => {
    setState({ value: new Date(item) });
  };

  const MyWeekHeader = (item) => {
    return (
      <div className="flex flex-col justify-center items-center text-primary-gray-600 w-full h-full">
        <p className="font-normal mb-0 font-karla text-sm">
          {moment(item.date).format("MMMM")}
        </p>
        <p className="font-bold mb-0 font-karla text-xl">
          {moment(item.date).format("DD")}
        </p>
        <p className="font-normal mb-0 font-karla text-sm">
          {moment(item.date).format("ddd")}
        </p>
      </div>
    );
  };
  const MyDayHeader = () => {
    return (
      <div className="flex flex-row justify-center items-center text-primary-gray-600 w-full h-full ">
        <p className="font-bold mb-0 font-karla text-sm">
          {moment(state.value).format("DD")} &nbsp;
        </p>
        <p className="font-bold mb-0 font-karla text-sm">
          {moment(state.value).format("MMMM")},
        </p>
        <p className="font-bold mb-0 font-karla text-sm">
          {moment(state.value).format("yy")}
        </p>
      </div>
    );
  };

  const MyMonthHeader = (item) => {
    return (
      <div className="flex flex-row justify-center items-center text-primary-gray-600 w-full h-full ">
        <p className="font-bold mb-0 font-karla text-sm">
          {moment(item?.date).format("ddd")} &nbsp;
        </p>
      </div>
    );
  };

  return (
    <>
      <ContentOfferingCreateEdit
        isOpen={newModal}
        closeModal={() => setNewModal(false)}
        onCreate={() => fetchOffering()}
        campaign={campaign}
        offeringdata={offeringdata}
      />

      <div className="w-full">
        <div className="flex theme-container md:flex-row flex-col-reverse items-center md:items-start w-full pb-16 md:gap-x-8 h-90vh">
          <Calendar
            components={{
              toolbar: getCustomToolbar,
              event: MyEvent,
              week: {
                header: MyWeekHeader,
              },
              month: {
                header: MyMonthHeader,
              },
            }}
            popup
            date={state.value}
            defaultView={Views.MONTH}
            events={events}
            localizer={localizer}
            max={max}
            showMultiDayTimes
            views={views}
            view={viewCal}
            formats={formats}
            selectable={true}
            onSelectSlot={(event) => {
              onSelectSlot(event);
            }}
            onNavigate={onNavigate}
          />
          {/* <div className="w-2/5 md:w-1/4">
            <RightCalendar
              className="mb-6 md:mt-0 text-center theme-container bg-white"
              onChange={(val) => {
                changeFromCalendar(val);
              }}
              tileContent={({ activeStartDate, date, view }) => {
                if (
                  mark?.find((x) => x === moment(date).format("DD-MM-YYYY"))
                ) {
                  return (
                    <div className="w-1 h-1 rounded-3xl !shrink-0 absolute bottom-2 calendar-div2"></div>
                  );
                }
              }}
              onActiveStartDateChange={({
                action,
                activeStartDate,
                value,
                view,
              }) => setState({ value: activeStartDate })}
              defaultDate={new Date()}
              value={state.value}
              activeStartDate={state.value}
              next2Label={null}
              prev2Label={null}
            />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default CalendarView;
