import ChronosButton from "../Buttons";

export default function EmptyState({
  text = "No Items Found!",
  image = "/assets/images/empty/accessDenied.svg",
  cta,
  ctaText,
  noImage,
}) {
  return (
    <div className="flex justify-center items-center flex-col py-10 gap-4 lg:min-h-[30vh]">
      {!noImage && (
        <div className="relative h-[149px] w-[192px] overflow-hidden">
          <img
            src={image}
            alt="empty epics"
            className="relative h-full w-full object-contain"
          />
        </div>
      )}
      <p className="text-gray-500 text-sm">{text}</p>
      {cta && <ChronosButton tertiary text={ctaText} onClick={cta} />}
    </div>
  );
}
