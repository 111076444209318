import React from "react";
import Drawer from "@mui/material/Drawer";
// Icons
import { XIcon } from "@heroicons/react/solid";

function ConfirmModal({ isOpen, closeModal, onAccept, text }) {
  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={300}
      PaperProps={{
        style: {
          maxHeight: window.innerWidth < 1024 ? "50vh" : "100vh",
          width: window.innerWidth < 1024 ? "100vw" : "456px",
        },
      }}
    >
      <div className="bg-gray-50 w-full h-full flex flex-row items-center justify-center">
        <div className="relative w-full p-2.5">
          <div className=" text-primary-aqua-darker font-semibold">
            <div className="flex flex-row w-full items-center justify-between p-3 rounded-t-lg">
              <h5 className="font-poppins text-sm">Confirmation!</h5>
              <button
                aria-label="Close"
                type="button"
                onClick={() => closeModal()}
              >
                <XIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="px-3 pt-3 pb-20 relative">
            <div className="relative">
              <div>
                <div className="" md="12">
                  <div className="text-left text-xs poppins font-medium mb-3">
                    {text}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t pt-3 border-gray-200 w-full flex flex-row items-center justify-end space-x-3">
            <div
              onClick={onAccept}
              className="font-poppins text-xs font-medium px-5 py-1 rounded cursor-pointer"
            >
              Yes
            </div>
            <div
              onClick={closeModal}
              className="font-poppins text-xs font-medium px-5 py-1 rounded cursor-pointer"
            >
              No
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default ConfirmModal;
