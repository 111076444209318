import React, { useState, useEffect } from "react";
import { showToast } from "redux/toaster";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ViewDetails from "components/Character/IndividualCharacter/ViewDetails";
import EditDetails from "components/Character/IndividualCharacter/EditDetails";
import * as MarketingApi from "config/APIs/marketing/index";
import ConfirmModal from "components/Modals/Common/ConfirmModal";

function Details({ characterDetails, onUpdate }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [details, setDetails] = useState({});
    const [editable, setEditable] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    useEffect(() => {
        setDetails(characterDetails);
    }, [characterDetails]);

    const deleteCharacter = async () => {
        setFetching(true);
        try {
            const response = await MarketingApi.deleteEntity(
                "character",
                details.id
            );
            if (response.data) {
                dispatch(
                    showToast({ message: "Character Deleted Sucessfully!" })
                );

                dispatch(
                    showToast({ message: "Details Deleted Successfully!" })
                );

                history.goBack();
            }
        } catch (err) {
            console.log("Delete Character error", err);
            switch (err.response?.status) {
                case 401:
                    dispatch(
                        showToast({ message: "Unauthorized!", type: "error" })
                    );
                    break;
                default:
                    dispatch(
                        showToast({
                            message: "Something went wrong!",
                            type: "error",
                        })
                    );
            }
        }
        setFetching(false);
    };

    const handleUpdate = async () => {
        let body = {
            ...details,
        };
        try {
            const response = await MarketingApi.updateEntity(
                "character",
                details.id,
                body
            );
        } catch (err) {
            switch (err.response?.status) {
                case 401:
                    dispatch(
                        showToast({ message: "Unauthorized!", type: "error" })
                    );
                    break;
                default:
                    dispatch(
                        showToast({
                            message: "Something went wrong!",
                            type: "error",
                        })
                    );
            }
        }
    };

    return (
        <div className="w-full">
            <ConfirmModal
                isOpen={deleteModal}
                closeModal={() => setDeleteModal(false)}
                onAccept={() => {
                    deleteCharacter();
                    setDeleteModal(false);
                }}
                text="Are you sure you want to delete the Task?"
            />
            {editable ? (
                <EditDetails
                    setEditable={(val) => {
                        setEditable(val);
                    }}
                    onUpdate={() => handleUpdate()}
                    details={details}
                    setDetails={setDetails}
                    onDelete={() => setDeleteModal(true)}
                />
            ) : (
                <ViewDetails
                    fetching={fetching}
                    details={details}
                    setEditable={(val) => {
                        setEditable(val);
                    }}
                />
            )}
        </div>
    );
}

export default Details;
