import ContentStrategy from "components/StrategyHub/ContentStrategy";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetTabs, updateTabs } from "redux/navigation";
import { useParams } from "react-router-dom";
import * as MarketingAPI from "config/APIs/marketing/index";
import { showToast } from "redux/toaster";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Goals from "components/StrategyHub/Goals";
import Audience from "components/StrategyHub/Audience";
import moment from "moment";
import Calendar from "components/StrategyHub/Calendar";

const tabs = [
  { label: "Goals", value: "goals" },
  { label: "Audience", value: "audience" },
  // { label: "Strategy", value: "strategy" },
  { label: "Calendar", value: "calendar" },
];

function StrategyHub() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedTab = useSelector((state) => state?.sideNav?.selectedTab);
  const [details, setDetails] = useState({});
  useEffect(() => {
    dispatch(updateTabs({ tabs: tabs, selectedTab: "goals" }));
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchDetails();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  const fetchDetails = async () => {
    try {
      const response = await MarketingAPI.getOneEntity("campaign", id);
      if (response?.data) {
        const data = response.data.data;

        setDetails(data);
        console.log(data);
      }
    } catch (err) {
      console.log("FetchDetails error by ID", err);
    }
  };

  const handleUpdate = async (detail) => {
    let {
      topicClusters,
      contentBuckets,
      contentPlatforms,
      contentPillars,
      tags,
      businessGoals,
      objectives,
      keyResults,
      characters,
      conflicts,
      ...values
    } = detail;
    let body = values;
    if (topicClusters) body["topicClusters"] = topicClusters?.map((i) => i?.id);
    if (contentBuckets)
      body["contentBuckets"] = contentBuckets?.map((i) => i?.id);
    if (contentPillars) body["pillars"] = contentPillars?.map((i) => i?.id);
    if (tags) body["tags"] = tags?.map((i) => i?.id);
    if (contentPlatforms)
      body["platforms"] = contentPlatforms?.map((i) => i?.id);
    if (characters) body["characters"] = characters?.map((i) => i?.id);
    if (conflicts) body["conflicts"] = conflicts?.map((i) => i?.id);
    if (businessGoals) body["goals"] = businessGoals?.map((i) => i?.id);
    if (objectives) body["objectives"] = objectives?.map((i) => i?.id);
    if (keyResults) body["keyResults"] = keyResults?.map((i) => i?.id);

    try {
      const response = await MarketingAPI.updateEntity(
        "campaign",
        detail?.id,
        body
      );
      if (response.data) {
        // history.goBack();
        // dispatch(resetTabs());
        // dispatch(showToast({ message: "Update successfull" }));
      }
    } catch (err) {
      switch (err.response?.status) {
        case 422:
        case 401:

        default:
          dispatch(
            showToast({ message: err.response?.data?.message, type: "error" })
          );
      }
    }
  };

  return (
    <>
      <div className="p-4 bg-white rounded-2xl">
        <div className="border-b border-primary-gray-200 flex flex-row items-center justify-between p-2 space-x-2">
          <div className="flex flex-col items-start space-y-1">
            <p className="text-primary-neutral-500 font-lato text-2xs font-semibold">
              {details?.code}
            </p>
            <p className="text-primary-aqua-medium font-lato text-base font-semibold">
              {details?.name}
            </p>
            <p className="text-primary-neutral-500 text-2xs font-lato font-light">
              {moment(details?.startDate).format("ll")} -{" "}
              {moment(details?.endDate).format("ll")}
            </p>
          </div>
          <img
            src={details?.image?.url}
            alt=""
            className="w-10 h-10 rounded-full bg-primary-gray-200"
          />
        </div>
        {details?.description && (
          <p className="p-2 text-primary-neutral-800 font-lato text-xs font-light">
            {details?.description}
          </p>
        )}
      </div>
      {selectedTab == "strategy" && (
        <ContentStrategy
          details={details}
          setDetails={setDetails}
          onUpdate={(val) => {
            handleUpdate(val);
          }}
        />
      )}

      {selectedTab == "goals" && (
        <Goals
          details={details}
          setDetails={setDetails}
          onUpdate={(val) => {
            handleUpdate(val);
          }}
        />
      )}
      {selectedTab == "audience" && (
        <Audience
          details={details}
          setDetails={setDetails}
          onUpdate={(val) => {
            handleUpdate(val);
          }}
        />
      )}
      {selectedTab == "calendar" && <Calendar campaign={details} />}
    </>
  );
}

export default StrategyHub;
