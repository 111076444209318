import { Trash } from "@phosphor-icons/react";
import React from "react";

function ContentCard({ item, editable, onRemove, onClick, selected }) {
  return (
    <div
      onClick={onClick}
      className={`p-4 shadow-md rounded-lg bg-white border ${
        selected ? "border-primary-aqua-light" : "border-primary-gray-100"
      } flex flex-row items-start justify-between space-x-4 relative min-w-full max-w-full lg:min-w-full`}
    >
      <div className="flex flex-col items-start space-y-4">
        <h1 className="text-base text-primary-gray-800 font-semibold font-lato">
          {item?.name || item?.type}
        </h1>
        {item?.platform && (
          <div className="text-primary-gray-350 text-sm font-lato font-semibold">
            {item?.platform}
          </div>
        )}
        <p className="text-sm text-primary-gray-800 font-lato">
          {item?.description}
        </p>
      </div>
      {item?.image ? (
        <img
          src={item?.image?.url}
          className="w-20 h-20 object-contain rounded-lg"
        />
      ) : (
        <div className="w-20 h-20 object-contain bg-primary-gray-100 rounded-lg" />
      )}
      {editable && (
        <Trash
          onClick={onRemove}
          size={16}
          className="text-secondary-red-550 absolute top-2 right-2"
        />
      )}
    </div>
  );
}

export default ContentCard;
