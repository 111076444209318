import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Wrapper from "./Wrapper";
import List from "./List";
import DropdownInput from "components/Comman/Inputs/DropdownInput";
import SimpleInput from "components/Comman/Inputs/SimpleInput";
import SimpleTextArea from "components/Comman/Inputs/SimpleTextArea";
import ChronosButton from "components/Comman/Buttons";
import { Check } from "@phosphor-icons/react";
import CharacterConflictCard from "components/Character/Card/CharacterConflictCard";
import * as MarketingAPI from "config/APIs/marketing/index";

function CreateEditIdeas({ details, setDetails, onUpdate }) {
  const [campaignData, setCampaignData] = useState({});

  const topicCluster = useSelector((state) => state?.marketing?.topicCluster);
  const contentTypes = useSelector((state) => state?.marketing?.contentTypes);
  const campaign = useSelector((state) => state?.marketing?.campaign);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && details?.marketingCampigns?.length > 0) {
      fetchDetails();
    }

    return () => {
      isMounted = false;
    };
  }, [details?.marketingCampigns]);

  const fetchDetails = async () => {
    try {
      const response = await MarketingAPI.getOneEntity(
        "campaign",
        details?.marketingCampigns[0]?.id
      );
      if (response?.data) {
        const data = response.data.data;
        let { characters, conflicts, objectives, keyResults, ...value } = data;
        let temp = [...objectives] || [];
        keyResults?.map((item) => {
          if (
            temp?.find((o) => o?.id == item?.objective_id) &&
            temp?.find((o) => o?.id == item?.objective_id)?.keyResults
          ) {
            let idx = temp?.findIndex((o) => o?.id == item?.objective_id);
            temp[idx].keyResults.push(item);
          } else if (temp?.find((o) => o?.id == item?.objective_id)) {
            let idx = temp?.findIndex((o) => o?.id == item?.objective_id);
            temp[idx]["keyResults"] = [item];
          } else {
            temp.push({ ...item?.objective, keyResults: [item] });
          }
        });
        value["objectives"] = temp;
        temp = [...characters] || [];
        conflicts?.map((item) => {
          if (
            temp?.find((o) => o?.id == item?.marketingCharacterId) &&
            temp?.find((o) => o?.id == item?.marketingCharacterId)
              ?.marketingConflict
          ) {
            let idx = temp?.findIndex(
              (o) => o?.id == item?.marketingCharacterId
            );
            temp[idx].marketingConflict.push(item);
          } else if (temp?.find((o) => o?.id == item?.marketingCharacterId)) {
            let idx = temp?.findIndex(
              (o) => o?.id == item?.marketingCharacterId
            );
            temp[idx]["marketingConflict"] = [item];
          } else {
            temp.push({
              ...item?.marketingCharacter,
              marketingConflict: [item],
            });
          }
        });
        value["characters"] = temp;
        setCampaignData(value);
        console.log(value);
      }
    } catch (err) {
      console.log("FetchDetails error by ID", err);
    }
  };

  return (
    <div className="w-full space-y-8">
      <Wrapper heading="Select a Campaign to Create an Idea">
        <div className="w-full flex flex-col space-y-6 pt-3">
          <DropdownInput
            name="Campaign"
            srOnly
            list={campaign?.map((val) => ({
              label: val?.name,
              value: val?.id,
            }))}
            value={
              details?.marketingCampigns?.length > 0
                ? details?.marketingCampigns[0]?.id
                : null
            }
            setValue={(val) => {
              setDetails({
                ...details,
                marketingCampigns: [campaign?.find((v) => v?.id == val)],
              });
            }}
          />
        </div>
      </Wrapper>
      <Wrapper
        heading="Select a character conflict you want to address"
        description="This would be your target audience"
      >
        <div className="grid lg:grid-cols-2 gap-6">
          {campaignData?.characters?.map((char) => {
            return (
              <CharacterConflictCard
                item={char}
                selectAble
                selected={
                  details?.charactersConflicts?.find(
                    (t) => t?.character?.id == char?.id
                  )?.conflicts || []
                }
                onSelect={(val) => {
                  let temp = details?.charactersConflicts || [];
                  if (temp?.find((t) => t?.character?.id == char?.id)) {
                    let indx = temp?.findIndex(
                      (t) => t?.character?.id == char?.id
                    );
                    temp[indx]["conflicts"] = val;
                  } else {
                    temp.push({ character: char, conflicts: val });
                  }
                  setDetails({ ...details, charactersConflicts: temp });
                }}
              />
            );
          })}
        </div>
      </Wrapper>
      <Wrapper
        heading="Select a Content Pillar for this Idea"
        description="This would be the core subject matter"
      >
        <List
          list={campaignData?.contentPillars}
          data={details}
          setData={setDetails}
          field="contentPillars"
        />
      </Wrapper>
      <Wrapper
        heading="Select a Content Bucket"
        description="Think of the manner in which you would like to communicate this idea"
      >
        <List
          list={campaignData?.contentBuckets}
          data={details}
          setData={setDetails}
          field="marketingContentBuckets"
        />
      </Wrapper>
      <Wrapper
        heading="Choose a Topic Cluster"
        description="How you want to categorize your idea"
      >
        <List
          list={campaignData?.topicClusters}
          data={details}
          setData={setDetails}
          field="topicClusters"
        />
      </Wrapper>
      <Wrapper
        heading="Choose the platform you want to use"
        description="Where you want to interact with your audience"
      >
        <List
          list={campaignData?.contentPlatforms}
          data={details}
          setData={setDetails}
          field="contentPlatforms"
        />
      </Wrapper>
      <Wrapper
        heading="Select your Content Type"
        description="How you want to interact with your audience"
      >
        <DropdownInput
          name="Content Type"
          srOnly
          list={contentTypes?.map((val) => ({
            label: val?.type,
            value: val?.id,
          }))}
          value={
            details?.contentTypes?.length > 0
              ? details?.contentTypes[0]?.id
              : null
          }
          setValue={(val) => {
            setDetails({
              ...details,
              contentTypes: [contentTypes?.find((v) => v?.id == val)],
            });
          }}
        />
      </Wrapper>
      <Wrapper heading="Define Your Content Idea">
        <div className="w-full flex flex-col space-y-6 pt-3">
          <SimpleInput
            label="Title"
            field="name"
            details={details}
            setDetails={setDetails}
          />
          <SimpleTextArea
            label="Description"
            field="description"
            details={details}
            setDetails={setDetails}
          />
        </div>
      </Wrapper>
      <div className="flex flex-row items-center justify-center">
        <ChronosButton
          text="Save"
          primary
          onClick={() => {
            onUpdate();
          }}
          iconReverse
          icon={<Check size={16} className="mr-2.5" />}
        />
      </div>
    </div>
  );
}

export default CreateEditIdeas;
