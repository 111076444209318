import { Check, Pencil, Plus } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import Wrapper from "./Common/Wrapper";
import BusinessGoals from "./BusinessGoals";
import MarketingGoals from "./MarketingGoals";
import { useState } from "react";
import AddMarketingAttributesModal from "components/Comman/Modals/MultipleAddModals/AddMarketingAttributes";

function Goals({ details, setDetails, onUpdate }) {
  const [editMode, setEditMode] = useState(true);
  const [editModal, setEditModal] = useState({ isOpen: false, type: "" });
  return (
    <>
      <AddMarketingAttributesModal
        isOpen={editModal?.isOpen}
        closeModal={() => {
          setEditModal({ isOpen: false });
        }}
        label={editModal?.label}
        type={editModal?.type}
        onAdd={(val) => {
          let temp = [...val];
          switch (editModal?.type) {
            case "characters":
              let char = temp?.filter((c) => c?.marketingConflict?.length > 0);
              let con = [];
              char?.map((c) => {
                con = con.concat(c?.marketingConflict);
              });
              setDetails({ ...details, characters: char, conflicts: con });
              onUpdate({ ...details, characters: char, conflicts: con });
              break;
            case "objectives":
              let obj = temp?.filter((c) => c?.keyResults?.length > 0);
              let kr = [];
              obj?.map((c) => {
                kr = kr.concat(c?.keyResults);
              });
              setDetails({ ...details, objectives: obj, keyResults: kr });
              onUpdate({ ...details, objectives: obj, keyResults: kr });
              break;
            default:
              setDetails({ ...details, [editModal?.type]: val });
              onUpdate({ ...details, [editModal?.type]: val });
          }

          setEditModal({ isOpen: false });
        }}
        selected={editModal?.selected}
      />
      <div className="p-4 bg-white rounded-2xl space-y-10">
        <div className="px-0 lg:px-6 flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:items-center justify-between text-primary-aqua-medium text-lg font-poppins font-medium">
          <p className="font-lato text-primary-neutral-500 text-xs lg:text-sm font-light">
            Here you can set the business and marketing goals you want to target
            through this campaign.{" "}
          </p>
          {/* {editMode ? (
            <div className="flex flex-row items-stretch space-x-4 lg:justify-end justify-between">
              <ChronosButton
                tertiary
                underline
                text="Cancel"
                onClick={() => {
                  setEditMode(!editMode);
                }}
              />
              <ChronosButton
                text="Save Changes"
                primary
                onClick={() => {
                  if (editMode) {
                    onUpdate();
                  }
                  setEditMode(!editMode);
                }}
                iconReverse
                icon={<Check size={16} className="mr-2.5" />}
              />
            </div>
          ) : (
            <ChronosButton
              light
              text={editMode ? "Save" : "Edit Campaign Goals"}
              primary
              onClick={() => {
                if (editMode) {
                  onUpdate();
                }
                setEditMode(!editMode);
              }}
              iconReverse
              icon={<Pencil size={16} className="mr-2.5" />}
            />
          )} */}
        </div>
        <Wrapper
          heading="Which business goals do you want to target?"
          description="Select the end-goals towards which you’d want to focus your marketing efforts"
        >
          {editMode && (
            <ChronosButton
              text={`${
                details?.businessGoals?.length > 0 ? "Edit" : "Add"
              } Business Goals`}
              tertiary
              underline
              onClick={() => {
                setEditModal({
                  isOpen: true,
                  type: "businessGoals",
                  selected: details?.businessGoals,
                  label: "Business Goals",
                });
              }}
              iconReverse
              icon={
                details?.businessGoals?.length > 0 ? (
                  <Pencil size={16} className="mr-2.5" />
                ) : (
                  <Plus size={16} className="mr-2.5" />
                )
              }
            />
          )}
          <BusinessGoals goals={details?.businessGoals} />
        </Wrapper>
        <Wrapper heading="What are your Marketing Goals?">
          {editMode && (
            <ChronosButton
              text={`${
                details?.objectives?.length > 0 ? "Edit" : "Add"
              } Marketing Goals`}
              tertiary
              underline
              onClick={() => {
                let temp = [...details.objectives] || [];
                details?.keyResults?.map((item) => {
                  if (
                    temp?.find((o) => o?.id == item?.objective_id) &&
                    temp?.find((o) => o?.id == item?.objective_id)?.keyResults
                  ) {
                    let idx = temp?.findIndex(
                      (o) => o?.id == item?.objective_id
                    );
                    temp[idx].keyResults.push(item);
                  } else if (temp?.find((o) => o?.id == item?.objective_id)) {
                    let idx = temp?.findIndex(
                      (o) => o?.id == item?.objective_id
                    );
                    temp[idx]["keyResults"] = [item];
                  } else {
                    temp.push({ ...item?.objective, keyResults: [item] });
                  }
                });
                setEditModal({
                  isOpen: true,
                  type: "objectives",
                  selected: temp,
                  label: "Marketing Goals",
                });
              }}
              iconReverse
              icon={
                details?.objectives?.length > 0 ? (
                  <Pencil size={16} className="mr-2.5" />
                ) : (
                  <Plus size={16} className="mr-2.5" />
                )
              }
            />
          )}
          <MarketingGoals
            objectives={details?.objectives}
            keyResults={details?.keyResults}
          />
        </Wrapper>
      </div>
    </>
  );
}

export default Goals;
