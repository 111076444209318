import { useHistory } from "react-router-dom";
import Settings from "components/Comman/Elements/SettingsDropdown";
import StatusDropDown from "components/Comman/Elements/StatusDropdown";
import moment from "moment";

function Card({ campaign, afterDelete, onUpdate, index, onClick = () => {} }) {
  const history = useHistory();

  return (
    <>
      <div
        onClick={onClick}
        className="p-2 cursor-pointer bg-white rounded-lg shadow"
      >
        <div
          className={`${
            campaign?.description ? "border-b" : ""
          } p-2 border-primary-gray-200 flex flex-row items-center justify-between space-x-2`}
        >
          <div className="flex flex-col items-start space-y-1">
            <p className="text-primary-neutral-500 font-lato text-2xs font-semibold">
              {campaign?.code}
            </p>
            <p className="text-primary-aqua-medium font-lato text-base font-semibold">
              {campaign?.name}
            </p>
            <p className="text-primary-neutral-500 text-2xs font-lato font-light">
              {moment(campaign?.startDate).format("ll")} -{" "}
              {moment(campaign?.endDate).format("ll")}
            </p>
          </div>
        </div>
        {campaign?.description && (
          <p className="px-3 py-2 text-primary-neutral-800 font-lato text-xs font-light">
            {campaign?.description}
          </p>
        )}
      </div>
    </>
  );
}

export default Card;
