import { addOrRemove } from "helpers/utils/common/array";
import React from "react";

function ObjectiveKRCard({ item, onSelect, selected, selectAble, keyResults }) {
  return (
    <div className="p-4 bg-white shadow-md rounded-lg border-primary-100 grid md:grid-cols-2 lg:divide-x">
      <div className="lg:py-6 lg:pr-4 text-primary-aqua-medium text-lg font-lato font-semibold">
        {item?.title}
      </div>
      <div className="flex flex-col items-start space-y-2 px-2 py-4">
        <p className="text-primary-gray-800 font-lato font-semibold text-sm">
          Key Results
        </p>
        {keyResults
          ? keyResults
              ?.filter((k) => k?.objective_id == item?.id)
              ?.map((kr) => {
                return (
                  <div className="flex flex-row items-center space-x-1 p-2">
                    <p className="text-primary-gray-800 font-lato text-sm">
                      {kr?.title}
                    </p>
                  </div>
                );
              })
          : item?.keyResults?.length > 0
          ? item?.keyResults?.map((kr) => {
              return (
                <div className="flex flex-row items-center justify-between space-x-1 p-2">
                  <p className="text-primary-gray-800 font-lato text-sm">
                    {kr?.title}
                  </p>
                  {selectAble && (
                    <input
                      type="checkbox"
                      checked={selected?.find((i) => i?.id == kr?.id)}
                      onChange={() => {
                        let temp = addOrRemove(selected, kr);
                        onSelect(temp);
                      }}
                    />
                  )}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default ObjectiveKRCard;
