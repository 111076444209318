import { UserIcon } from "@heroicons/react/solid";
import { DefaultAvatar } from "helpers/utils/common/icons";
/**
 *
 * @param {Object} event
 * @param {Function} onClick
 * @returns
 */

function EventCard({ event, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`release-card space-y-2.5 theme-card p-5 font-lato`}
    >
      <h1 className="text-sm text-primary-gray-600 font-bold">
        {event?.name || event?.title}
      </h1>
      <div className="flex flex-row items-center space-x-1.5 justify-between">
        <div className="flex flex-row items-center" key={event?.publisher?.id}>
          <img
            src={event?.publisher?.image?.url || DefaultAvatar}
            className="rounded-full h-3 w-3 mr-2"
            alt=""
          />

          <p className="text-xs">{event?.publisher?.first_name || "NA"}</p>
        </div>
      </div>
    </div>
  );
}

function EventCardLoader() {
  return (
    <div className={`release-card space-y-2.5 failed`}>
      <p className="font-lato text-sm h-2 rounded bg-primary-lightest animate-pulse w-full font-normal text-primary-gray-600"></p>

      <div className="flex flex-row items-center space-x-1.5 justify-between">
        <div className="flex flex-row items-center">
          <UserIcon className="rounded-full h-3 w-3 mr-2 -mt-1" />

          <p className="font-lato text-sm h-2 rounded bg-primary-lightest animate-pulse w-32 font-normal text-primary-gray-600"></p>
        </div>
        <div className="capitalize">
          <p className="font-lato text-sm h-2 rounded bg-primary-lightest animate-pulse w-20 font-normal text-primary-gray-600"></p>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
export { EventCardLoader };
