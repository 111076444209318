import { useState, useEffect } from "react";
import moment from "moment";
import EventCard, { EventCardLoader } from "./Card/index";
import ContentIdeaOverlay from "./ContentIdeaOverlay";
import EmptyState from "components/Comman/EmptyState";

function CardViews({ offerings, fetching }) {
  const [list, setList] = useState([]);
  const [selectedIdea, setSelectedIdea] = useState();
  useEffect(() => {
    let dates = [];

    let temp = [];

    offerings?.map((i) => {
      if (dates?.includes(moment(i?.release_date)?.format("DD-MM-YYYY"))) {
        temp?.map((t) => {
          if (
            moment(t?.date)?.format("DD-MM-YYYY") ==
            moment(i?.release_date)?.format("DD-MM-YYYY")
          ) {
            t.release.push(i);
          }
        });
      } else {
        dates.push(moment(i?.release_date)?.format("DD-MM-YYYY"));
        let obj = {
          date: i?.release_date,
          release: [i],
        };
        temp.push(obj);
      }
    });

    setList(temp);
  }, [offerings]);

  return (
    <div className="py-5 w-full space-y-5">
      <ContentIdeaOverlay
        isOpen={selectedIdea ? true : false}
        closeModal={() => {
          setSelectedIdea();
        }}
        id={selectedIdea}
      />
      {!fetching ? (
        list?.length > 0 ? (
          list?.map((item) => {
            return (
              <div className="space-y-2.5 py-2.5 border-b border-chronos-gray-60 font-karla">
                <div className="flex flex-row items-center space-x-1.5">
                  <div className="flex flex-row items-center justify-center bg-primary-gray-600 p-1 h-6 w-6 text-white text-xs font-lato font-bold rounded-full">
                    {moment(item?.date).format("DD")}
                  </div>
                  <div className="text-white text-xs font-lato font-bold rounded-full">
                    {moment(item?.date).format("MMM")}
                  </div>
                </div>
                {item?.release?.map((r) => {
                  return (
                    <EventCard
                      event={r}
                      onClick={() => {
                        setSelectedIdea(r?.id);
                      }}
                    />
                  );
                })}
              </div>
            );
          })
        ) : (
          <EmptyState />
        )
      ) : (
        <>
          {[1, 2, 3, 4, 5, 6].map((item) => {
            return <EventCardLoader event={item} />;
          })}
        </>
      )}
    </div>
  );
}

export default CardViews;
