import * as MarketingAPI from "config/APIs/marketing";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { showToast } from "redux/toaster";
import CharacterConflicts from "./create/CharacterConflicts";
import Details from "./create/Details";
import { ArrowLeft } from "@phosphor-icons/react";

const defaultValue = {
  tags: [],
};

const CreateIdea = () => {
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [newData, setNewData] = useState(defaultValue);
  const [charactersConflicts, setCharactersConflicts] = useState([]);
  const [creating, setCreating] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleCreate = async () => {
    setCreating(true);
    let {
      topicClusters,
      marketingContentBuckets,
      marketingContentTypes,
      contentPlatforms,
      contentPillars,
      tags,
      ...values
    } = newData;
    let body = values;
    if (topicClusters) body["topicClusters"] = topicClusters?.map((i) => i?.id);
    if (marketingContentBuckets)
      body["contentBuckets"] = marketingContentBuckets?.map((i) => i?.id);
    if (contentPillars) body["pillars"] = contentPillars?.map((i) => i?.id);
    if (tags) body["tags"] = tags?.map((i) => i?.id);
    if (marketingContentTypes)
      body["contentTypes"] = marketingContentTypes?.map((i) => i?.id);
    if (contentPlatforms)
      body["platforms"] = contentPlatforms?.map((i) => i?.id);

    if (charactersConflicts?.length > 0) {
      body["characters"] = charactersConflicts?.map((i) => i?.character?.id);
      let temp = [];
      charactersConflicts?.map((i) => {
        if (i?.conflicts?.length > 0) {
          temp = temp.concat(i?.conflicts);
        }
      });
      body["conflicts"] = temp?.map((i) => i?.id);
    }
    body["type"] = "Community";
    try {
      const response = await MarketingAPI.createEntity("contentOffering", body);
      if (response.data) {
        history.goBack();
      }
    } catch (err) {
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
  };

  const handleUpdate = async () => {
    setCreating(true);
    let {
      topicClusters,
      marketingContentBuckets,
      marketingContentTypes,
      contentPlatforms,
      contentPillars,
      tags,
      ...values
    } = newData;
    let body = values;
    if (topicClusters) body["topicClusters"] = topicClusters?.map((i) => i?.id);
    if (marketingContentBuckets)
      body["contentBuckets"] = marketingContentBuckets?.map((i) => i?.id);
    if (contentPillars) body["pillars"] = contentPillars?.map((i) => i?.id);
    if (tags) body["tags"] = tags?.map((i) => i?.id);
    if (marketingContentTypes)
      body["contentTypes"] = marketingContentTypes?.map((i) => i?.id);
    if (contentPlatforms)
      body["platforms"] = contentPlatforms?.map((i) => i?.id);

    if (charactersConflicts?.length > 0) {
      body["characters"] = charactersConflicts?.map((i) => i?.character?.id);
      let temp = [];
      charactersConflicts?.map((i) => {
        if (i?.conflicts?.length > 0) {
          temp = temp.concat(i?.conflicts);
        }
      });
      body["conflicts"] = temp?.map((i) => i?.id);
    }

    try {
      const response = await MarketingAPI.updateEntity(
        "contentOffering",
        id,
        body
      );
      if (response.data) {
        history.goBack();
      }
    } catch (err) {
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && id && id !== 0) {
      fetchDetails();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  const fetchDetails = async () => {
    try {
      const response = await MarketingAPI.getOneEntity("contentOffering", id);
      if (response?.data) {
        const data = response.data.data;

        // let { characters, conflicts, objectives, keyResults, ...value } = data;
        // let temp = [...objectives] || [];
        // keyResults?.map((item) => {
        //   if (
        //     temp?.find((o) => o?.id == item?.objective_id) &&
        //     temp?.find((o) => o?.id == item?.objective_id)?.keyResults
        //   ) {
        //     let idx = temp?.findIndex((o) => o?.id == item?.objective_id);
        //     temp[idx].keyResults.push(item);
        //   } else if (temp?.find((o) => o?.id == item?.objective_id)) {
        //     let idx = temp?.findIndex((o) => o?.id == item?.objective_id);
        //     temp[idx]["keyResults"] = [item];
        //   } else {
        //     temp.push({ ...item?.objective, keyResults: [item] });
        //   }
        // });
        // value["objectives"] = temp;
        // temp = [...characters] || [];
        // conflicts?.map((item) => {
        //   if (
        //     temp?.find((o) => o?.id == item?.marketingCharacterId) &&
        //     temp?.find((o) => o?.id == item?.marketingCharacterId)
        //       ?.marketingConflict
        //   ) {
        //     let idx = temp?.findIndex(
        //       (o) => o?.id == item?.marketingCharacterId
        //     );
        //     temp[idx].marketingConflict.push(item);
        //   } else if (temp?.find((o) => o?.id == item?.marketingCharacterId)) {
        //     let idx = temp?.findIndex(
        //       (o) => o?.id == item?.marketingCharacterId
        //     );
        //     temp[idx]["marketingConflict"] = [item];
        //   } else {
        //     temp.push({
        //       ...item?.marketingCharacter,
        //       marketingConflict: [item],
        //     });
        //   }
        // });
        // value["characters"] = temp;
        setNewData(data);
      }
    } catch (err) {
      console.log("FetchDetails error by ID", err);
    }
  };

  return (
    <div className="pb-6 px-2.5 relative">
      {" "}
      <div
        onClick={() => {
          history.goBack();
        }}
        className="pb-2 bg-primary-gray-50 z-20 pt-3 fixed top-11 right-0 left-0 font-semibold px-3 flex flex-row items-center space-x-2 text-primary-gray-1000 font-lato text-xs cursor-pointer"
      >
        <ArrowLeft size={12} />
        <p>Back</p>
      </div>
      <div className="pt-0 px-0.5 md:pt-16 mt-8 lg:mt-5 mb-12 md:px-4 w-full relative">
        <div className="flex flex-col lg:flex-row items-start justify-between lg:space-x-8">
          <div className="w-full lg:w-2/6">
            <Details details={newData} setDetails={setNewData} />
          </div>
          <div className="w-full">
            <CharacterConflicts
              list={charactersConflicts}
              setList={setCharactersConflicts}
              onCancel={() => history.goBack()}
              onSubmit={() => handleCreate()}
              onUpdate={() => handleUpdate()}
              updating={updating}
              creating={creating}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateIdea;
