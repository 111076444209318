// TODO : Update documentation

/**
 * Simple Text Area field for changing values in an object
 * @param { String } label
 * @param { String } field - Key in the details oject
 * @param { Object } details - Object containing the details of the entity
 * @param { Function } setDetails - Function to changed the value of the given field in details object
 * @param { Boolean } srOnly - To display the label or not
 * @param { Boolean } required - to set the field as required
 * @returns
 */
function SimpleTextArea({
  label,
  field,
  rows,
  details,
  setDetails,
  srOnly = false,
  isDisabled,
  showError,
  required = false,
  placeholder,
  errorMessage = "Please fill in the details",
}) {
  return (
    <>
      <div className="flex flex-col items-start w-full border-opacity-40">
        {!srOnly && (
          <label htmlFor={label} className={srOnly ? "sr-only" : "input-label"}>
            {required ? label + "*" : label}
          </label>
        )}
        <textarea
          id={label}
          rows={rows}
          name={label}
          disabled={isDisabled}
          placeholder={placeholder || `Enter ${label}`}
          value={details[field] || ""}
          onChange={(e) => {
            setDetails({ ...details, [field]: e.target.value });
          }}
          className="w-full p-4  rounded-lg bg-primary-gray-50  border border-primary-gray-200 appearance-none border-none text-primary-gray-800 font-lato font-normal text-sm leading-6 placeholder:text-gray-300 focus:outline-none focus:ring-transparent outline-none"
        ></textarea>
        {showError &&
          (!details[field] || (details[field] && details[field] == "")) && (
            <p className="text-alert text-2xs flex flex-row items-center mt-1 ml-0.5">
              {errorMessage}
            </p>
          )}
      </div>
    </>
  );
}

export default SimpleTextArea;
