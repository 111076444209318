import React, { useEffect, useState } from "react";
//Icons
import { SearchIcon } from "@heroicons/react/solid";
export const SEARCH_BAR_TOGGLE_TYPE = "SEARCH_BAR_TOGGLE_TYPE";

const FilterDropDown = ({ label, values, onSelect }) => {
  return (
    <div className="mr-1 theme-input px-4 py-2 mb-2 inter text-sm font-normal text-primary-gray-600">
      <select
        className="bg-transparent w-36"
        onChange={(e) => {
          onSelect(e.target.value);
        }}
      >
        <option value={label}>{label}</option>
        {values?.map((value) => (
          <option id={value} key={value} onClick={() => onSelect(value)}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

/**
 * Search Filter Component
 * @param {Object} searchList - Objects containing details after filter is applied
 * @param {Function} setSearchList - Function to set the search list
 * @param {Object} originalList - Object containing details before filter or original details
 * @returns
 */

const SearchFilter = ({
  searchList,
  setSearchList,
  originalList,
  options,
  newLine,
  params,
  onSearch,
  onFilter,
  queryFilter,
  setSearchItem,
  isSearch,
  white = false,
  rounded,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectValues, setSelectValues] = useState([]);
  const [toggles, setToggles] = useState([]);

  useEffect(() => {
    if (originalList?.length > 0) {
      filterList();
    }
  }, [originalList]);

  useEffect(() => {
    if (setSearchItem) setSearchItem(inputValue);
    filterList();
  }, [selectValues, inputValue, toggles]);

  useEffect(() => {
    if (!isSearch && inputValue !== "") {
      setInputValue("");
    }
  }, [isSearch]);

  const filterList = () => {
    let filterList = [...originalList];
    filterList = filterToggles(filterList);
    filterList = filterSearch(filterList);
    filterList = filterSearchItems(filterList);
    setSearchList([...filterList]);
  };

  const filterToggles = (list) => {
    if (toggles.length === 0) {
      return list;
    }
    let filter = [...list].filter((e) => {
      for (let toggle of toggles) {
        if (!e[toggle]) return false;
      }
      return true;
    });
    return filter;
  };

  const filterSearchItems = (list) => {
    if (selectValues.length === 0) {
      return list;
    }
    let filter = [...list].filter((element) => {
      let e = { ...element };
      for (let { value, field } of selectValues) {
        if (
          !JSON.stringify(field ? e[field] : e)
            ?.toLowerCase()
            .includes(value.toLowerCase())
        )
          return false;
      }
      return true;
    });
    return filter;
  };
  const filterSearch = (list) => {
    let searchTerm = inputValue.toLowerCase();
    if (searchTerm === "") {
      return list;
    }
    let filter = [...list].filter((element) => {
      let e = { ...element };
      if (params) {
        Object.keys(e).map((key) => {
          if (!params.includes(key)) {
            delete e[key];
          }
        });
      }
      return JSON.stringify(e).toLowerCase().includes(searchTerm);
    });
    return filter;
  };

  return (
    <div
      className={`w-full flex-col flex md:flex-row items-start md:mt-2 md:flex`}
    >
      <div
        style={{ flexGrow: 1 }}
        className={`mb-2 md:mr-2 ${
          options && options?.length > 0 ? "w-1/2" : "w-full"
        } inter text-sm font-normal text-primary-gray-600`}
      >
        <div className="flex flex-row items center theme-input py-2 px-4">
          <SearchIcon className="w-5 h-5 text-primary-gray-350" />
          <input
            className="p-0 border-0 bg-transparent pl-2"
            placeholder="Search..."
            value={inputValue}
            onChange={(e) => setInputValue(e?.target?.value)}
          />
        </div>
      </div>
      <div
        className={`md:ml-5 flex flex-wrap`}
        style={{ marginLeft: newLine ? -3 : 0 }}
      >
        {queryFilter && queryFilter.length > 0 && (
          <div className="mr-1 bg-white rounded-lg px-4 py-2 mb-2">
            <select
              className="bg-transparent w-36"
              onChange={(e) => {
                onFilter(JSON.parse(e.target.value));
              }}
            >
              <option>Project</option>
              {queryFilter.map((value) => (
                <option
                  id={value.value}
                  key={value.value}
                  onClick={() => onFilter(value)}
                  value={JSON.stringify(value)}
                >
                  {value.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {options &&
          options?.map(({ label, values, field, type }) => (
            <div>
              {type !== SEARCH_BAR_TOGGLE_TYPE && (
                <FilterDropDown
                  label={label}
                  values={values}
                  onSelect={(value) =>
                    setSelectValues(() =>
                      value != "Status"
                        ? [{ label: value, value: `"${value}"`, field }]
                        : []
                    )
                  }
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SearchFilter;
