import { StarIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";

function ContentCard({ idea, setToDelete, onEdit, handleUpdate }) {
  const [starred, setStarred] = useState(idea?.starred);

  useEffect(() => {
    setStarred(idea?.starred);
  }, [idea]);

  return (
    <div className="relative px-6 py-4 border-l-[5px] border-primary-aqua-medium space-y-2.5">
      <div className="flex flex-row items-center space-x-6 justify-end">
        <svg
          onClick={() => {
            setToDelete(idea);
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5.0013 15.8333C5.0013 16.75 5.7513 17.5 6.66797 17.5H13.3346C14.2513 17.5 15.0013 16.75 15.0013 15.8333V5.83333H5.0013V15.8333ZM6.66797 7.5H13.3346V15.8333H6.66797V7.5ZM12.918 3.33333L12.0846 2.5H7.91797L7.08464 3.33333H4.16797V5H15.8346V3.33333H12.918Z"
            fill="#ED4C41"
          />
        </svg>{" "}
        <svg
          onClick={() => {
            onEdit(idea);
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
        >
          <path
            d="M3.345 12.3337L11.7967 3.88206L10.6183 2.70373L2.16667 11.1554V12.3337H3.345ZM4.03583 14.0004H0.5V10.4646L10.0292 0.935395C10.1854 0.779169 10.3974 0.691406 10.6183 0.691406C10.8393 0.691406 11.0512 0.779169 11.2075 0.935395L13.565 3.2929C13.7212 3.44917 13.809 3.66109 13.809 3.88206C13.809 4.10303 13.7212 4.31496 13.565 4.47123L4.03583 14.0004ZM0.5 15.6671H15.5V17.3337H0.5V15.6671Z"
            fill="#0497AE"
          />
        </svg>{" "}
        {starred ? (
          <StarIcon
            onClick={() => {
              handleUpdate(idea?.id, !starred);
              setStarred(!starred);
            }}
            className="w-5 h-5 text-primary-aqua-medium"
          />
        ) : (
          <svg
            onClick={() => {
              handleUpdate(idea?.id, !starred);
              setStarred(!starred);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M18.3346 8.11732L12.343 7.60065L10.0013 2.08398L7.65964 7.60898L1.66797 8.11732L6.21797 12.059L4.8513 17.9173L10.0013 14.809L15.1513 17.9173L13.793 12.059L18.3346 8.11732ZM10.0013 13.2507L6.86797 15.1423L7.7013 11.5757L4.93464 9.17565L8.58464 8.85898L10.0013 5.50065L11.4263 8.86732L15.0763 9.18398L12.3096 11.584L13.143 15.1507L10.0013 13.2507Z"
              fill="#9C9A96"
            />
          </svg>
        )}{" "}
      </div>
      <h1 className="text-primary-gray-800 font-semibold text-lg font-lato w-[94%]">
        {idea?.name}
      </h1>
      <p className="text-primary-gray-800 text-base font-lato">
        {idea?.description}
      </p>
      <div className="flex flex-row justify-between  items-center">
        <div className="flex flex-wrap flex-row">
          <div className="flex flex-row items-center space-x-1.5 text-primary-aqua-medium font-bold font-lato text-sm mr-4">
            <h1 className="">
              {idea?.marketingContentBuckets?.map((i) => i?.name)?.join(",")}
            </h1>
          </div>
        </div>{" "}
        <div className="flex flex-wrap flex-row">
          {idea?.contentPlatforms?.length > 0 && (
            <div className="mr-1 flex flex-row items-center space-x-1.5 text-primary-neutral-500 font-lato text-sm">
              <h1 className="">
                {idea?.contentPlatforms?.length > 0
                  ? idea?.contentPlatforms[0]?.name
                  : "-"}
                /
              </h1>
            </div>
          )}
          {idea?.marketingContentTypes?.length > 0 && (
            <div className="mr-2 flex flex-row items-center space-x-1.5 text-primary-aqua-medium font-bold font-lato text-sm">
              <h1 className="">
                {idea?.marketingContentTypes?.length > 0
                  ? idea?.marketingContentTypes[0]?.type
                  : "-"}
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContentCard;
