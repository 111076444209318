import VerticalCard from "components/Comman/Cards/ControlCard/VerticalCard";
import EmptyState from "components/Comman/EmptyState";

function TopicCluster({ topicClusters }) {
  return (
    <div className="bg-primary-gray-50 rounded-lg px-4 py-2 max-w-full overflow-x-auto">
      <div className="lg:grid grid-cols-5 lg:gap-4 flex flex-row items-stretch space-x-4 lg:space-x-0">
        {topicClusters?.length > 0 ? (
          topicClusters?.map((t) => {
            return <VerticalCard item={t} />;
          })
        ) : (
          <div className="col-span-5 flex flex-row items-center justify-center">
            <EmptyState
              noImage
              text="The topic cluster you add will be shown here"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TopicCluster;
