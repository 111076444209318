import { X } from "@phosphor-icons/react";

function TagElement({ tag, editable, onRemove, onClick }) {
  return (
    <>
      {editable ? (
        <div className="cursor-pointer px-4 py-1 m-1.5 rounded-full flex flex-row items-center justify-between space-x-2 bg-primary-aqua-50 text-primary-aqua-medium">
          <p className="text-sm font-lato w-11/12">
            {tag?.name ||
              tag?.title ||
              tag?.type ||
              tag?.platform ||
              tag?.character}
          </p>
          <X onClick={() => onRemove()} size={16} />
        </div>
      ) : (
        <div
          onClick={onClick}
          className="cursor-pointer px-4 py-1 m-1.5 rounded-full flex flex-row items-center justify-between space-x-2 text-primary-gray-800 border border-primary-aqua-medium"
        >
          <p className="text-sm font-lato">
            {tag?.name ||
              tag?.title ||
              tag?.type ||
              tag?.platform ||
              tag?.character}
          </p>
        </div>
      )}
    </>
  );
}

export default TagElement;
