const addOrRemove = (list, item) => {
  let temp = list ? [...list] : [];
  if (list?.find((i) => i?.id == item?.id)) {
    temp = temp?.filter((i) => i?.id !== item?.id);
  } else {
    temp.push(item);
  }
  return temp;
};

export { addOrRemove };
