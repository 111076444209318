import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

// Storing campaign details and Token
export const campaign = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    updateList: (state, action) => {
      let list = action.payload?.list;

      state.list = list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = campaign.actions;

export default campaign.reducer;
