import React, { useEffect, useState } from "react";
import ContentIdeas from "components/Creatives/ContentIdeas";

import { updateIdeas } from "redux/contentIdea";
import * as MarketingAPI from "config/APIs/marketing";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab, updateTabs } from "redux/navigation";
import CreateEditIdeas from "components/Creatives/CreateEditIdea";
import { showToast } from "redux/toaster";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const tabs = [
  { label: "Build Ideas", value: "build" },
  { label: "Your Ideas", value: "yourIdeas" },
];

function ContentIdea() {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedTab =
    useSelector((state) => state?.sideNav?.selectedTab) || "yourIdeas";
  const [list, setList] = useState([]);
  const [newData, setNewData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editIdea, setEditIdea] = useState();
  const [creating, setCreating] = React.useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    dispatch(updateTabs({ selectedTab: "yourIdeas" }));
  }, []);
  useEffect(() => {
    if (selectedTab == "yourIdeas") {
      setNewData({});
      setEditIdea();
      setEditMode(false);
    }
  }, [selectedTab]);
  const fetchList = async () => {
    setFetching(true);
    try {
      const response = await MarketingAPI.getAllEntity("contentOffering");
      if (response?.data) {
        const offerings = response.data.data;

        setList(offerings);
        dispatch(updateIdeas({ list: offerings }));
        setFetching(false);
      }
    } catch (err) {
      setFetching(false);
      console.log("Fetch marking get all error", err);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchList();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const getIdeas = () => {
    // if (selectedTab === "yourIdeas") {
    //   return list.filter((data) => data?.publisherId === user?.id) ?? [];
    // } else {
    return list;
    // }
  };

  const handleCreate = async () => {
    setCreating(true);
    let {
      topicClusters,
      marketingContentBuckets,
      contentTypes,
      contentPlatforms,
      contentPillars,
      tags,
      charactersConflicts,
      marketingCampigns,
      ...values
    } = newData;
    let body = values;
    if (topicClusters) body["topicClusters"] = topicClusters?.map((i) => i?.id);
    if (marketingContentBuckets)
      body["contentBuckets"] = marketingContentBuckets?.map((i) => i?.id);
    if (contentPillars) body["pillars"] = contentPillars?.map((i) => i?.id);
    if (tags) body["tags"] = tags?.map((i) => i?.id);
    if (contentTypes) body["contentTypes"] = contentTypes?.map((i) => i?.id);
    if (marketingCampigns)
      body["campaigns"] = marketingCampigns?.map((i) => i?.id);
    if (contentPlatforms)
      body["platforms"] = contentPlatforms?.map((i) => i?.id);

    if (charactersConflicts?.length > 0) {
      body["characters"] = charactersConflicts
        ?.filter((i) => i?.conflicts?.length > 0)
        ?.map((i) => i?.character?.id);
      let temp = [];
      charactersConflicts?.map((i) => {
        if (i?.conflicts?.length > 0) {
          temp = temp.concat(i?.conflicts);
        }
      });
      body["conflicts"] = temp?.map((i) => i?.id);
    }

    try {
      const response = editMode
        ? await MarketingAPI.updateEntity("contentOffering", editIdea?.id, body)
        : await MarketingAPI.createEntity("contentOffering", body);
      if (response.data) {
        setNewData({});
        fetchList();
        dispatch(setSelectedTab({ selectedTab: "yourIdeas" }));
      }
    } catch (err) {
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification(err.response?.data?.message);
      }
    }
  };
  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleDelete = async (id) => {
    try {
      const response = await MarketingAPI.deleteEntity("contentOffering", id);
      if (response.data) {
        fetchList();
      }
    } catch (err) {
      switch (err.response?.status) {
      }
    }
  };
  return (
    <>
      <div className="p-4 bg-white rounded-2xl space-y-6">
        {/* <div
          id="tabHead"
          className="px-0 lg:px-6 py-4 flex flex-row items-center justify-between text-primary-aqua-medium text-lg font-poppins font-medium"
        >
          <p>
            {selectedTab == "yourIdeas"
              ? "Your Content Ideas"
              : "Build an Idea"}
          </p>
          {selectedTab == "yourIdeas" && (
            <ChronosButton
              text="Add New Idea"
              primary
              onClick={() => {
                setNewData({});
                dispatch(setSelectedTab({ selectedTab: "build" }));
              }}
              iconReverse
              icon={<Plus size={16} className="mr-2.5" />}
            />
          )}
        </div> */}
        {console.log(editIdea, newData, editMode)}
        {selectedTab == "yourIdeas" && (
          <ContentIdeas
            onEdit={(val) => {
              history.push(`/contentIdeas/edit/${val?.id}`);
              // setEditIdea(val);
              // setEditMode(true);
              // setNewData(val);
              // dispatch(setSelectedTab({ selectedTab: "build" }));
            }}
            list={getIdeas(list)}
            onUpdate={() => {
              fetchList();
            }}
            onDelete={(val) => {
              handleDelete(val);
            }}
          />
        )}
        {selectedTab == "build" && (
          <CreateEditIdeas
            details={newData}
            setDetails={setNewData}
            onUpdate={() => {
              handleCreate();
            }}
          />
        )}
      </div>
    </>
  );
}

export default ContentIdea;
