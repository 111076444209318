import ChronosButton from "components/Comman/Buttons";
import Drawer from "@mui/material/Drawer";

const AddConflicts = ({ closeModal, character, onAddConflict, isOpen }) => {
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={500}
    >
      <div className=" p-7.5  w-full lg:max-w-md mx-auto modals-component bg-white">
        <div>
          <h1 className="font-lato font-normal text-sm ">
            Would you like to add conflicts to the character
            <span className="font-bold"> "{character?.character}" </span> ?
          </h1>
          <div className="mt-8 flex w-full flex-row items-center space-x-4 justify-around">
            <div className="left-side">
              <ChronosButton
                text="Add Conflict"
                primary
                onClick={() => {
                  onAddConflict(character);
                  closeModal();
                }}
              />
            </div>
            <div className="right-side">
              <ChronosButton
                text="Not Now"
                secondary
                onClick={() => closeModal()}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AddConflicts;
