import TagElement from "components/Comman/Cards/Tags";
import EmptyState from "components/Comman/EmptyState";

function Tags({ tags }) {
  return (
    <div className="bg-primary-gray-50 rounded-lg px-4 py-2">
      <div className="flex flex-row items-center justify-start flex-wrap">
        {tags?.length > 0 ? (
          tags?.map((t) => {
            return <TagElement tag={t} />;
          })
        ) : (
          <div className="w-full flex flex-row items-center justify-center">
            <EmptyState noImage text="The tags you add will be shown here" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Tags;
