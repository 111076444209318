import CharacterConflictCard from "components/Character/Card/CharacterConflictCard";
import EmptyState from "components/Comman/EmptyState";

function CharacterConflict({ characters, conflicts }) {
  return (
    <div className="bg-primary-gray-50 rounded-lg px-4 py-2 max-w-full overflow-x-auto">
      <div className="grid lg:grid-cols-2 gap-4">
        {characters?.length > 0 ? (
          characters?.map((t) => {
            return <CharacterConflictCard item={t} conflicts={conflicts} />;
          })
        ) : (
          <div className="col-span-2 flex flex-row items-center justify-center">
            <EmptyState
              noImage
              text="The character conflicts you add will be shown here"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CharacterConflict;
