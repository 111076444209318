// TODO : Update documentation
import { useState, useEffect } from "react";

//datepicker
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
//icons
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/outline";

/**
 *
 * @param {Date} date -initial Date
 * @param {Function} onChange -Function called when date is changed
 * @returns Component to get Date Input
 *
 */

function DateInput({ date, onChange }) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [value, setValue] = useState(new Date(date));

  const decrementDate = () => {
    var today = new Date(value);
    var i = today.valueOf() - 86400000;
    today = new Date(i);
    setValue(today);
  };
  const incrementDate = () => {
    var today = new Date(value);
    var i = today.valueOf() + 86400000;
    today = new Date(i);
    setValue(today);
  };
  const onDateChange = (e) => {
    if (JSON.stringify(e._i) != JSON.stringify(e._d)) {
      var today = new Date(e._d);
      setValue(today);
    }
  };
  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        open={calendarOpen}
        label=""
        value={value}
        inputFormat="DD MMMM yyyy"
        mask=""
        onChange={(e) => {
          onDateChange(e);
          setCalendarOpen(false);
        }}
        renderInput={({ inputRef, inputProps }) => (
          <div
            onClick={() => setCalendarOpen(!calendarOpen)}
            className="flex items-center dateinput-div1 justify-between space-x-3 w-full"
          >
            <div className="flex">
              <ChevronLeftIcon
                className="w-3 cursor-pointer flex-shrink-0"
                onClick={(e) => {
                  e.preventDefault();
                  decrementDate();
                }}
              />
            </div>
            <div className="">
              <input
                ref={inputRef}
                {...inputProps}
                id="date"
                name="date"
                className="appearance-none text-center bg-transparent focus:outline-none focus:ring-transparent focus:border-transparent  "
              />
            </div>
            <div className="flex">
              <ChevronRightIcon
                onClick={(e) => {
                  e.preventDefault();
                  incrementDate();
                }}
                className="w-3 cursor-pointer flex-shrink-0"
              />
            </div>
          </div>
        )}
      />
    </LocalizationProvider>
  );
}

export default DateInput;
