import { createSlice } from "@reduxjs/toolkit";
import { getAllUsers } from "config/APIs/users";
// import {
// getAllUsersAdmin,
// getRanksUserAdmin,
// getRolesUserAdmin,
// } from "config/axios";
// import { getAll, getDepartments } from "config/axios/controls/accessmanagement";
// import { findAll } from "config/axios/project/index";
// import { getAdmin } from "helpers/utils/common/localStorage";
// import * as CollegeAPI from "config/axios/users/colleges";

// import { getAttributes } from "config/axios/controls/attributes";

const initialState = {
  users: [],

  // colleges: [],
  // roles: [],
  // ranks: [],
  // designations: [],
  // projects: [],
  // allProjects: [],
  // mentors: [],
  // departments: [],
  // status: [],
  // attributeTypes: [],
  // attributes: [],
  // crew: [],
  // cgc: [],
};

export const dropdown = createSlice({
  name: "dropdown",
  initialState,
  reducers: {
    updateUsers: (state, action) => {
      state.users = action.payload.users;
    },
    // updateMentors: (state, action) => {
    //   state.mentors = action.payload.users;
    // },
    // updateDepartments: (state, action) => {
    //   state.departments = action.payload.departments;
    // },
    // updateCollege: (state, action) => {
    //   state.colleges = action.payload.colleges;
    // },
    // updateRoles: (state, action) => {
    //   state.roles = action.payload.roles;
    // },
    // updateRanks: (state, action) => {
    //   state.ranks = action.payload.ranks;
    // },
    // updateStatus: (state, action) => {
    //   state.status = action.payload.status;
    // },
    // updateAttributesType: (state, action) => {
    //   state.attributeTypes = action.payload.type;
    // },
    // updateDesignation: (state, action) => {
    //   state.designations = action.payload.designations;
    // },
    // onDeleteDesignation: (state, action) => {
    //   let d = action.payload.designation;
    //   let temp = state.designations?.filter((des) => des?.id !== d?.id);
    //   state.designations = temp;
    // },
    // updateProject: (state, action) => {
    //   state.projects = action.payload.projects;
    // },
    // updateAllProject: (state, action) => {
    //   state.allProjects = action.payload.allProjects;
    // },
    // updateReduxDropdownList: (state, action) => {
    //   let field = action.payload.field;
    //   state[field] = action.payload.list;
    // },
  },
});

export const {
  updateUsers,
  // updateCollege,
  // updateRanks,
  // updateRoles,
  // updateStatus,
  // updateDesignation,
  // updateProject,
  // updateAllProject,
  // updateMentors,
  // updateDepartments,
  // updateAttributesType,
  // updateReduxDropdownList,
  // onDeleteDesignation,
} = dropdown.actions;
export default dropdown.reducer;

export function fetchAllUsers() {
  return async (dispatch) => {
    try {
      const response = await getAllUsers({ is_active: true });

      if (response.status === 200) {
        let data = response.data.data.response;

        // Dispatching user data to redux-store
        dispatch(
          updateUsers({
            users: data,
          })
        );
      }
    } catch (err) {
      console.log("user fetching error", err);
    }
  };
}

// export function fetchAllMentors() {
//   return async (dispatch) => {
//     try {
//       const response = await getAllUsersAdmin({
//         is_active: true,
//         is_mentor: true,
//       });

//       if (response.status === 200) {
//         let data = response.data.data.response;

//         // Dispatching user data to redux-store
//         dispatch(
//           updateMentors({
//             users: data,
//           })
//         );
//       }
//     } catch (err) {
//       console.log("mentor fetching error", err);
//     }
//   };
// }

// export function fetchAllDepartments() {
//   return async (dispatch) => {
//     try {
//       const response = await getDepartments();

//       if (response.status === 200) {
//         let data = response.data.data.data;

//         // Dispatching user data to redux-store
//         dispatch(
//           updateDepartments({
//             departments: data,
//           })
//         );
//       }
//     } catch (err) {
//       console.log("department fetching error", err);
//     }
//   };
// }

// export function fetchAllCollege(type = "colleges") {
//   return async (dispatch) => {
//     try {
//       const response = await CollegeAPI.findAll(type);
//       if (response.status === 200) {
//         let data = response.data.data.response;

//         dispatch(
//           updateReduxDropdownList({
//             list: data,
//             field: type,
//           })
//         );
//       }
//     } catch (err) {
//       console.log("college fetching error", err);
//     }
//   };
// }

// export function fetchAllRanks() {
//   return async (dispatch) => {
//     try {
//       const response = await getRanksUserAdmin();
//       if (response.status === 200) {
//         const { ranks } = response.data.data;

//         // Dispatching ranks data to redux-store
//         dispatch(
//           updateRanks({
//             ranks: ranks,
//           })
//         );
//       }
//     } catch (err) {
//       console.log("Ranks fetching error", err);
//     }
//   };
// }

// export function fetchAllStatus() {
//   return async (dispatch) => {
//     try {
//       const response = await getAll("status");
//       if (response.status === 200) {
//         const data = response.data.data;

//         dispatch(
//           updateStatus({
//             status: data?.list,
//           })
//         );
//       }
//     } catch (err) {
//       console.log("Status fetching error", err);
//     }
//   };
// }

// export function fetchAllRoles() {
//   return async (dispatch) => {
//     try {
//       const response = await getRolesUserAdmin();
//       if (response.status === 200) {
//         const { roles } = response.data.data;

//         // Dispatching roles data to redux-store
//         dispatch(
//           updateRoles({
//             roles: roles,
//           })
//         );
//       }
//     } catch (err) {
//       console.log("Roles fetching error", err);
//     }
//   };
// }

// export function fetchAllAttributesType(type = "attributeTypes") {
//   return async (dispatch) => {
//     try {
//       const response = await getAttributes(type);
//       if (response.status === 200) {
//         const data = response.data.data;

//         dispatch(
//           updateReduxDropdownList({
//             list: data,
//             field: type,
//           })
//         );
//       }
//     } catch (err) {
//       console.log("AttributesType fetching error", err);
//     }
//   };
// }

// export function fetchAllDesignations() {
//   return async (dispatch) => {
//     try {
//       const response = await getAll("designations");
//       if (response.status === 200) {
//         let data = response.data.designations;

//         // Dispatching designation data to redux-store
//         dispatch(
//           updateDesignation({
//             designations: data,
//           })
//         );
//       }
//     } catch (err) {
//       console.log("Designation fetching error", err);
//     }
//   };
// }

// export function fetchAllProjects() {
//   return async (dispatch) => {
//     try {
//       const admin = getAdmin();

//       let q = { is_active: true, status: "Live" };

//       q["selfEntities"] = true;

//       const response = await findAll(q);
//       if (response.status === 200) {
//         let data = response.data.data.response || [];

//         // Dispatching Projects data to redux-store
//         dispatch(
//           updateProject({
//             projects: data,
//           })
//         );
//       }
//     } catch (err) {
//       console.log("Projects fetching error", err);
//     }
//   };
// }

// const findPass = (tags, pass) => {
//   if (tags?.includes(pass)) {
//     return tags;
//   }
// };

// export function fetchAllActiveProjects() {
//   return async (dispatch) => {
//     try {
//       const admin = getAdmin();
//       const { tags } = admin;

//       let temp = [];
//       // switch (tags) {
//       //   case findPass(tags, "pmo"):
//       //     temp.push(
//       //       BasicProjectTypes.find((e) => e.value == PROJECT_TYPE_WORK_STUDY)
//       //     );

//       //     break;
//       //   case findPass(tags, "vmo"):
//       //     temp.push(
//       //       BasicProjectTypes.find((e) => e.value == PROJECT_TYPE_INITIATIVES)
//       //     );

//       //     break;
//       //   case findPass(tags, "cpo"):
//       //     temp.push(
//       //       BasicProjectTypes.find(
//       //         (e) => e.value == PROJECT_TYPE_CAARYA_INTERNAL
//       //       )
//       //     );

//       //     break;
//       //   default:
//       //     temp = BasicProjectTypes;
//       // }
//       let query = {
//         // is_active: true,
//         // status: "Live",
//         // types: JSON.stringify(temp?.map((i) => i?.value)),
//       };
//       let p = [];
//       await findAll(query)
//         .then((res) => {
//           let data =
//             res.data.data?.response?.filter((a) =>
//               PROJECT_CATEGORY?.map((b) => b?.name)?.includes(a?.category)
//             ) || [];
//           p = p.concat(data);
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//       delete query?.types;
//       // query["type"] = PROJECT_TYPE_PERSONAL;
//       // query["selfEntities"] = true;

//       // await findAll(query)
//       //   .then((res) => {
//       //     let data = res.data.data?.response || [];
//       //     p = p.concat(data);
//       //   })
//       //   .catch((error) => {
//       //     console.log(error);
//       //   });

//       dispatch(
//         updateAllProject({
//           allProjects: p,
//         })
//       );

//       // Dispatching Projects data to redux-store
//     } catch (err) {
//       console.log("Projects fetching error", err);
//     }
//   };
// }
