import React from "react";
import { Redirect } from "react-router-dom";
import Character from "pages/Character/IndividualCharacter";
import Campaign from "pages/Campaign";
import IndividualCampaign from "pages/Campaign/IndividualCampaign";
import Controls from "pages/Controls";
import ControlList from "pages/Controls/ControlList";
import ContentIdea from "pages/ContentIdea";
import IndividualCreative from "components/Creatives/IndividualCreative";
import Media from "pages/Media";
import { checkAppAccess } from "helpers/utils/accessCheck";
import Characters from "pages/Character";
import CreateIdea from "pages/CreateIdea";
import Wishlist from "pages/Wishlist";
import StrategyHub from "pages/StrategyHub";
import { CAMPAIGNS, IDEA_BUILDER, STRATEGY_HUB } from "helpers/constants/tabs";
// Authenticated Paths

// Array of routes only a logged in user can access
const privateRoutes = [
  // {
  //   name: STRATEGY_HUB,
  //   description: "",
  //   tab: STRATEGY_HUB,
  //   path: "/strategyHub",
  //   component: StrategyHub,
  // },
  {
    name: STRATEGY_HUB,
    description: "",
    tab: STRATEGY_HUB,
    path: "/campaign/:id",
    component: StrategyHub,
  },
  {
    name: CAMPAIGNS,
    description: "",
    tab: CAMPAIGNS,
    path: "/campaign",
    component: Campaign,
  },
  {
    name: STRATEGY_HUB,
    description: "",
    tab: STRATEGY_HUB,
    path: "/character",
    component: Characters,
  },
  {
    name: IDEA_BUILDER,
    description: "",
    tab: IDEA_BUILDER,
    path: `/contentIdeas/:type/:id`,
    component: CreateIdea,
  },
  {
    name: IDEA_BUILDER,
    description: "",
    tab: IDEA_BUILDER,
    path: "/contentIdea",
    component: ContentIdea,
  },

  {
    name: IDEA_BUILDER,
    description: "",
    tab: IDEA_BUILDER,
    path: "/ideas/:id",
    component: IndividualCreative,
  },
  {
    name: STRATEGY_HUB,
    description: "",
    tab: STRATEGY_HUB,
    path: "/character/:id",
    component: Character,
  },
  {
    name: STRATEGY_HUB,
    description: "",
    tab: STRATEGY_HUB,
    path: "/control/:type",
    component: ControlList,
  },
  {
    name: STRATEGY_HUB,
    description: "",
    tab: STRATEGY_HUB,
    path: "/control",
    component: Controls,
  },
  {
    name: "",
    description: "",
    tab: "",
    path: "/media",
    component: Media,
  },
  {
    name: "",
    description: "",
    tab: "",
    path: "/wishlist",
    component: Wishlist,
  },
  {
    path: "/",
    exact: true,
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          <Redirect to="/contentIdea" />
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <Redirect to="/auth/signIn" />
      ),
  },
];

export { privateRoutes };
