import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import * as MarketingAPI from "config/APIs/marketing";

import { XIcon } from "@heroicons/react/solid";
import { ChevronRightIcon } from "@heroicons/react/solid";
//Date
import DateInput from "./DateInput";

import ContentOfferingCard from "./ContentOfferingCard/index";
import moment from "moment";
import { Drawer } from "@mui/material";
import { fetchAllOfferings } from "redux/marketing";
import { showToast } from "redux/toaster";
import { fetchAllUsers } from "redux/dropdown";
import EmptyState from "components/Comman/EmptyState";
import ChronosButton from "components/Comman/Buttons";
import DropdownInput from "components/Comman/Inputs/DropdownInput";

/**
 * Calendar Add Content Offer Component
 * @param { Function } onCreate -API Call to fetch offering
 * @param { Function } closeModal -Function to setNewModalState
 * @param { object }  campaign -details of the respective campaign
 * @param { object }  offeringdata  -Object containing the details of the time slot which is clicked
 * @returns
 */

const ContentOfferingCreateEdit = ({
  onCreate,
  closeModal,
  campaign,
  offeringdata,
  isOpen,
}) => {
  const [showStep, setShowStep] = useState(1);

  const dispatch = useDispatch();
  const [offering, setOffering] = useState([]);
  const [allOffering, setAllOffering] = useState([]);

  const offerings = useSelector((state) => state.marketing.contentOffering);

  const users = useSelector((state) => state.dropdown.users);

  const [addoffering, setAddOffering] = useState([]);
  const [deselect, setDeselect] = useState(false);

  useEffect(() => {
    if (offerings.length === 0) {
      dispatch(fetchAllOfferings());
    }
  }, []);
  useEffect(() => {
    setOffering(offerings);
    setAllOffering(offerings);
  }, [offerings]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchAllUsers());
    }
  }, []);

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleDelete = (val) => {
    const temp = addoffering.filter((item) => item.id != val.id);
    setAddOffering(temp);
  };

  useEffect(() => {
    if (deselect === true) {
      setDeselect(false);
    }
  }, [deselect]);

  const DateAdd = (val, content) => {
    const res = addoffering.map((item) => {
      if (JSON.stringify(item) === JSON.stringify(content)) {
        return { ...item, release_date: new Date(val) };
      } else {
        return { ...item };
      }
    });

    setAddOffering(res);
  };

  const UserAdd = (val, content) => {
    const id = users.find((item) => item.first_name === val).id;
    const res = addoffering.map((item) => {
      if (JSON.stringify(item) === JSON.stringify(content)) {
        return { ...item, publisherId: id, first_name: val };
      } else {
        return { ...item };
      }
    });
    setAddOffering(res);
  };

  const onAddToCalendar = async (arr) => {
    var res = { name: campaign?.name };
    console.log("array", arr);

    const contentOfferingsDetails = arr.map((item) => {
      return {
        contentOfferingId: item.id,
        release_date: moment(item.release_date).format("yyyy-MM-DD"),
        publisherId: item.publisherId,
      };
    });
    res = { ...res, contentOfferingsDetails: contentOfferingsDetails };
    try {
      const response = await MarketingAPI.updateEntity(
        "campaign",
        campaign?.id,
        res
      );
      if (response.data) {
        closeModal();
        onCreate();
        setOffering(offerings);
        setAllOffering(offerings);
      }
    } catch (err) {
      console.log("Marketing update err:", err);
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
  };

  const getOfferings = () => {
    let temp = [];
    temp = offering?.filter((a) => a?.marketingCampigns?.id == campaign?.id);
    return temp;
  };

  return (
    <>
      <Drawer
        anchor={window.innerWidth < 1024 ? "bottom" : "right"}
        open={isOpen}
        onClose={() => {
          closeModal();
        }}
        PaperProps={{
          style: {
            maxHeight: "100vh",
            width: window.innerWidth < 1024 ? "100vw" : "50vw",
          },
        }}
      >
        <div className="p-7.5 w-full md:max-w-2xl mx-auto text-sm sm:h-full z-100 contentcreate-div1">
          <div className="flex flex-row items-start md:items-center justify-between mb-6">
            <div className="flex flex-row items-center justify-start space-x-4">
              <h5
                className="font-lato font-bold text-xl text-primary-gray-600 flex flex-col items-start"
                id="exampleModalLiveLabel"
              >
                Add Content Idea
              </h5>
              <p className="font-lato font-normal text-primary-gray-600 text-lg">
                {showStep}/2
              </p>
            </div>
            <div className="flex flex-row items-center">
              {addoffering.length > 0 && (
                <h5
                  className="text-primary-indigo-300 cursor-pointer text-sm font-lato font-normal ml-6 mr-14 "
                  onClick={() => {
                    setAddOffering([]);
                    setDeselect(true);
                  }}
                >
                  Deselect All
                </h5>
              )}
              <button
                aria-label="Close"
                type="button"
                onClick={() => closeModal()}
              >
                <XIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="flex flex-row text-primary-gray-350 mt-1 mb-6">
            <ChevronRightIcon
              className="flex-shrink-0 h-4 w-4"
              aria-hidden="true"
            />
            <p className="font-normal font-lato text-sm leading-4 text-justify">
              {<>{addoffering.map((content) => content.name).join(", ")}</>}
            </p>
          </div>

          <div className="mt-3.5 flex space-y-5 flex-col w-full relative contentcreate-div2 ">
            {showStep === 1 && (
              <>
                <div className="sm:grid md:grid-cols-2 sm:gap-8 space-y-3 sm:space-y-0  min-h-45h max-h-50vh overflow-y-auto pl-2 pr-2 ">
                  {getOfferings()?.length > 0 ? (
                    getOfferings()?.map((content, index) => {
                      return (
                        <ContentOfferingCard
                          content={content}
                          onAdd={(val) => {
                            setAddOffering([
                              ...addoffering,
                              {
                                ...val,
                                release_date:
                                  offeringdata?.action === "click"
                                    ? new Date(offeringdata.date)
                                    : val.release_date === null
                                    ? new Date()
                                    : val.release_date,
                              },
                            ]);
                          }}
                          key={index}
                          deselect={deselect}
                          onDelete={(val) => {
                            handleDelete(val);
                          }}
                        />
                      );
                    })
                  ) : (
                    <div className="col-span-2">
                      <EmptyState text={`${campaign?.name} has no ideas!`} />
                    </div>
                  )}
                </div>
                {getOfferings()?.length > 0 && (
                  <div className=" absolute z-20 bottom-5 right-10 ">
                    <ChronosButton
                      text="Set Dates +"
                      primary
                      onClick={() => {
                        setShowStep(2);
                        setAddOffering(
                          addoffering.map((item) => {
                            return { ...item, publisherId: null };
                          })
                        );
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {showStep === 2 && (
              <>
                <div className="w-full space-y-2.5 ">
                  <div className="min-h-50vh max-h-60vh overflow-y-auto  pr-2">
                    <div className="space-y-5">
                      {addoffering.map((content, index) => {
                        return (
                          <div className="flex flex-col space-y-2.5">
                            <div className="grid grid-cols-12 gap-4">
                              <h5 className="text-sm text-left leading-4 font-bold col-span-11">
                                {content?.name}
                              </h5>

                              <div className="flex flex-row justify-end">
                                <img
                                  src="/assets/images/icons/binRed.svg"
                                  className="cursor-pointer w-4 h-4"
                                  onClick={() => {
                                    handleDelete(content);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="w-full grid sm:grid-cols-2 gap-5">
                              <DateInput
                                date={content.release_date}
                                onChange={(date) => {
                                  DateAdd(date, content);
                                }}
                              />

                              <DropdownInput
                                label="Choose a publisher..."
                                value={content.first_name}
                                setValue={(val) => {
                                  UserAdd(val, content);
                                }}
                                list={
                                  users.map((i) => ({
                                    label: i?.first_name,
                                    value: i?.first_name,
                                  })) || []
                                }
                                srOnly
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="absolute z-20 bottom-5 right-10">
                    <ChronosButton
                      text="Add to Calendar +"
                      primary
                      onClick={() => {
                        onAddToCalendar(addoffering);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ContentOfferingCreateEdit;
