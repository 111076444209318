import { Target } from "@phosphor-icons/react";
import React from "react";

function BusinessGoalCard({ item, onClick, selected }) {
  return (
    <div
      onClick={onClick}
      className={`border ${
        selected ? "border-primary-aqua-light" : "border-primary-gray-100"
      } rounded-lg bg-white p-4 flex flex-col w-full space-y-4`}
    >
      <div className="bg-primary-gray-50 border border-primary-gray-100 max-w-max rounded px-2 py-0.5 flex flex-row items-center space-x-0.5">
        <Target size={12} className="text-secondary-red-550" />
        <p className="text-primary-gray-800 font-lato text-3xs font-semibold">
          Goal
        </p>
      </div>
      <p className="text-primary-gray-800 font-lato text-sm">{item?.title}</p>
    </div>
  );
}

export default BusinessGoalCard;
