import React from "react";

function Wrapper({ heading, description, children }) {
  return (
    <div className="px-0 lg:px-6 space-y-2.5">
      <div className="flex flex-col items-start space-y-0.5 text-primary-gray-800 font-poppins text-lg font-medium">
        <p>{heading}</p>
        {description && <p className="font-normal text-sm">{description}</p>}
      </div>
      {children}
    </div>
  );
}

export default Wrapper;
