import DesktopWrapper from "components/Layout/DesktopWrapper";
import TabBar from "components/Layout/TabBar";
import TopBar from "components/Layout/TopBar";
import { LongTabs } from "components/Comman/Tabs";
import { setSelectedTab } from "redux/navigation";
import { useDispatch, useSelector } from "react-redux";

function AppWrapper({ children }) {
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state.sideNav?.tabs);
  const selectedTab = useSelector((state) => state.sideNav?.selectedTab);
  return (
    <div className="h-screen w-screen overflow-hidden">
      <TopBar />
      <div className="max-h-85vh mt-11 pb-3 overflow-y-auto lg:hidden">
        {tabs?.length > 0 && (
          <LongTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={(val) => {
              dispatch(setSelectedTab({ selectedTab: val }));
            }}
          />
        )}{" "}
        {children}
      </div>
      <DesktopWrapper>
        <div className="mt-0 py-0 max-h-[85vh] overflow-y-auto">{children}</div>
      </DesktopWrapper>
      <TabBar />
    </div>
  );
}

export default AppWrapper;
