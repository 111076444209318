import { useState, useRef, useEffect } from "react";
import SearchBox from "components/Comman/Inputs/SearchBox";
import Medias from "components/Media";
import Tab from "components/Comman/Lists/TabList";
import Tabs from "components/Comman/Tabs";
const tabs = [
  { label: "Draft", value: "draft" },
  { label: "Review", value: "inReview" },
  { label: "Publish", value: "published" },
];

function Media() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [selectedTab, setSelectedTab] = useState("draft");
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });
  const tabsRef = useRef([]);
  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);
  return (
    <>
      <div className="px-7.5 pt-2.5">
        <SearchBox
          placeholder="Find your media!"
          search={search}
          setSearch={setSearch}
        />
      </div>

      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      <span
        className="absolute rounded-sm block h-[3px] bg-primary-gray-1000 transition-all duration-300"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
      <div className="max-h-70vh overflow-x-hidden block lg:hidden mt-5 space-y-4">
        <Medias search={search} tab={selectedTab} />
      </div>
    </>
  );
}

export default Media;
