import BusinessGoalCard from "components/Comman/Cards/Objectives";
import EmptyState from "components/Comman/EmptyState";

function BusinessGoals({ goals }) {
  return (
    <div className="bg-primary-gray-50 rounded-lg px-4 py-2 max-w-full overflow-x-auto">
      <div className="grid lg:grid-cols-2 gap-4">
        {goals?.length > 0 ? (
          goals?.map((t) => {
            return <BusinessGoalCard item={t} />;
          })
        ) : (
          <div className="col-span-2 flex flex-row items-center justify-center">
            <EmptyState
              noImage
              text="The business goals you add will be shown here"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default BusinessGoals;
