import liIcon from "assets/imgimports/icons/li_icon.svg";
import { useEffect, useState } from "react";
//ICONS
import {
  XIcon,
  ChevronRightIcon,
  PlusIcon,
  MinusIcon,
} from "@heroicons/react/solid";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { fetchWithType } from "redux/marketing";
import { Disclosure, Transition } from "@headlessui/react";
import Error from "./Error";
import { showToast } from "redux/toaster";
import WideModalsWrapper from "components/Comman/Modals/ModalsWrapper/WideModalWrapper";
import ChronosButton from "components/Comman/Buttons";
import SearchFilter from "components/Comman/Inputs/SearchFilter";
import AddTagModal from "components/Comman/Modals/MultipleAddModals/AddTags";

function AddCharacterConflict({ closeModal, onAdd }) {
  const dispatch = useDispatch();
  const characterList = useSelector((state) => state.marketing.character);
  const conflictList = useSelector((state) => state.marketing.conflict);
  const tags = useSelector((state) => state.marketing.tags);
  const [showError, setShowError] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState([]);
  const [selectedConflict, setSelectedConflict] = useState([]);
  const [tagModal, setTagModal] = useState(false);
  const [selectedTags, setselectedTags] = useState([]);
  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  useEffect(() => {
    if (characterList.length === 0) {
      dispatch(fetchWithType("character"));
    }
    if (conflictList.length === 0) {
      dispatch(fetchWithType("conflict"));
    }
  }, []);

  const addOrRemove = (list, item) => {
    console.log(list, item);
    let tempSelected = list || [];

    if (tempSelected.find((e) => e?.id == item?.id)) {
      tempSelected = tempSelected.filter((e) => e?.id !== item?.id);
    } else {
      tempSelected.push(item);
    }

    return tempSelected;
  };

  return (
    <>
      <WideModalsWrapper
        isOpen={showError}
        closeModal={() => setShowError(false)}
        component={<Error />}
      />

      <WideModalsWrapper
        isOpen={tagModal}
        closeModal={(val) => {
          setTagModal(val);
        }}
        height="100vh"
        borderRadius="0px"
        component={
          <AddTagModal
            onAdd={(val) => {
              let temp = tags.filter((item) => val.includes(item?.id));
              setselectedTags({ ...selectedTags, tags: temp });
            }}
            selected={selectedTags?.tags || []}
            isOpen={tagModal}
            closeModal={() => {
              setTagModal(false);
            }}
          />
        }
      />
      <div className="p-7.5 w-full md:max-w-2xl mx-auto text-sm sm:h-full z-100 contentcreate-div1 relative">
        <div className="flex flex-row items-start md:items-center justify-between mb-2">
          <div className="flex flex-row items-center justify-start space-x-4">
            <h5
              className="inter font-bold text-sm text-primary-gray-600 flex flex-col items-start"
              id="exampleModalLiveLabel"
            >
              Add Characters & Conflicts
            </h5>
          </div>
          <div className="flex flex-row items-center">
            {selectedCharacter?.length > 0 && (
              <h5
                className="text-primary-indigo-650 cursor-pointer text-sm inter font-normal ml-6 mr-14 "
                onClick={() => {
                  setSelectedCharacter([]);
                  setSelectedConflict([]);
                }}
              >
                Deselect All
              </h5>
            )}
            <button
              aria-label="Close"
              type="button"
              onClick={() => closeModal()}
            >
              <XIcon className="h-4 w-4 text-primary-indigo-650" />
            </button>
          </div>
        </div>
        <div className="flex flex-row text-primary-gray-350 mb-6 mt-1 -ml-1">
          <ChevronRightIcon
            className="flex-shrink-0 h-4 w-4"
            aria-hidden="true"
          />
          <p className="font-normal inter text-sm leading-4 text-justify">
            Selected <b>{selectedCharacter?.length || 0}</b> characters and{" "}
            <b>{selectedConflict?.length || 0}</b> conflicts
          </p>
        </div>
        <div className="mb-6">
          <div className="flex flex-row items-end space-x-5">
            <h1 className="inter font-normal text-sm text-primary-gray-600">
              Tags:{" "}
            </h1>
            <p
              onClick={() => {
                setTagModal(true);
              }}
              className="text-primary-indigo-650 cursor-pointer underline text-2xs inter font-normal transform transition ease-in-out duration-150 hover:scale-105"
            >
              Filter by tags
            </p>
          </div>
          <div className="flex flex-row flex-wrap items-center -ml-1.5">
            {selectedTags?.tags?.map((item) => {
              return (
                <div className="m-1.5 components-controls-tags-single-div">
                  <div className="px-1.5 rounded-full bg-white">
                    <div className="flex flex-row items-center justify-between v2-view-para space-x-2 font-normal">
                      <p className="text-2xs">{item?.name} </p>
                      <XIcon
                        onClick={() => {
                          let temp = selectedTags?.tags || [];
                          temp = temp.filter((i) => i?.id !== item?.id);
                          setselectedTags({
                            ...selectedTags,
                            tags: temp,
                          });
                        }}
                        className="w-3 h-3 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mb-2.5 w-full">
          <SearchFilter
            rounded
            searchList={characterList.concat(conflictList)}
            setSearchList={() => {}}
            originalList={[]}
          />
        </div>

        <div className="sm:grid grid-cols-2 gap-7 max-h-70vh overflow-y-scroll space-y-7 sm:space-y-0">
          {characterList?.length > 0 &&
            characterList?.map((char) => {
              let hasTag = true;
              if (selectedTags?.tags?.length > 0) {
                let charTags = char?.tags?.map((i) => i?.id);
                let t = selectedTags?.tags;
                hasTag = false;
                t?.map((i) => {
                  if (charTags?.includes(i?.id)) {
                    hasTag = true;
                  }
                });
              }
              if (hasTag == false) {
                return;
              } else
                return (
                  <Disclosure className="character-card-container bg-white w-full">
                    {({ open }) => (
                      <div>
                        <div
                          className={`upper-character-card floating-primary-cta ${
                            selectedCharacter?.find((c) => c?.id == char?.id)
                              ? "bg-white border-active border-2"
                              : "bg-primary-gray-600"
                          }  flex items-center px-4.5 py-2.5 justify-between`}
                        >
                          <h1
                            className={`upper-character-card-h1 ${
                              selectedCharacter?.find((c) => c?.id == char?.id)
                                ? "text-active"
                                : "text-white"
                            }  inter font-bold text-sm cursor-pointer w-11/12`}
                          >
                            {char.character}
                          </h1>
                          <Disclosure.Button>
                            <button
                              onClick={() => {
                                let temp = addOrRemove(
                                  [...selectedCharacter],
                                  char
                                );
                                setSelectedCharacter(temp);
                              }}
                              className={`cursor-pointer h-4 w-4 ${
                                selectedCharacter?.find(
                                  (c) => c?.id == char?.id
                                )
                                  ? "text-active"
                                  : "text-white"
                              }`}
                            >
                              {selectedCharacter?.find(
                                (c) => c?.id == char?.id
                              ) ? (
                                <MinusIcon />
                              ) : (
                                <PlusIcon />
                              )}
                            </button>
                          </Disclosure.Button>
                        </div>

                        <Transition
                          show={open}
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel static>
                            <div className="px-4.5 py-2.5">
                              <ul>
                                {char?.marketingConflict?.map((item) => {
                                  return (
                                    <div className=" flex justify-between mb-2.5 character-card-left">
                                      <div className="flex">
                                        <div className="character-card-li-icon flex items-center mr-1.5">
                                          <img
                                            className="character-card-li-icon-img"
                                            src={liIcon}
                                            alt="li-icon"
                                          ></img>
                                        </div>
                                        <li
                                          className={`${
                                            selectedConflict?.find(
                                              (i) => i?.id == item?.id
                                            )
                                              ? "text-active"
                                              : "text-primary-gray-600"
                                          } character-card-li inter text-sm font-normal `}
                                        >
                                          {item.name}
                                        </li>
                                      </div>

                                      {selectedCharacter?.find(
                                        (c) => c?.id == char?.id
                                      ) && (
                                        <>
                                          {" "}
                                          {selectedConflict?.find(
                                            (i) => i?.id == item?.id
                                          ) ? (
                                            <XIcon
                                              onClick={() => {
                                                let temp = addOrRemove(
                                                  [...selectedConflict],
                                                  item
                                                );
                                                setSelectedConflict(temp);
                                              }}
                                              className="cursor-pointer h-4 w-4 text-alert"
                                              alt="add-icon"
                                            ></XIcon>
                                          ) : (
                                            <PlusIcon
                                              onClick={() => {
                                                let isCharPresent =
                                                  selectedCharacter?.find(
                                                    (c) =>
                                                      c?.id ==
                                                      item?.marketingCharacterId
                                                  );
                                                if (!isCharPresent) {
                                                  setShowError(true);
                                                  showErrorNotification(
                                                    "Select the character before choosing it’s conflicts."
                                                  );
                                                  return;
                                                }

                                                let temp = addOrRemove(
                                                  [...selectedConflict],
                                                  item
                                                );
                                                setSelectedConflict(temp);
                                              }}
                                              className="cursor-pointer h-4 w-4 font-chronos-grey-350"
                                              alt="add-icon"
                                            ></PlusIcon>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </ul>
                            </div>
                          </Disclosure.Panel>
                        </Transition>
                      </div>
                    )}
                  </Disclosure>
                );
            })}
        </div>
        <div className="fixed bottom-16 left-1/2 transform p-1 rounded-md shadow bg-white -translate-x-1/2">
          <ChronosButton
            text="Add +"
            primary
            onClick={() => {
              onAdd(selectedCharacter, selectedConflict);
              closeModal();
            }}
          />
        </div>
      </div>
    </>
  );
}

export default AddCharacterConflict;
