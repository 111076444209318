import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  tabs: [],
  selectedTab: "yourIdeas",
};

// Open toggle for Mobile Side Navigation
export const navigation = createSlice({
  name: "sideNav",
  initialState,
  reducers: {
    change: (state) => {
      state.isOpen = !state.isOpen;
    },
    updateTabs: (state, action) => {
      state.tabs = action.payload.tabs;
      state.selectedTab = action.payload.selectedTab;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload.selectedTab;
    },
    resetTabs: (state, action) => {
      state.selectedTab = null;
      state.tabs = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { change, updateTabs, setSelectedTab, resetTabs } =
  navigation.actions;

export default navigation.reducer;
