import { instance, getHeader } from "config";


/**
 * To get all Media Published
 */
export const getAllMedia = (type, query = {}) =>
  instance.get(
    `/admin/news/all?status=${type}`,
    getHeader()
  );
