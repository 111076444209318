import React, { useEffect, useState } from "react";

// APIs
import * as MarketingAPI from "config/APIs/marketing";

import CampaignList from "components/Comman/Lists/CampaignList";
import EmptyState from "components/Comman/EmptyState";
import { useDispatch, useSelector } from "react-redux";
import { updateList } from "redux/campaign";
import CampaignListLoader from "components/Comman/Loaders/CampaignListLoader";

function Campaigns({ search }) {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign.list);
  const [lists, setLists] = useState([]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchList();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const fetchList = async () => {
    if (campaign?.length > 0) {
      setLists(campaign);
      return;
    }
    setFetching(true);

    try {
      const response = await MarketingAPI.getAllEntity("campaign");
      if (response?.data) {
        const data = response.data.data;
        setLists(data);
        dispatch(updateList({ list: data }));
        setFetching(false);
      }
    } catch (err) {
      setFetching(false);
      console.log("Fetch marking get all error", err);
    }
  };

  return (
    <>
      <div className="w-full relative">
        <div className="px-7.5 relative">
          <div
            // id="scrollingDiv"
            className={`max-h-75vh overflow-y-auto pb-28`}
          >
            {!fetching ? (
              lists.length > 0 ? (
                <CampaignList
                  onUpdate={(id, val, field) => {
                    let temp = lists;
                    let t = [];
                    temp.map((item) => {
                      if (item.id == id) {
                        if (field) {
                          item[field] = val;
                        } else {
                          item.students = [...val];
                        }
                        t.push(item);
                      } else {
                        t.push(item);
                      }
                    });
                    setLists(t);
                  }}
                  afterDelete={(id) => {
                    let temp = lists;
                    let t = [];
                    temp.map((item) => {
                      if (item.id == id) {
                      } else {
                        t.push(item);
                      }
                    });
                    setLists(t);
                  }}
                  //campaigns={lists}
                  campaigns={
                    search?.isSearch
                      ? lists.filter(
                          (e) =>
                            e?.code
                              ?.toLowerCase()
                              .includes(search?.searchText?.toLowerCase()) ||
                            e?.name
                              ?.toLowerCase()
                              .includes(search?.searchText?.toLowerCase()) ||
                            e?.description
                              ?.toLowerCase()
                              .includes(search?.searchText?.toLowerCase())
                        )
                      : lists
                  }
                />
              ) : (
                <EmptyState text="No Campaign found!" />
              )
            ) : (
              <CampaignListLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Campaigns;
