import axios from "axios";
import config from "config/APIEndpoints";

const BASE_API_URL = config.getEndpoint();
const API_URL = BASE_API_URL + "/api/v1";

export { BASE_API_URL };

// Util
export const getHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
};

export const getMultipartHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "multipart/form-data",
    },
  };
};

export const instance = axios.create({
  baseURL: API_URL,
});

export const refreshToken = () =>
  instance.get("admin/dashboard/token", getHeader());
