export default function CampaignListLoader() {
  return (
    <div className="space-y-5">
      {Array.from(Array(10).keys()).map((key) => (
        <div className="p-5 flex-col w-full bg-white" key={key}>
          <div className="flex flex-row items-start justify-between relative">
            <div className="flex flex-col items-start w-11/12 space-y-2">
              <div className="h-1 w-12 bg-aqua-800 animate-pulse rounded-xl" />
              <div className="h-1 w-28 bg-black animate-pulse rounded-xl" />
              <div className="mt-2.5 border-t border-primary-gray-250 pt-3 flex flex-col space-y-1 w-full">
                <div className="h-1 w-28 bg-primary-gray-250 animate-pulse rounded-xl" />
                <div className="h-1 w-28 bg-primary-gray-250 animate-pulse rounded-xl" />
                <div className="h-1 w-28 bg-primary-gray-250 animate-pulse rounded-xl" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
