import { useState } from "react";
import SearchBox from "components/Comman/Inputs/SearchBox";
import Control from "components/Controls";

function Controls() {
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  return (
    <>
      <div className="px-7.5 pt-2.5">
        <SearchBox
          placeholder="Find ..."
          search={search}
          setSearch={setSearch}
        />
      </div>

      <div className="max-h-70vh overflow-x-hidden block mt-5">
        <Control search={search} />
      </div>
    </>
  );
}

export default Controls;
