import VerticalCard from "components/Comman/Cards/ControlCard/VerticalCard";
import TagElement from "components/Comman/Cards/Tags";
import EmptyState from "components/Comman/EmptyState";
import { addOrRemove } from "helpers/utils/common/array";
import React from "react";

function List({ list, data, setData, field }) {
  return (
    <div className="px-4 py-1 max-w-full overflow-x-auto">
      <div className="lg:grid grid-cols-5 lg:gap-4 flex flex-row items-stretch space-x-4 lg:space-x-0">
        {list?.length > 0 ? (
          list?.map((t) => {
            return field?.includes("tag") ? (
              <TagElement
                tag={t}
                selected={
                  field && data && data[field]?.find((i) => i?.id == t?.id)
                }
                onClick={() => {
                  let temp = addOrRemove(data && field ? data[field] : [], t);
                  setData({ ...data, [field]: temp });
                }}
              />
            ) : (
              <VerticalCard
                item={t}
                selected={
                  field && data && data[field]?.find((i) => i?.id == t?.id)
                }
                onClick={() => {
                  let temp = addOrRemove(data && field ? data[field] : [], t);
                  setData({ ...data, [field]: temp });
                }}
              />
            );
          })
        ) : (
          <EmptyState
            noImage
            text="The topic cluster you add will be shown here"
          />
        )}
      </div>
    </div>
  );
}

export default List;
