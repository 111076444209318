import { instance, getHeader } from "config";

/**
 * To create Quarterly Goal, Objectives, Key Results
 * @param type --> quarterlyGoals, keyResults, objectives
 */
export const createQuarterlyGoal = (type, body) =>
  instance.post(`/admin/quarterlyGoals/${type}`, body, getHeader());

/**
 * To delete Quarterly Goal, Objectives, Key Results
 * @param type --> quarterlyGoals, keyResults, objectives
 * @param id --> id of quarterlyGoals, keyResults, objectives
 */
export const deleteQuarterlyGoal = (type, id) =>
  instance.delete(`/admin/quarterlyGoals/${type}/${id}`, getHeader());

/**
 * To get all Quarterly Goal, Objectives, Key Results
 * @param type --> quarterlyGoals, keyResults, objectives
 */
export const getquarterlyGoal = (type, query) =>
  instance.get(
    `/admin/quarterlyGoals/${type}/all${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

/**
 * Gives a Quarterly Goal, Objectives, Key Results
 * @param type --> quarterlyGoals, keyResults, objectives
 * @param id --> id of quarterlyGoals or attributeTypes
 */
export const getQuarterlyGoalById = (type, id) =>
  instance.get(`/admin/quarterlyGoals/${type}/${id}`, getHeader());

/**
 * To update Quarterly Goal, Objectives, Key Results
 * @param type --> quarterlyGoals, keyResults, objectives
 * @param id --> id of quarterlyGoals or attributeTypes
 */
export const updateQuarterlyGoal = (type, id, body) =>
  instance.patch(`/admin/quarterlyGoals/${type}/${id}`, body, getHeader());
