//ICONS
import { XIcon, ChevronRightIcon, PlusIcon } from "@heroicons/react/solid";

function Error({ closeModal }) {
  return (
    <>
      <div
        style={{ maxWidth: "230px" }}
        className="bg-white-br-9 mx-auto g-white-br-9-shadow-laptop p-4.5 relative"
      >
        <XIcon
          className="h-4 w-4 text-alert absolute top-2 right-2 cursor-pointer"
          onClick={() => {
            closeModal();
          }}
        />
        <div className="flex flex-row items-end space-x-2">
          <img
            src="/assets/images/icons/error.svg"
            alt=""
            className="h-6 w-6"
          />
          <p className="text-sm text-alert inter font-normal">Error</p>
        </div>
        <h1 className="text-left mt-2 inter font-normal text-sm text-primary-gray-600">
          Select the <b>character</b> before choosing it’s <b>conflicts</b>.
        </h1>
      </div>
    </>
  );
}

export default Error;
