import { ArrowRightIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import React from "react";

function Card({ item, type }) {
  const history = useHistory();

  return (
    <div
      onClick={() => history.push(`/control/${type}/${item?.id}`)}
      className="bg-white  shadow-container rounded-20px p-2.5 space-y-2"
    >
      <div className="flex flex-row items-end justify-between">
        <div className="flex flex-row items-center justify-between w-full">
          <div className="space-y-1 w-10/12 pr-4">
            <h1 className="font-lato break-words font-bold text-sm text-primary-gray-600 leading-4">
              {item?.name || item?.type}
            </h1>
            {item?.platform && (
              <p className="font-lato font-normal text-2xs text-primary-gray-600 leading-3">
                {item?.platform}
              </p>
            )}
            {item?.description && (
              <p className="font-lato break-words font-normal text-2xs text-primary-gray-350 leading-3">
                {item?.description}
              </p>
            )}
          </div>
          <img
            className={`h-14 w-14 object-cover ${
              item?.image?.url && "rounded"
            }`}
            src={
              (item?.image && item.image.url) ||
              "/assets/images/icons/contentPillar.svg"
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

function ListView({ list, type }) {
  return (
    <>
      <div className="w-full relative px-7.5">
        <div className="max-h-70vh overflow-y-auto pb-5 space-y-5">
          {list?.length > 0 &&
            list?.map((item) => <Card item={item} type={type} />)}
        </div>
      </div>
    </>
  );
}

export default ListView;
