import Card from "./Card";
import { useHistory } from "react-router-dom";
function CampaignList({ campaigns, onUpdate, afterDelete }) {
  const navigate = useHistory();
  return (
    <>
      <div className="space-y-5">
        {campaigns?.map((campaign, index) => {
          return (
            <Card
              onClick={() =>
                navigate.push(`campaign/${campaign.id}`)
              }
              campaign={campaign}
              afterDelete={(val) => afterDelete(val)}
              onUpdate={(id, val, field) => {
                onUpdate(id, val, field);
              }}
              index={index}
            />
          );
        })}
      </div>
    </>
  );
}

export default CampaignList;
