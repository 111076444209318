import { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as MarketingAPI from "config/APIs/marketing";

import moment from "moment";
import ContentOfferingCreateEdit from "./ContentOfferingCreateEdit";
import CalendarView from "./CalendarView";
import CardViews from "./CardViews";
import ChronosButton from "components/Comman/Buttons";
import EmptyState from "components/Comman/EmptyState";

const headings = [
  { label: "Name", field: "name" },
  { label: "Date", field: "date" },
  { label: "Publisher", field: "publisher" },
  { label: "Description", field: "description" },
  { label: "Tags", field: "tags" },
];

/**
 * Content Offer Table under Campaign
 * @param { object }  campaign -details of the respective campaign
 * @returns
 *
 *
 */

function Calendar({ campaign }) {
  const params = useParams();
  const { id } = params;
  const [offering, setOffering] = useState([]);
  const [searchedOffering, setSearchedOffering] = useState([]);
  const [calendarEvent, setCalendarEvent] = useState();
  const [fetching, setFetching] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [view, setView] = useState("Cards");

  const fetchOffering = async () => {
    setFetching(true);
    try {
      const response = await MarketingAPI.getAllEntity("contentOffering", {
        marketing_campaign_id: id,
      });
      if (response.data) {
        const fetchedoffering = response.data.data;

        let temp = [];
        fetchedoffering?.map((item) => {
          let obj = {
            title: item?.name,
            start: new Date(item?.release_date),
            end: new Date(item?.release_date),
            id: item?.id,
            publisher_id: item?.publisher?.id,
            publisher_first_name: item?.publisher?.first_name,
          };
          temp.push(obj);
        });
        setCalendarEvent(temp);
        setOffering(fetchedoffering);
        setSearchedOffering(fetchedoffering);
      }
    } catch (err) {
      console.log("Fetch offering error", err);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchOffering();
  }, [id]);

  return (
    <>
      <ContentOfferingCreateEdit
        isOpen={newModal}
        closeModal={() => setNewModal(false)}
        onCreate={() => fetchOffering()}
        campaign={campaign}
      />

      <div className="px-2.5">
        <div className="md:py-5 md:mb-10 space-y-4">
          <div className="flex flex-row items-center justify-between w-full">
            {/* <div className="hidden md:block">
              <ViewSwitch
                dark
                view2="Cards"
                view1="Table"
                viewType={view}
                setViewType={(val) => {
                  setView(val);
                }}
              />
            </div> */}

            <ChronosButton
              primary
              text="Add Content Idea +"
              onClick={() => {
                setNewModal(true);
              }}
            />
          </div>{" "}
          {/* <SearchFilter
            dark
            searchList={searchedOffering}
            setSearchList={setSearchedOffering}
            originalList={offering}
          /> */}
        </div>

        {view === "Cards" && (
          <>
            <div className="hidden lg:block">
              <CalendarView
                campaign={campaign}
                offerings={calendarEvent}
                view={view}
                setView={() => {
                  setView("Table");
                }}
                fetchOffering={fetchOffering}
              />
            </div>

            <div className="lg:hidden block">
              <CardViews
                campaign={campaign}
                offerings={searchedOffering}
                fetching={fetching}
              />
            </div>
          </>
        )}

        {view === "Table" && (
          <>
            {searchedOffering?.length > 0 ? (
              <div className="tableCard mb-12">
                <table className="w-full">
                  <thead className="text-indigo-600  font-lato font-bold text-sm leading-4">
                    <tr className="mx-0">
                      {headings.map((e, ind) => {
                        return (
                          <th
                            className={`py-2.5 ${
                              ind == 0
                                ? "pl-6 pr-1.5"
                                : ind == headings?.length - 1
                                ? "pl-1.5 pr-6"
                                : "px-1.5"
                            }`}
                          >
                            {e.label}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {searchedOffering?.map((item, ind) => (
                      <tr
                        className={`hover:bg-primary-indigo-100 text-sm py-2 font-normal text-primary-gray-600 font-lato transform transition ease-in-out duration-150 hover:scale-101`}
                      >
                        <td className="pl-6 pr-1.5 font-bold text-primary-indigo-300 py-2.5 contenttable-td ">
                          {item?.name}
                        </td>
                        <td className="padding-6 py-2.5 contenttable-td">
                          {moment(item?.release_date).format("ll")}
                        </td>
                        <td className="padding-6 py-2.5 contenttable-td">
                          {item?.publisher?.first_name}
                        </td>
                        <td className="padding-6 py-2.5 contenttable-td">
                          {item?.description}
                        </td>
                        <td className="py-2.5 pr-1.5 md:pr-6 pl-1.5">
                          {item?.tags?.length > 0 ? (
                            <>
                              {item?.tags?.map((e, ind) => {
                                return (
                                  <>
                                    {ind !== 0 ? ", " : ""}
                                    {e?.name}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            "No Tags added!"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyState text="No Content Offer found!" />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Calendar;
