import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  draft:[],
  inReview:[],
  published:[]
};

// Storing campaign details
export const media = createSlice({
  name: "media",
  initialState,
  reducers: {
    updateList: (state, action) => {
      let tab=action.payload?.tab;
      state[tab]=action.payload.list
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = media.actions;

export default media.reducer;
