import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { XIcon } from "@heroicons/react/solid";
//APIS
import * as MarketingAPI from "config/APIs/marketing";

import moment from "moment";

import DateInput from "./DateInput";
import { Drawer } from "@mui/material";
import { fetchAllUsers } from "redux/dropdown";
import DropdownInput from "components/Comman/Inputs/DropdownInput";
import ChronosButton from "components/Comman/Buttons";
import { showToast } from "redux/toaster";

/**
 * Calendar Content Offer Edit Component
 * @param { object } content /* info about the offering
 * @param { Function } closeModal /* Function to setEditState
 * @param { Function } fetchOffering /*API function call to get all the offerings
 * @returns
 *
 *
 */

function ContentOfferingEdit({ isOpen, content, closeModal, fetchOffering }) {
  const users = useSelector((state) => state.dropdown.users);
  const [offering, setOffering] = useState(content);
  const dispatch = useDispatch();
  console.log(offering);

  const UserAdd = (val) => {
    const id = users.find((item) => item.first_name === val).id;
    setOffering({ ...offering, publisher_id: id, publisher_first_name: val });
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const onEdit = async (item) => {
    const contentOfferingsDetails = {
      contentOfferingId: item?.id,
      release_date: moment(item?.start).format("yyyy-MM-DD"),
      publisherId: item?.publisher_id,
    };

    try {
      const response = await MarketingAPI.updateEntity(
        "contentOffering",
        item?.id,
        contentOfferingsDetails
      );
      if (response.data) {
        closeModal();
        fetchOffering();
      }
    } catch (err) {
      console.log("Marketing update err:", err);
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    if (users.length === 0) {
      dispatch(fetchAllUsers());
    }
  }, []);

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={() => {
        closeModal();
      }}
      PaperProps={{
        style: {
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100vw" : "50vw",
        },
      }}
    >
      <div className=" p-7.5  w-full lg:max-w-lg mx-auto modals-component bg-white">
        <div className="flex justify-between">
          <h5 className="font-lato font-bold text-xl text-primary-gray-600 flex flex-row items-center mr-6">
            Edit Content Idea
          </h5>

          <button
            aria-label="Close"
            type="button"
            onClick={() => {
              closeModal();
            }}
          >
            <XIcon className="h-5 w-5" />
          </button>
        </div>
        <h1 className="text-sm leading-4 mt-3 font-normal text-primary-gray-600 pb-6 text-left m-0">
          {offering?.title}
        </h1>
        <div className="grid gap-y-4 sm:max-w-2xs">
          <DateInput
            date={content?.start}
            onChange={(date) => {
              setOffering({
                ...offering,
                start: date,
                end: date,
              });
            }}
          />
          <DropdownInput
            label="Choose a publisher..."
            value={offering.publisher_first_name}
            setValue={(val) => {
              UserAdd(val);
            }}
            list={
              users?.map((i) => ({
                label: i?.first_name,
                value: i?.first_name,
              })) || []
            }
            srOnly
          />
        </div>
        <div className="flex flex-row items-center justify-end">
          <ChronosButton
            text="Update"
            onClick={() => {
              onEdit(offering);
            }}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default ContentOfferingEdit;
