// TODO : Update documentation
import { useState } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";

/**
 * Simple Input field for changing values in an object
 * @param { String } label
 * @param { String } field - Key in the details oject
 * @param { Object } details - Object containing the details of the entity
 * @param { Function } setDetails - Function to changed the value of the given field in details object
 * @param { Boolean } srOnly - To display the label or not
 * @returns
 */
function SimpleResizeInput({
  label,
  field,
  details,
  setDetails,
  srOnly = false,
  isDisabled,
  showError,
  required = false,
  errorMessage = "Please fill in the details",
  customError,
  bigText,
  placeholder,
  type,
}) {
  const [error, setError] = useState();
  return (
    <>
      <div className="flex flex-col items-start w-full">
        <label htmlFor={label} className={srOnly ? "sr-only" : "input-label"}>
          {required ? label + "*" : label}
        </label>
        <TextareaAutosize
          id={label}
          minRows={1}
          placeholder={placeholder ? placeholder : `Enter ${label}`}
          className="text-2xl leading-7 font-medium w-full text-primary-gray-600 border-b border-color-gray-350 components-mobilecreate-textareaautosize"
          value={details[field] || ""}
          onChange={(e) => {
            setDetails({ ...details, [field]: e.target.value });
          }}
        />

        {((showError &&
          (!details[field] || (details[field] && details[field] == ""))) ||
          error) && (
          <p className="text-alert text-2xs flex flex-row items-center mt-1 ml-0.5">
            {error || errorMessage}
          </p>
        )}
        {showError && customError && errorMessage && (
          <p className="text-alert text-2xs flex flex-row items-center mt-1 ml-0.5">
            {errorMessage}
          </p>
        )}
      </div>
    </>
  );
}

export default SimpleResizeInput;
