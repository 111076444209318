import React from "react";
import { CheckIcon } from "@heroicons/react/outline";

function EditDetails({ onUpdate, setEditable, details, setDetails }) {
  return (
    <div className="w-full">
      <div className="pt-3 pb-4 px-7 space-y-4 relative">
        <CheckIcon
          className="h-4 w-4 text-caarya-red-lighter absolute top-0 right-7"
          onClick={() => {
            setEditable(false);
            onUpdate();
          }}
        />
        <div className="w-full space-y-1">
          <h1 className="font-normal text-black font-lato text-xs mb-2.5">
            Code
          </h1>
          <input
            value={details?.code}
            onChange={(e) => {
              setDetails({ ...details, code: e.target.value });
            }}
            placeholder="Add a code"
            className="text-primary-gray-450 focus:border-0 focus:ring-0 focus:outline-none bg-transparent  font-lato font-normal text-2xs"
          ></input>
        </div>

        <div className="w-full space-y-1">
          <h1 className="font-normal text-black font-lato text-xs mb-2.5">
            Character
          </h1>
          <input
            value={details?.character}
            onChange={(e) => {
              setDetails({
                ...details,
                character: e.target.value,
              });
            }}
            placeholder="Add a character"
            className="text-primary-gray-450 focus:border-0 focus:ring-0 focus:outline-none bg-transparent  font-lato font-normal text-2xs"
          ></input>
        </div>
        <div className="w-full space-y-1">
          <h1 className="font-normal text-black font-lato text-xs mb-2.5">
            Description
          </h1>
          <input
            value={details?.description}
            onChange={(e) => {
              setDetails({
                ...details,
                description: e.target.value,
              });
            }}
            placeholder="Add a description"
            className="text-primary-gray-450 focus:border-0 focus:ring-0 focus:outline-none bg-transparent  font-lato font-normal text-2xs"
          ></input>
        </div>
      </div>
    </div>
  );
}

export default EditDetails;
