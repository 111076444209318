import { addOrRemove } from "helpers/utils/common/array";
import React from "react";

function CharacterConflictCard({
  item,
  onSelect,
  selected,
  selectAble,
  conflicts,
}) {
  return (
    <div className="p-4 bg-white shadow-md rounded-lg border-primary-100 grid md:grid-cols-2 lg:divide-x">
      <div className="lg:py-6 lg:pr-4 text-primary-aqua-medium text-lg font-lato font-semibold">
        {item?.character}
      </div>
      <div className="flex flex-col items-start space-y-2 px-2 py-4">
        <p className="text-primary-gray-800 font-lato font-semibold text-sm">
          Conflicts
        </p>
        {conflicts
          ? conflicts
              ?.filter((k) => k?.marketingCharacterId == item?.id)
              ?.map((conflict) => {
                return (
                  <div className="flex flex-row items-center space-x-1 p-2">
                    <p className="text-primary-gray-800 font-lato text-sm">
                      {conflict?.name}
                    </p>
                  </div>
                );
              })
          : item?.marketingConflict?.length > 0
          ? item?.marketingConflict?.map((conflict) => {
              return (
                <div className="w-full flex flex-row items-center justify-between space-x-1 p-2">
                  <p className="text-primary-gray-800 font-lato text-sm">
                    {conflict?.name}
                  </p>
                  {selectAble && (
                    <input
                      type="checkbox"
                      checked={selected?.find((i) => i?.id == conflict?.id)}
                      onChange={() => {
                        let temp = addOrRemove(selected, conflict);
                        onSelect(temp);
                      }}
                    />
                  )}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default CharacterConflictCard;
