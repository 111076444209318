import { instance } from "config";
import { getHeader } from "config";

/**
 * To create tag or tag type
 * @param type --> tag, tagType
 */
export const createTag = (type, body) =>
  instance.post(`/admin/node/${type}/create`, body, getHeader());

/**
 * To delete tag or tag type
 * @param type --> tag, tagType
 * @param id --> id of tag or tagType
 */
export const deleteTag = (type, id) =>
  instance.delete(`/admin/node/${type}/${id}`, getHeader());

/**
 * To get all tag and tag types
 * @param type --> tag, tagType
 */
export const getTags = (type, typeId) =>
  instance.get(
    `/admin/node/${type}/all${typeId ? `?typeId=${typeId}` : ""}`,
    getHeader()
  );

/**
 * Gives a tag or tag type
 * @param type --> tag, tagType
 * @param id --> id of tag or tagType
 */
export const getTagById = (type, id) =>
  instance.get(`/admin/node/${type}/${id}`, getHeader());

/**
 * To update tag or tag type
 * @param type --> tag, tagType
 * @param id --> id of tag or tagType
 */
export const updateTag = (type, id, body) =>
  instance.patch(`/admin/node/${type}/update/${id}`, body, getHeader());
