import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import {
  ArrowNarrowLeftIcon,
  CheckIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/solid";

//APIS
import * as MarketingAPI from "config/APIs/marketing";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllOfferings,
  fetchAllTags,
  fetchWithType,
} from "redux/marketing";
import { showToast } from "redux/toaster";
import AddMarketingAttributesModal from "components/Comman/Modals/MultipleAddModals/AddMarketingAttributes";
import SimpleTextArea from "components/Comman/Inputs/SimpleTextArea";

function ContentIdeaOverlay({ isOpen, closeModal, id }) {
  const dispatch = useDispatch();
  const contentType = useSelector((state) => state.marketing.contentTypes);
  const contentPlatform = useSelector(
    (state) => state.marketing.contentPlatform
  );
  const clusters = useSelector((state) => state.marketing.topicCluster);
  const pillars = useSelector((state) => state.marketing.contentPillars);
  const buckets = useSelector((state) => state.marketing.contentBucket);
  const tags = useSelector((state) => state.marketing.tags);
  const [details, setDetails] = useState({});
  const [edit, setEdit] = useState({});
  const [tagModal, setTagModal] = useState(false);
  const [marketingModal, setMarketingModal] = useState({
    isOpen: false,
    type: "",
  });
  const [updating, setUpdating] = React.useState(false);

  useEffect(() => {
    if (contentType.length === 0) {
      dispatch(fetchWithType("contentTypes"));
    }
    if (contentPlatform.length === 0) {
      dispatch(fetchWithType("contentPlatform"));
    }
    if (clusters.length === 0) {
      dispatch(fetchWithType("topicCluster"));
    }
    if (pillars.length === 0) {
      dispatch(fetchWithType("contentPillars"));
    }
    if (buckets.length === 0) {
      dispatch(fetchWithType("contentBucket"));
    }
    if (tags?.length === 0) {
      dispatch(fetchAllTags());
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      getData();
    }
  }, [id]);

  const getData = async () => {
    try {
      let res = await MarketingAPI.getOneEntity("contentOffering", id);
      setDetails(res?.data?.data);
    } catch (e) {
      console.log("Get one idea", e);
    }
  };

  const onUpdate = async () => {
    setUpdating(true);
    let {
      topicClusters,
      marketingContentBuckets,
      marketingContentTypes,
      contentPlatforms,
      contentPillars,
      tags,
      ...values
    } = details;
    let body = values;
    if (topicClusters) body["topicClusters"] = topicClusters?.map((i) => i?.id);
    if (marketingContentBuckets)
      body["contentBuckets"] = marketingContentBuckets?.map((i) => i?.id);
    if (contentPillars) body["pillars"] = contentPillars?.map((i) => i?.id);
    if (tags) body["tags"] = tags?.map((i) => i?.id);
    if (marketingContentTypes)
      body["contentTypes"] = marketingContentTypes?.map((i) => i?.id);
    if (contentPlatforms)
      body["platforms"] = contentPlatforms?.map((i) => i?.id);
    // if (
    //   charactersConflicts?.length > 0 &&
    //   charactersConflicts[0]?.characterId !== null
    // ) {
    //   body["characters"] = charactersConflicts?.map((i) => i?.characterId);
    //   let temp = [];
    //   charactersConflicts?.map((i) => {
    //     if (i?.conflicts?.length > 0) {
    //       temp = temp.concat(i?.conflicts);
    //     }
    //   });
    //   body["conflicts"] = temp?.map((i) => i?.id);
    // }

    try {
      const response = await MarketingAPI.updateEntity(
        "contentOffering",
        body?.id,
        body
      );
      if (response.data) {
        // onUpdate();
      }
      dispatch(fetchAllOfferings());
    } catch (err) {
      console.log("Marketing update err:", err);
      dispatch(
        showToast({
          message: err.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <AddMarketingAttributesModal
        label={
          marketingModal?.type === "topicCluster"
            ? "Topic Clusters"
            : marketingModal?.type === "contentBucket"
            ? "Content Buckets"
            : marketingModal?.type === "contentPillars"
            ? "Content Pillars"
            : marketingModal?.type === "contentPlatform"
            ? "Content Platform"
            : "Content Type"
        }
        onAdd={(val) => {
          let temp;
          console.log(val, marketingModal);
          switch (marketingModal?.type) {
            case "contentPlatform":
              temp = contentPlatform.filter((item) => val.includes(item?.id));
              console.log(temp, contentPlatform);
              setDetails({ ...details, contentPlatforms: temp });
              break;
            case "contentTypes":
              temp = contentType.filter((item) => val.includes(item?.id));
              console.log(temp, contentType);
              setDetails({ ...details, marketingContentTypes: temp });
              break;
            case "topicCluster":
              temp = clusters.filter((item) => val.includes(item?.id));
              console.log(temp, clusters);
              setDetails({ ...details, topicClusters: temp });
              break;
            case "contentBucket":
              temp = buckets.filter((item) => val.includes(item?.id));
              console.log(temp, buckets);
              setDetails({ ...details, marketingContentBuckets: temp });
              break;
            case "contentPillars":
              temp = pillars.filter((item) => val.includes(item?.id));
              console.log(temp, pillars);
              setDetails({ ...details, contentPillars: temp });
              break;
            default:
          }
        }}
        length={
          marketingModal?.type === "contentPlatform" ||
          marketingModal?.type === "contentTypes"
            ? 1
            : null
        }
        type={marketingModal?.type}
        selected={marketingModal?.selected || []}
        isOpen={marketingModal?.isOpen}
        closeModal={() => {
          setMarketingModal({ ...marketingModal, isOpen: false });
        }}
      />

      <Drawer
        anchor={window.innerWidth < 1024 ? "bottom" : "right"}
        open={isOpen}
        onClose={() => {
          closeModal();
        }}
        PaperProps={{
          style: { maxHeight: "100vh" },
        }}
      >
        <div className="bg-white primary-shadow p-2.5 h-screen max-w-lg w-screen lg:pt-8">
          <div className="flex flex-row items-center space-x-2.5 px-5 text-primary-gray-600">
            <ArrowNarrowLeftIcon
              onClick={() => {
                closeModal();
              }}
              className="w-4 h-4 lg:w-5 lg:h-5"
            />
            <p className="font-lato text-base lg:text-xl font-bold w-11/12">
              {details?.name}
            </p>
          </div>
          <div className="px-5 mt-5 lg:mt-10 space-y-5 lg:space-y-8 max-h-90vh overflow-y-auto text-primary-gray-600 font-lato">
            <div className="flex flex-row items-center space-x-3">
              <div className="input-label w-1/3 mb-0 text-sm">Description</div>
              <div className="w-2/3 relative flex flex-row items-start justify-between space-x-2.5">
                <div className="w-11/12">
                  {edit?.description ? (
                    <SimpleTextArea
                      srOnly
                      label="Description"
                      field="description"
                      rows={3}
                      details={details}
                      setDetails={setDetails}
                    />
                  ) : details?.description ? (
                    <p className="text-primary-gray-600 font-lato font-normal text-sm lg:text-base">
                      {details?.description}
                    </p>
                  ) : (
                    <p className="text-primary-gray-250 font-lato font-normal text-sm lg:text-base">
                      Add a description
                    </p>
                  )}
                </div>
                <div className="absolute top-1 right-0 cursor-pointer">
                  {edit?.description ? (
                    <CheckIcon
                      onClick={() => {
                        setEdit({ ...edit, description: false });
                        onUpdate();
                      }}
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setEdit({ ...edit, description: true });
                      }}
                      src="/assets/images/icons/editRed.svg"
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-3">
              <div className="input-label w-1/3 mb-0 text-sm">Resolution</div>
              <div className="w-2/3 relative flex flex-row items-start justify-between space-x-2.5">
                <div className="w-11/12">
                  {edit?.resolution ? (
                    <SimpleTextArea
                      srOnly
                      label="resolution"
                      placeholder="Enter the business resolution for the content idea here..."
                      field="resolution"
                      rows={3}
                      details={details}
                      setDetails={setDetails}
                    />
                  ) : details?.resolution ? (
                    <p className="text-primary-gray-600 font-lato font-normal text-sm lg:text-base">
                      {details?.resolution}
                    </p>
                  ) : (
                    <p className="text-primary-gray-250 font-lato font-normal text-sm lg:text-base">
                      Add a resolution
                    </p>
                  )}
                </div>
                <div className="absolute top-1 right-0 cursor-pointer">
                  {edit?.resolution ? (
                    <CheckIcon
                      onClick={() => {
                        setEdit({ ...edit, resolution: false });
                        onUpdate();
                      }}
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setEdit({ ...edit, resolution: true });
                      }}
                      src="/assets/images/icons/editRed.svg"
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-3">
              <div className="input-label w-1/3 mb-0 text-sm">Content Type</div>
              <div className="w-2/3 relative flex flex-row items-start justify-between space-x-2.5">
                <>
                  <div className="flex flex-row flex-wrap items-center -ml-1 w-11/12">
                    {details?.marketingContentTypes?.length > 0 ? (
                      <>
                        {details?.marketingContentTypes?.map((item) => {
                          return (
                            <div className="py-1 px-1.5 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                              {/* <img
                                src={item?.image?.url || DefaultAvatar}
                                alt="tags"
                                className="w-3 h-3 object-cover rounded-full"
                              /> */}
                              <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                                {item?.type}
                              </p>
                              {edit?.contenttype == true && (
                                <XIcon
                                  onClick={() => {
                                    let temp =
                                      details?.marketingContentTypes || [];
                                    temp = temp.filter(
                                      (i) => i?.id !== item?.id
                                    );
                                    setDetails({
                                      ...details,
                                      marketingContentTypes: temp,
                                    });
                                  }}
                                  className="w-3 h-3 lg:w-4 lg:h-4 text-primary-indigo-300 cursor-pointer"
                                />
                              )}
                            </div>
                          );
                        })}
                        {edit?.contenttype == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected: details?.marketingContentTypes || [],
                                type: "contentTypes",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </>
                    ) : (
                      <div className="py-1 px-1.5 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                        <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                          Add Type
                        </p>
                        {edit?.contenttype == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected: details?.marketingContentTypes || [],
                                type: "contentTypes",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </>
                <div className="absolute top-1 right-0 cursor-pointer">
                  {edit?.contenttype ? (
                    <CheckIcon
                      onClick={() => {
                        setEdit({ ...edit, contenttype: false });
                        onUpdate();
                      }}
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setEdit({ ...edit, contenttype: true });
                      }}
                      src="/assets/images/icons/editRed.svg"
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-3">
              <div className="input-label w-1/3 mb-0 text-sm">
                Content Platforms
              </div>
              <div className="w-2/3 relative flex flex-row items-start justify-between space-x-2.5">
                <>
                  <div className="flex flex-row flex-wrap items-center -ml-1 w-11/12">
                    {details?.contentPlatforms?.length > 0 ? (
                      <>
                        {details?.contentPlatforms?.map((item) => {
                          return (
                            <div className="py-1 px-1.5 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                              {/* <img
                                src={item?.image?.url || DefaultAvatar}
                                alt="tags"
                                className="w-3 h-3 object-cover rounded-full"
                              /> */}
                              <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                                {item?.name}
                              </p>
                              {edit?.contentPlatform == true && (
                                <XIcon
                                  onClick={() => {
                                    let temp = details?.contentPlatforms || [];
                                    temp = temp.filter(
                                      (i) => i?.id !== item?.id
                                    );
                                    setDetails({
                                      ...details,
                                      contentPlatforms: temp,
                                    });
                                  }}
                                  className="w-3 h-3 lg:w-4 lg:h-4 text-primary-indigo-300 cursor-pointer"
                                />
                              )}
                            </div>
                          );
                        })}
                        {edit?.contentPlatform == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected: details?.contentPlatforms || [],
                                type: "contentPlatform",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </>
                    ) : (
                      <div className="py-1 px-1.5 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                        <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                          Add Platform
                        </p>
                        {edit?.contentPlatform == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected: details?.contentPlatforms || [],
                                type: "contentPlatform",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </>
                <div className="absolute top-1 right-0 cursor-pointer">
                  {edit?.contentPlatform ? (
                    <CheckIcon
                      onClick={() => {
                        setEdit({ ...edit, contentPlatform: false });
                        onUpdate();
                      }}
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setEdit({ ...edit, contentPlatform: true });
                      }}
                      src="/assets/images/icons/editRed.svg"
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-3">
              <div className="input-label w-1/3 mb-0 text-sm">
                Content Pillars
              </div>
              <div className="w-2/3 relative flex flex-row items-start justify-between space-x-2.5">
                <>
                  <div className="flex flex-row flex-wrap items-center -m-1 w-11/12">
                    {details?.contentPillars?.length > 0 ? (
                      <>
                        {details?.contentPillars?.map((item) => {
                          return (
                            <div className="py-1 px-1.5 m-1 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                              {/* <img
                                src={item?.image?.url || DefaultAvatar}
                                alt="tags"
                                className="w-3 h-3 object-cover rounded-full"
                              /> */}
                              <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                                {item?.name}
                              </p>
                              {edit?.contentPillars == true && (
                                <XIcon
                                  onClick={() => {
                                    let temp = details?.contentPillars || [];
                                    temp = temp.filter(
                                      (i) => i?.id !== item?.id
                                    );
                                    setDetails({
                                      ...details,
                                      contentPillars: temp,
                                    });
                                  }}
                                  className="w-3 h-3 lg:w-4 lg:h-4 text-primary-indigo-300 cursor-pointer"
                                />
                              )}
                            </div>
                          );
                        })}
                        {edit?.contentPillars == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected: details?.contentPillars || [],
                                type: "contentPillars",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </>
                    ) : (
                      <div className="py-1 px-1.5 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                        <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                          Add Content Pillars
                        </p>
                        {edit?.contentPillars == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected: details?.contentPillars || [],
                                type: "contentPillars",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </>
                <div className="absolute top-1 right-0 cursor-pointer">
                  {edit?.contentPillars ? (
                    <CheckIcon
                      onClick={() => {
                        setEdit({ ...edit, contentPillars: false });
                        onUpdate();
                      }}
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setEdit({ ...edit, contentPillars: true });
                      }}
                      src="/assets/images/icons/editRed.svg"
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-3">
              <div className="input-label w-1/3 mb-0 text-sm">
                Content Buckets
              </div>
              <div className="w-2/3 relative flex flex-row items-start justify-between space-x-2.5">
                <>
                  <div className="flex flex-row flex-wrap items-center -m-1 w-11/12">
                    {details?.marketingContentBuckets?.length > 0 ? (
                      <>
                        {details?.marketingContentBuckets?.map((item) => {
                          return (
                            <div className="py-1 px-1.5 m-1 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                              {/* <img
                                src={item?.image?.url || DefaultAvatar}
                                alt="tags"
                                className="w-3 h-3 object-cover rounded-full"
                              /> */}
                              <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                                {item?.name}
                              </p>
                              {edit?.contentBucket == true && (
                                <XIcon
                                  onClick={() => {
                                    let temp =
                                      details?.marketingContentBuckets || [];
                                    temp = temp.filter(
                                      (i) => i?.id !== item?.id
                                    );
                                    setDetails({
                                      ...details,
                                      marketingContentBuckets: temp,
                                    });
                                  }}
                                  className="w-3 h-3 lg:w-4 lg:h-4 text-primary-indigo-300 cursor-pointer"
                                />
                              )}
                            </div>
                          );
                        })}
                        {edit?.contentBucket == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected:
                                  details?.marketingContentBuckets || [],
                                type: "contentBucket",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </>
                    ) : (
                      <div className="py-1 px-1.5 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                        <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                          Add Content Buckets
                        </p>
                        {edit?.contentBucket == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected:
                                  details?.marketingContentBuckets || [],
                                type: "contentBucket",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </>
                <div className="absolute top-1 right-0 cursor-pointer">
                  {edit?.contentBucket ? (
                    <CheckIcon
                      onClick={() => {
                        setEdit({ ...edit, contentBucket: false });
                        onUpdate();
                      }}
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setEdit({ ...edit, contentBucket: true });
                      }}
                      src="/assets/images/icons/editRed.svg"
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-3">
              <div className="input-label w-1/3 mb-0 text-sm">
                Topic Clusters
              </div>
              <div className="w-2/3 relative flex flex-row items-start justify-between space-x-2.5">
                <>
                  <div className="flex flex-row flex-wrap items-center -m-1 w-11/12">
                    {details?.topicClusters?.length > 0 ? (
                      <>
                        {details?.topicClusters?.map((item) => {
                          return (
                            <div className="py-1 px-1.5 m-1 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                              {/* <img
                                src={item?.image?.url || DefaultAvatar}
                                alt="tags"
                                className="w-3 h-3 object-cover rounded-full"
                              /> */}
                              <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                                {item?.name}
                              </p>
                              {edit?.topicCluster == true && (
                                <XIcon
                                  onClick={() => {
                                    let temp = details?.topicClusters || [];
                                    temp = temp.filter(
                                      (i) => i?.id !== item?.id
                                    );
                                    setDetails({
                                      ...details,
                                      topicClusters: temp,
                                    });
                                  }}
                                  className="w-3 h-3 lg:w-4 lg:h-4 text-primary-indigo-300 cursor-pointer"
                                />
                              )}
                            </div>
                          );
                        })}
                        {edit?.topicCluster == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected: details?.topicClusters || [],
                                type: "topicCluster",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </>
                    ) : (
                      <div className="py-1 px-1.5 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                        <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                          Add Topic Clusters
                        </p>
                        {edit?.topicCluster == true && (
                          <PlusIcon
                            onClick={() => {
                              setMarketingModal({
                                isOpen: true,
                                selected: details?.topicClusters || [],
                                type: "topicCluster",
                              });
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </>
                <div className="absolute top-1 right-0 cursor-pointer">
                  {edit?.topicCluster ? (
                    <CheckIcon
                      onClick={() => {
                        setEdit({ ...edit, topicCluster: false });
                        onUpdate();
                      }}
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setEdit({ ...edit, topicCluster: true });
                      }}
                      src="/assets/images/icons/editRed.svg"
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-3">
              <div className="input-label w-1/3 mb-0 text-sm">Tags</div>
              <div className="w-2/3 relative flex flex-row items-start justify-between space-x-2.5">
                <>
                  <div className="flex flex-row flex-wrap items-center -m-1 w-11/12">
                    {details?.tags?.length > 0 ? (
                      <>
                        {details?.tags?.map((item) => {
                          return (
                            <div className="py-1 px-1.5 m-1 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                              {/* <img
                                src={item?.image?.url || DefaultAvatar}
                                alt="tags"
                                className="w-3 h-3 object-cover rounded-full"
                              /> */}
                              <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                                {item?.name}
                              </p>
                              {edit?.tags == true && (
                                <XIcon
                                  onClick={() => {
                                    let temp = details?.tags || [];
                                    temp = temp.filter(
                                      (i) => i?.id !== item?.id
                                    );
                                    setDetails({
                                      ...details,
                                      tags: temp,
                                    });
                                  }}
                                  className="w-3 h-3 lg:w-4 lg:h-4 text-primary-indigo-300 cursor-pointer"
                                />
                              )}
                            </div>
                          );
                        })}
                        {edit?.tags == true && (
                          <PlusIcon
                            onClick={() => {
                              setTagModal(true);
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </>
                    ) : (
                      <div className="py-1 px-1.5 rounded-full bg-primary-indigo-100 flex flex-row items-center">
                        <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs lg:text-sm">
                          Add Tags
                        </p>
                        {edit?.tags == true && (
                          <PlusIcon
                            onClick={() => {
                              setTagModal(true);
                            }}
                            className="w-3 h-3 cursor-pointer text-primary-indigo-300"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </>
                <div className="absolute top-1 right-0 cursor-pointer">
                  {edit?.tags ? (
                    <CheckIcon
                      onClick={() => {
                        setEdit({ ...edit, tags: false });
                        onUpdate();
                      }}
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  ) : (
                    <img
                      onClick={() => {
                        setEdit({ ...edit, tags: true });
                      }}
                      src="/assets/images/icons/editRed.svg"
                      alt=""
                      className="w-3 lg:w-4 h-auto text-active"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default ContentIdeaOverlay;
