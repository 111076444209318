import ContentCard from "components/Comman/Cards/ControlCard";
import EmptyState from "components/Comman/EmptyState";

function ContentPlatform({ contentPlatforms }) {
  return (
    <div className="bg-primary-gray-50 rounded-lg px-4 py-2 max-w-full overflow-x-auto">
      <div className="lg:grid grid-cols-2 lg:gap-4 flex flex-row items-stretch space-x-4 lg:space-x-0">
        {contentPlatforms?.length > 0 ? (
          contentPlatforms?.map((t) => {
            return <ContentCard item={t} />;
          })
        ) : (
          <div className="col-span-2 flex flex-row items-center justify-center">
            <EmptyState
              noImage
              text="The content platform you add will be shown here"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ContentPlatform;
