import React from "react";

function Wrapper({ heading, description, children }) {
  return (
    <div className="px-0 lg:px-6 space-y-2.5">
      <div className="flex flex-row items-start space-x-4 text-primary-gray-800 font-poppins text-lg font-medium">
        <p>{heading}</p>
        <div className="rounded-full w-4 h-4 p-1 mt-1 text-xs flex flex-row items-center justify-center bg-primary-gray-100">
          ?
        </div>
      </div>
      {description && (
        <p className="text-primary-gray-800 font-lato text-sm">{description}</p>
      )}
      {children}
    </div>
  );
}

export default Wrapper;
