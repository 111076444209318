import React, { useState, useEffect } from "react";
import * as TaskAPI from "config/APIs/task/task";
import { UserIcon } from "@heroicons/react/solid";
import AddMembers from "components/Modals/AddMembers";
import { showToast } from "redux/toaster";
import { useDispatch } from "react-redux";
import { TaskStatus } from "helpers/task";
import { CheckIcon } from "@heroicons/react/outline";
import DateInput from "components/Comman/Inputs/DateInput";

function EditDetails({ onUpdate, setEditable, onDelete, details, setDetails }) {
  const dispatch = useDispatch();
  const [creating, setCreating] = useState(false);
  const [showEditModal, setShowEditModal] = useState({
    isOpen: false,
    type: "",
  });
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  const addMembers = async (id, profiles) => {
    setCreating(true);

    try {
      let body = {
        students: profiles.map((e) => e.id),
      };

      const response = await TaskAPI.updateTasks(id, body);

      onUpdate();
      showToast({ message: "Members updated successfully!" });
    } catch (err) {
      switch (err.response?.status) {
        case 401:
          showToast({ message: "Unauthorized!", type: "error" });
          break;
        default:
          showToast({
            message: "Something went wrong!",
            type: "error",
          });
      }
    }
    setCreating(false);
  };

  return (
    <div className="w-full">
      <AddMembers
        isOpen={showAddMembersModal}
        closeModal={() => setShowAddMembersModal(false)}
        id={details?.id}
        onUpdate={(id, profiles) => {
          addMembers(id, profiles);

          setShowAddMembersModal(false);
        }}
      />

      <div className="pt-3 pb-4 px-7 space-y-4 relative">
        <input
          value={details?.title}
          onChange={(e) => {
            setDetails({ ...details, title: e.target.value });
          }}
          className="text-black focus:border-0 w-full focus:ring-0 focus:outline-none bg-transparent font-medium font-lato text-xs"
        ></input>
        <CheckIcon
          className="h-4 w-4 text-caarya-red-lighter absolute top-0 right-7"
          onClick={() => {
            setEditable(false);
            onUpdate();
          }}
        />
        {details?.status && (
          <p
            onClick={() => {
              setShowEditModal({ isOpen: true, type: "Status" });
            }}
            className="px-3 py-1 max-w-max rounded-full font-lato font-normal text-2xs"
            style={{
              color: TaskStatus.find((e) => e.value == details?.status).color,
              backgroundColor: TaskStatus.find(
                (e) => e.value == details?.status
              ).lightColor,
            }}
          >
            {details?.status}
          </p>
        )}
        <div className="w-full">
          <h1 className="font-normal text-black font-lato text-xs mb-2.5">
            Due Date
          </h1>
          <DateInput
            date={details?.date}
            onChange={(value) =>
              setDetails({
                ...details,
                date: value,
              })
            }
          />
        </div>

        <div className="w-full space-y-1">
          <h1 className="font-normal text-black font-lato text-xs mb-2.5">
            Description
          </h1>

          <input
            value={details?.description}
            onChange={(e) => {
              setDetails({
                ...details,
                description: e.target.value,
              });
            }}
            placeholder="Add a description"
            className="text-primary-gray-450 focus:border-0 focus:ring-0 focus:outline-none bg-transparent  font-lato font-normal text-2xs"
          ></input>
        </div>

        <div className="w-full space-y-1">
          <h1 className="font-normal text-black font-lato text-xs mb-2.5">
            Type
          </h1>
          {details?.type ? (
            <p
              onClick={() => {
                setShowEditModal({
                  isOpen: true,
                  type: "Task Type",
                });
              }}
              className="px-3 py-1 max-w-max mt-2 rounded bg-slate-200 text-slate-800 font-lato font-normal text-2xs"
            >
              {details?.type?.name}
            </p>
          ) : (
            <p
              onClick={() => {
                setShowEditModal({
                  isOpen: true,
                  type: "Task Type",
                });
              }}
              className="text-primary-gray-450 font-lato font-normal text-2xs"
            >
              Add Task Type
            </p>
          )}
        </div>

        <div className="w-full space-y-1">
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 space-y-1">
              <h1 className="font-normal text-black font-lato text-xs mb-2.5">
                T-Shirt Size
              </h1>
              {details?.t_shirt_size ? (
                <p
                  onClick={() => {
                    setShowEditModal({
                      isOpen: true,
                      type: "T-Shirt Size",
                    });
                  }}
                  className="px-3 py-1 max-w-max mt-2 rounded bg-slate-200 text-slate-800 font-lato font-normal text-2xs"
                >
                  {details?.t_shirt_size}
                </p>
              ) : (
                <p
                  onClick={() => {
                    setShowEditModal({
                      isOpen: true,
                      type: "T-Shirt Size",
                    });
                  }}
                  className="text-primary-gray-450 font-lato font-normal text-2xs"
                >
                  Add T-Shirt Size
                </p>
              )}
            </div>
          </div>
        </div>

        {details?.project && (
          <div className="w-full space-y-1">
            <h1 className="font-normal text-black font-lato text-xs mb-2.5">
              Project
            </h1>
            <p className="px-3 py-1 max-w-max mt-2 rounded bg-slate-200 text-slate-800 font-lato font-normal text-2xs">
              {details?.project?.title}
            </p>
          </div>
        )}

        <div className="w-full">
          <h1 className="font-normal text-black font-lato text-xs mb-2.5">
            Members
          </h1>
          <div className="mt-2 grid grid-cols-2 gap-4">
            {details?.students?.length > 0 &&
              details?.students.map((person) => {
                return (
                  <div className="flex flex-row items-center" key={person?.id}>
                    {person?.image?.url ? (
                      <img
                        src={person?.image?.url}
                        className="rounded-full h-8 w-8 mr-2"
                        alt=""
                      />
                    ) : (
                      <UserIcon className="rounded-full h-8 w-8 mr-2 theme-gray-500" />
                    )}
                    <p className="text-gray-700 font-lato font-normal text-2xs">
                      {person?.first_name}
                    </p>
                  </div>
                );
              })}
          </div>
          {details?.students?.length === 0 && (
            <p
              onClick={() => {
                setShowAddMembersModal(true);
              }}
              className="mt-1 text-primary-gray-450 font-lato font-normal text-2xs"
            >
              Assign Users to Task
            </p>
          )}
        </div>

        {/* {taskPermission(details) && (
            <div className="w-full pt-3">
              <div
                onClick={() => setDeleteModal(true)}
                className="px-3 py-1 max-w-max rounded bg-red-100 text-red-700 cursor-pointer"
              >
                Delete
              </div>
            </div>
          )} */}
      </div>
    </div>
  );
}

export default EditDetails;
