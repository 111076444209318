import React, { useEffect, useState } from "react";
//Icons
import { XIcon, SearchIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { DefaultAvatar } from "helpers/utils/common/icons";
//redux
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ChronosButton from "components/Comman/Buttons";
import { fetchAllTagTypes } from "redux/marketing";
import Drawer from "@mui/material/Drawer";

const AddTagModal = ({ closeModal, onAdd, isOpen, selected, getNames }) => {
  let dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [fetching, setFetching] = useState(false);
  const TagsList = useSelector((state) => state.marketing.tagType);

  React.useEffect(() => {
    if (TagsList?.length === 0) {
      dispatch(fetchAllTagTypes());
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isOpen && selected) {
      setSelectedUsers([...selected]);
    }
    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  const addOrRemoveUser = async (item) => {
    let tempSelected = [...selectedUsers] || [];

    if (tempSelected.find((e) => e?.id == item?.id)) {
      tempSelected = tempSelected.filter((e) => e?.id !== item?.id);
    } else {
      tempSelected.push(item);
    }

    setSelectedUsers([...tempSelected]);
  };

  const onSave = () => {
    let temp = selectedUsers.map((item) => item?.id);
    if (getNames) {
      onAdd([...selectedUsers]);
    } else onAdd([...temp]);
    closeModal();
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={() => closeModal()}
        transitionDuration={500}
      >
        <div className="h-screen relative lg:hidden flex flex-col space-y-4">
          <div className="app-bg text-primary-indigo-650">
            <div className="flex flex-row items-center justify-between px-12 py-4">
              <h5
                className="inter font-bold text-sm"
                id="exampleModalLiveLabel"
              >
                Add Tags
              </h5>
              <button
                aria-label="Close"
                type="button"
                onClick={() => closeModal()}
              >
                <XIcon className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="px-7">
            <div className="h-12vh text-primary-gray-350 p-3 overflow-y-scroll">
              <div className="flex flex-row items-center flex-wrap">
                {selectedUsers?.length > 0 &&
                  selectedUsers?.map((item, index) => {
                    return (
                      <div className="m-1.5 components-controls-tags-single-div">
                        <div className="py-1 px-4 rounded-full bg-indigo-100">
                          <div className="flex flex-row items-center justify-between text-primary-gray-500 space-x-2 font-medium">
                            <p className="text-xs">{item?.name} </p>
                            <XIcon
                              onClick={() => {
                                addOrRemoveUser(item);
                              }}
                              className="w-3 h-3 text-primary-indigo-650 cursor-pointer"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {selectedUsers.length === 0 && <p>Select tags...</p>}
              </div>
            </div>
            <div className="px-4">
              <div className="flex flex-row items center chronos-gray-card py-2 px-4">
                <SearchIcon className="w-5 h-5 text-primary-gray-350" />
                <input
                  className="p-0 border-0 bg-transparent pl-2"
                  placeholder="Search..."
                  value={searchItem}
                  onChange={(e) => setSearchItem(e?.target?.value)}
                />
              </div>
            </div>
          </div>

          <div className="w-full px-7 h-auto">
            <div
              className={`chronos-gray-card max-w-md mx-auto h-60vh p-4 overflow-y-scroll`}
            >
              {!fetching &&
                TagsList?.length > 0 &&
                TagsList?.map((type) => {
                  return (
                    <>
                      <div className="flex flex-row items-center justify-between py-6 px-4">
                        <h1 className="text-xl font-bold">{type?.name}</h1>
                      </div>
                      <div className="flex flex-row items-center flex-wrap ml-2">
                        {type?.tags?.length > 0 &&
                          type?.tags?.map((item) => {
                            if (
                              searchItem !== "" &&
                              !item?.name
                                ?.toLowerCase()
                                .includes(searchItem.toLowerCase())
                            )
                              return;
                            return (
                              <div
                                onClick={() => {
                                  addOrRemoveUser(item);
                                }}
                                className="m-1.5 components-controls-tags-single-div relative"
                              >
                                <div className="py-1 px-4 rounded-full bg-indigo-100">
                                  <div className="flex flex-row items-center justify-between text-primary-gray-500 space-x-2 font-medium">
                                    <p className="text-xs">{item?.name} </p>
                                    <CheckCircleIcon
                                      className={`h-5 w-5 components-modals-multi-checkcircleicon2 ${
                                        selectedUsers.find(
                                          (e) => e?.id === item?.id
                                        )
                                          ? "block"
                                          : "hidden"
                                      }`}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  );
                })}
              {fetching &&
                [1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                  return (
                    <div
                      onClick={() => {
                        addOrRemoveUser(item);
                      }}
                      className={`bg-white-br-9-shadow px-2 py-3.5 h-24 relative flex flex-col items-center justify-center space-y-2.5 cursor-pointer transition transform hover:scale-105 ease-in-out duration-150`}
                    >
                      <CheckCircleIcon
                        className={`h-5 w-5 absolute top-1.5 right-1.5 components-modals-multi-checkcircleicon ${
                          selectedUsers.find((e) => e?.id === item?.id)
                            ? "visible"
                            : "invisible"
                        }`}
                      />

                      <img
                        className="h-9 w-9 rounded-full"
                        src={item.image?.url || DefaultAvatar}
                        alt=""
                      />
                      <p className="inter text-sm h-4 rounded bg-gray-200 animate-pulse w-8/12 font-normal text-primary-gray-600"></p>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="fixed bottom-16 left-1/2 transform -translate-x-1/2">
            <ChronosButton
              text="Add +"
              primary
              onClick={() => {
                onSave();
              }}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default AddTagModal;
