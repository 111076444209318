import React, { useEffect, useState, useRef, Fragment } from "react";

//Redux
import { useDispatch } from "react-redux";
import { XIcon } from "@heroicons/react/solid";
import { PlusIcon } from "@heroicons/react/outline";

import Drawer from "@mui/material/Drawer";

import * as MarketingAPI from "config/APIs/marketing";
import * as TagsAPI from "config/APIs/tags";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import { showToast } from "redux/toaster";
import SimpleInput from "components/Comman/Inputs/SimpleInput";
import SimpleTextArea from "components/Comman/Inputs/SimpleTextArea";
import SimpleResizeInput from "components/Comman/Inputs/SimpleResizeInput";
import ChronosButton from "components/Comman/Buttons";
import ImageSelector from "components/Comman/Inputs/ImageSelector";
import { fetchWithType } from "redux/marketing";
import AddTagModal from "components/Comman/Modals/MultipleAddModals/AddTags";

const defaultValue = {
  tags: [],
};

const NewMarketingManagement = ({
  type,
  onCreate,
  onUpdate,
  editValues,
  isEdit,
  closeModal,
  isOpen,
  label,
  character,
}) => {
  const [newData, setNewData] = useState(defaultValue);
  const [fieldErrors, setFieldErrors] = useState({});
  const [tags, setTags] = useState([]);

  // Progress state
  const [creating, setCreating] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [focusTitle, setFocusTitle] = useState(isOpen);
  const [tagModal, setTagModal] = useState(false);
  const [selectedTags, setselectedTags] = useState(defaultValue);
  const [selectedToDelete, setSelectedToDelete] = useState();
  const [addConflictData, setAddConflictData] = useState([defaultValue]);
  const [conflictIndex, setConflictIndex] = useState();

  const bool = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (editValues) setNewData(editValues);
  }, [editValues]);

  useEffect(() => {
    if (isEdit && bool) {
      setselectedTags({
        tags: editValues.tags,
      });
    }
    bool.current = false;
  }, []);

  useEffect(() => {
    fetchAllTags();
  }, [editValues]);

  const fetchAllTags = async () => {
    try {
      const response = await TagsAPI.getTags("tag");
      if (response.data.data) {
        let data = response.data.data?.tags;

        setTags(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (value, field) => {
    var data = { ...newData };
    if (field == "category_id") {
      data["category_id"] = value.id;
      data[field] = value;
      setNewData(data);
    } else {
      data[field] = value;
      setNewData(data);
    }
    if (field !== "name") {
      setFocusTitle(false);
    }
  };

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleAddConflicts = () => {
    addConflictData.map(async (item) => {
      let temp = item.tags.map((it) => {
        return it.id;
      });
      let body = { ...item, tags: temp };

      body["marketingCharacterId"] = character?.id;

      try {
        console.log(type, body);
        const response = await MarketingAPI.createEntity(type, body);
        if (response.data) {
          onCreate(response?.data?.data);
          closeModal();
        }
        if (type === "conflict") {
          dispatch(fetchWithType("character"));
        }
        dispatch(fetchWithType(type));
      } catch (err) {
        console.log("Marketing create err:", err);
        switch (err.response?.status) {
          case 422:
          case 401:
            showErrorNotification(err.response?.data?.message);
            break;
          default:
            showErrorNotification("Something went wrong!");
        }
      }
    });
  };

  const handleCreate = async () => {
    setCreating(true);
    console.log("newData", newData);
    const body = { ...newData };
    if (newData?.image) {
      body["image_id"] = newData?.image?.id;
    }
    if (character && type == "conflict") {
      body["marketingCharacterId"] = character?.id;
    }
    if (selectedTags?.tags && type != "conflict") {
      body["tags"] = selectedTags.tags.map((item) => item?.id);
    }

    try {
      console.log(type, body);
      const response = await MarketingAPI.createEntity(type, body);
      if (response.data) {
        onCreate(response?.data?.data);
        closeModal();
      }
      if (type === "conflict") {
        dispatch(fetchWithType("character"));
      }
      dispatch(fetchWithType(type));
    } catch (err) {
      console.log("Marketing create err:", err);
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
    closeModal();
  };

  const handleDelete = async () => {
    const body = { ...newData };
    try {
      console.log(type, body.id);
      await MarketingAPI.deleteEntity(type, body?.id);
      showSuccessNotification("Deleted successfully!");
      closeModal();
      if (type === "conflict") {
        dispatch(fetchWithType("character"));
      }
      dispatch(fetchWithType(type));
    } catch (err) {
      console.log("Delete error", err);
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
    closeModal();
  };

  const handleUpdate = async () => {
    setUpdating(true);
    const body = { ...newData };
    if (newData?.image) {
      body["image_id"] = newData?.image?.id;
    }
    if (selectedTags?.tags) {
      body["tags"] = selectedTags.tags.map((item) => item?.id);
    }
    if (type == "character") {
      body["conflict"] = newData?.marketingConflict?.map((item) => item?.id);
    }
    try {
      console.log(body);
      const response = await MarketingAPI.updateEntity(type, body?.id, body);
      if (response?.data) {
        onUpdate(response?.data?.data);
      }
      if (type === "conflict") {
        dispatch(fetchWithType("character"));
      }
      dispatch(fetchWithType(type));
    } catch (err) {
      console.log("Marketing update err:", err);
      switch (err.response?.status) {
        case 422:
        case 401:
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Something went wrong!");
      }
    }
    closeModal();
  };
  return (
    <>
      <ConfirmModal
        isOpen={selectedToDelete ? true : false}
        closeModal={() => setSelectedToDelete(null)}
        onAccept={() => handleDelete()}
        text={
          <Fragment>
            Are you sure you want to delete the {type}
            <b>
              {" "}
              "{type === "character" ? newData?.character : newData?.name}"{" "}
            </b>
            ?
          </Fragment>
        }
      />

      <AddTagModal
        onAdd={(val) => {
          let temp = tags.filter((item) => val.includes(item?.id));
          setselectedTags({ ...selectedTags, tags: temp });
          if (type === "conflict" && !isEdit) {
            setAddConflictData(
              addConflictData.map((item, i) => {
                if (conflictIndex != i) {
                  return item;
                } else {
                  return { ...item, tags: temp };
                }
              })
            );
          }
        }}
        selected={selectedTags?.tags || []}
        isOpen={tagModal}
        closeModal={() => {
          setTagModal(false);
        }}
      />
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={() => closeModal()}
        transitionDuration={500}
      >
        <div className=" p-7.5  w-full lg:max-w-lg mx-auto modals-component bg-white">
          <div className="flex flex-row items-start md:items-center justify-between">
            <div className="flex flex-col justify-start">
              <div className="flex flex-row items-center justify-start">
                <h5 className="font-lato font-bold text-xl text-primary-gray-600 flex flex-row items-center mr-6">
                  {`${isEdit ? "Update" : "Add New"}  ${label}`}
                </h5>
              </div>
              {type == "conflict" && (
                <>
                  <p className="text-left leading-4 font-lato font-normal text-sm flex flex-row items-center components-modals-controls-marketingcreate-para">
                    Character: {character?.character}
                  </p>
                </>
              )}
            </div>

            <button
              aria-label="Close"
              type="button"
              onClick={() => closeModal()}
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>
          <div>
            <div className="mt-3.5 flex space-y-5 flex-col w-full">
              {type !== "character" && type !== "conflict" && (
                <div>
                  <label className="input-label">Image</label>
                  <ImageSelector
                    image={newData.image}
                    onSuccess={(file) => handleChange(file, "image")}
                    showImage
                    showName={false}
                    onDelete={() => handleChange(null, "image")}
                  />
                </div>
              )}
              {type !== "contentTypes" &&
                type != "conflict" &&
                type != "character" && (
                  <SimpleInput
                    label="Name"
                    field="name"
                    details={newData}
                    setDetails={setNewData}
                  />
                )}

              {type === "conflict" && isEdit && (
                <SimpleInput
                  label="Conflict"
                  field="name"
                  details={newData}
                  setDetails={setNewData}
                />
              )}
              {type === "character" && (
                <SimpleResizeInput
                  label="Character"
                  field="character"
                  details={newData}
                  setDetails={setNewData}
                />
              )}

              {type == "contentTypes" && (
                <>
                  <SimpleInput
                    label="Type"
                    field="type"
                    details={newData}
                    setDetails={setNewData}
                  ></SimpleInput>
                </>
              )}

              {type != "character" && type != "conflict" && (
                <SimpleTextArea
                  label="Description"
                  field="description"
                  rows={4}
                  details={newData}
                  setDetails={setNewData}
                  showError={Object.keys(fieldErrors).length > 0}
                  errorMessage={fieldErrors.description?.msg}
                ></SimpleTextArea>
              )}
              {type === "conflict" &&
                !isEdit &&
                addConflictData.map((data, index) => {
                  return (
                    <div>
                      <SimpleInput
                        label="Conflict"
                        field="name"
                        details={data}
                        setDetails={(details) => {
                          setAddConflictData(
                            addConflictData.map((item, i) => {
                              if (index != i) {
                                return item;
                              } else {
                                return details;
                              }
                            })
                          );
                        }}
                      />

                      <div className="py-4 mt-1">
                        <label className="flex flex-row items-center font-lato text-2xs leading-3 mb-2.5 font-lato font-normal components-modals-controls-marketingcreate-label">
                          <p className="mr-2">Tags</p>
                          <PlusIcon
                            onClick={() => {
                              setTagModal(true);
                              setselectedTags({ tags: data.tags });
                              setConflictIndex(index);
                            }}
                            className="w-3 h-3 cursor-pointer"
                          />
                        </label>
                        <div className="flex flex-row flex-wrap items-center -ml-1">
                          {data?.tags?.map((item) => {
                            return (
                              <div className="m-1.5 components-controls-tags-single-div">
                                <div className="py-1 px-4 rounded-full bg-indigo-100">
                                  <div className="flex flex-row items-center justify-between text-primary-gray-500 space-x-2 font-medium">
                                    <p className="text-xs">{item?.name} </p>
                                    <XIcon
                                      onClick={() => {
                                        let temp = data?.tags || [];
                                        temp = temp.filter(
                                          (i) => i?.id !== item?.id
                                        );
                                        setAddConflictData(
                                          addConflictData.map((con, i) => {
                                            if (index != i) {
                                              return con;
                                            } else {
                                              return { ...con, tags: temp };
                                            }
                                          })
                                        );
                                      }}
                                      className="w-3 h-3 text-primary-indigo-300 cursor-pointer"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}

              {type == "conflict" && isEdit && (
                <div>
                  <label className="flex flex-row items-center font-lato text-2xs leading-3 mb-2.5 font-lato font-normal components-modals-controls-marketingcreate-label">
                    <p className="mr-2">Tags</p>
                    <PlusIcon
                      onClick={() => {
                        setTagModal(true);
                      }}
                      className="w-3 h-3 cursor-pointer"
                    />
                  </label>
                  <div className="flex flex-row flex-wrap items-center -ml-1">
                    {selectedTags?.tags?.map((item) => {
                      return (
                        <div className="m-1.5 components-controls-tags-single-div">
                          <div className="py-1 px-4 rounded-full bg-indigo-100">
                            <div className="flex flex-row items-center justify-between text-primary-gray-500 space-x-2 font-medium">
                              <p className="text-xs">{item?.name} </p>
                              <XIcon
                                onClick={() => {
                                  let temp = selectedTags?.tags || [];
                                  temp = temp.filter((i) => i?.id !== item?.id);
                                  setselectedTags({
                                    ...selectedTags,
                                    tags: temp,
                                  });
                                }}
                                className="w-3 h-3 text-primary-indigo-300 cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {type != "conflict" && (
                <div>
                  <label className="flex flex-row items-center font-lato text-2xs leading-3 mb-2.5 font-lato font-normal components-modals-controls-marketingcreate-label">
                    <p className="mr-2">Tags</p>
                    <PlusIcon
                      onClick={() => {
                        setTagModal(true);
                      }}
                      className="w-3 h-3 cursor-pointer"
                    />
                  </label>
                  <div className="flex flex-row flex-wrap items-center -ml-1">
                    {selectedTags?.tags?.map((item) => {
                      return (
                        <div className="m-1.5 components-controls-tags-single-div">
                          <div className="py-1 px-4 rounded-full bg-indigo-100">
                            <div className="flex flex-row items-center justify-between text-primary-gray-500 space-x-2 font-medium">
                              <p className="text-xs">{item?.name} </p>
                              <XIcon
                                onClick={() => {
                                  let temp = selectedTags?.tags || [];
                                  temp = temp.filter((i) => i?.id !== item?.id);
                                  setselectedTags({
                                    ...selectedTags,
                                    tags: temp,
                                  });
                                }}
                                className="w-3 h-3 text-primary-indigo-300 cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {type == "conflict" && !isEdit && (
                <div
                  className=" flex justify-start font-lato font-normal text-sm text-primary-indigo-300 underline underline-offset-1 decoration-[#495197]  cursor-pointer"
                  onClick={() => {
                    setAddConflictData([...addConflictData, defaultValue]);
                  }}
                >
                  Add Conflict +
                </div>
              )}

              {type == "contentPlatform" && (
                <>
                  <SimpleInput
                    label="Platform"
                    field="platform"
                    details={newData}
                    setDetails={setNewData}
                    showError={Object.keys(fieldErrors).length > 0}
                    errorMessage={fieldErrors.platform?.msg}
                  ></SimpleInput>
                </>
              )}
            </div>
          </div>

          <div className="mt-6 flex w-full flex-row items-center space-x-4 justify-between">
            {isEdit && (
              <div className="flex">
                <ChronosButton
                  className={`${isEdit ? "block" : "hidden"}`}
                  text="Delete"
                  tertiary
                  red
                  onClick={() => {
                    setSelectedToDelete(true);
                  }}
                />
              </div>
            )}

            <div className="flex flex-row items-center space-x-2 justify-end">
              <ChronosButton
                text="Cancel"
                secondary
                onClick={() => closeModal()}
              />

              <ChronosButton
                loader={creating || updating}
                text={isEdit ? "Update" : "Create"}
                primary
                onClick={() => {
                  isEdit
                    ? handleUpdate()
                    : type === "conflict"
                    ? handleAddConflicts()
                    : handleCreate();
                }}
              />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default NewMarketingManagement;
