import { useHistory } from "react-router";
import { mobileBottomNav, mobileBottomNavProtected } from "helpers/constants";
import { checkAppAccess } from "helpers/utils/accessCheck";
import { useDispatch } from "react-redux";
import { resetTabs } from "redux/navigation";
import { Plus } from "@phosphor-icons/react";
function TabBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div>
      <div
        className={`w-full fixed z-30 bottom-0 right-0 tapbar left-0 lg:hidden transform transition-all ease-in-out duration-300 font-poppins`}
      >
        <div className=" w-full bottom-0 p-2  max-w-sm mx-auto">
          <div className="grid grid-cols-5 gap-2">
            {(checkAppAccess()
              ? mobileBottomNavProtected
              : mobileBottomNav
            )?.map((item, idx) => {
              const centerNavIdx = Math.floor(mobileBottomNav.length / 2);

              return (
                <div
                  key={idx}
                  onClick={() => {
                    dispatch(resetTabs());
                    history.push(item?.path);
                  }}
                  className="relative"
                >
                  <div
                    className={`flex flex-col items-center ${
                      idx === centerNavIdx &&
                      "absolute inset-x-0 -translate-y-3"
                    }`}
                  >
                    {idx === centerNavIdx ? (
                      <div className="h-9 w-9 mb-0.5 bg-primary-aqua-lightest flex flex-row items-center justify-center rounded-full drop-shadow-md ">
                        <Plus size={20} className="text-primary-aqua-darkest" />
                      </div>
                    ) : (
                      <div
                        className={`grid place-items-center text-2xl h-6 w-6
                         ${
                           idx === centerNavIdx
                             ? "text-primary-green-darker font-bold"
                             : window.location.pathname.includes(item?.path)
                             ? "text-primary-green-darker font-bold"
                             : "text-primary-gray-280 font-normal"
                         }  `}
                      >
                        {item.icon}
                      </div>
                    )}

                    {!item?.hideTitle && (
                      <p
                        className={`${
                          idx === centerNavIdx
                            ? "text-primary-green-darker font-bold"
                            : window.location.pathname.includes(item?.path)
                            ? "text-primary-green-darker font-bold"
                            : "text-primary-gray-280 font-normal"
                        } text-2xs inter mt-1 text-center  `}
                      >
                        {item.name}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabBar;
