import { ArrowRightIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Character from "pages/Character/IndividualCharacter";
import { BiEdit } from "react-icons/bi";
import { Check, Plus } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import * as MarketingAPI from "config/APIs/marketing";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import NewMarketingManagement from "../Modal/MarketingCreateEdit";
import { RiEditLine } from "react-icons/ri";

function Card({ item, onUpdate }) {
  const history = useHistory();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  let currentTitle = item?.name || item?.character || item?.type || "";

  const [inEditMode, setInEditMode] = useState(false);
  const [editedCharacter, setEditedCharacter] = useState(currentTitle);

  const dispatch = useDispatch();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleCancel = () => {
    setInEditMode(false);
    setEditedCharacter(currentTitle);
  };

  const handleEdit = async () => {
    if (editedCharacter.length === 0) {
      return;
    }
    if (editedCharacter === currentTitle) {
      handleCancel();
      return;
    }

    const body = {
      marketingCharacterId: item?.id,
      character: editedCharacter,
    };

    try {
      const response = await MarketingAPI.updateEntity(
        "character",
        item?.id,
        body
      );
      dispatch(showToast({ message: response.data.message, type: "success" }));
      onUpdate();
    } catch (err) {
      console.log(err);
    } finally {
      setInEditMode(false);
    }
  };

  return (
    <>
      <NewMarketingManagement
        isOpen={inEditMode}
        closeModal={() => setInEditMode(false)}
        onUpdate={onUpdate}
        label="Character"
        type="character"
        editValues={item}
        isEdit
      />{" "}
      <React.Fragment key={"bottom"}>
        <Drawer
          anchor={"bottom"}
          open={state["bottom"]}
          onClose={toggleDrawer("bottom", false)}
          PaperProps={{
            style: { height: "100vh" },
          }}
        >
          <Character
            Id={item?.id}
            closeModal={() => {
              setState({ ...state, bottom: false });
            }}
          />
        </Drawer>
      </React.Fragment>
      <div className={`cursor-pointer bg-white shadow-container rounded-20px`}>
        <div>
          <div className="relative group p-4">
            <div className="flex flex-col items-start w-full">
              <div className="flex flex-row items-start justify-between w-full">
                {/* {inEditMode ? (
                  <input
                    autoFocus
                    className="focus:outline-none w-full text-sm leading-4 font-lato text-primary-gray-1000 break-words font-bold"
                    value={editedCharacter}
                    onChange={(e) => setEditedCharacter(e.target.value)}
                  />
                ) : ( */}
                <h3 className="focus:outline-none w-full text-sm leading-4 font-lato text-primary-gray-1000 break-words font-bold">
                  {item?.name || item?.character || item?.type}
                </h3>
                {/* )} */}

                {/* {!inEditMode && ( */}
                <button onClick={() => setInEditMode(true)}>
                  <RiEditLine className="text-primary-aqua-medium" />
                </button>
                {/* )} */}
              </div>
              <div className="-m-1 flex flex-row items-center flex-wrap pt-1.5">
                {item?.tags?.map((t) => {
                  return (
                    <div className="m-1 rounded-full py-1 px-2.5 font-semibold bg-primary-aqua-lightest text-primary-aqua-darkest text-2xs font-lato">
                      {t?.name}
                    </div>
                  );
                })}
              </div>
              <div className="mt-4 font-lato text-xs font-bold text-primary-red-darkest border-primary-red-lighter break-all border-t pt-2 w-full flex flex-row items-center justify-between">
                <div onClick={toggleDrawer("bottom", true)}>
                  View {item?.marketingConflict?.length || 0} Conflict
                  {item?.marketingConflict?.length > 1 ? "s" : ""} -&gt;
                </div>
              </div>
              {/* {inEditMode && (
                <div className="flex justify-between items-end mt-4 w-full">
                  <ChronosButton
                    tertiary
                    text={<span className="underline">Cancel</span>}
                    onClick={handleCancel}
                  />
                  <ChronosButton
                    disabled={
                      editedCharacter.length === 0 ||
                      editedCharacter === currentTitle
                    }
                    primary
                    onClick={handleEdit}
                    text={
                      <p className="flex items-center gap-2">
                        <Check />
                        <span>Done</span>
                      </p>
                    }
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ListView({ list, onUpdate }) {
  const [inAddMode, setInAddMode] = useState(false);
  const [newCharacter, setNewCharacter] = useState("");

  const dispatch = useDispatch();

  const handleAdd = async () => {
    if (newCharacter.length === 0) {
      return;
    }

    const body = {
      character: newCharacter,
    };

    try {
      const response = await MarketingAPI.createEntity("character", body);
      dispatch(showToast({ message: response.data.message, type: "success" }));
      onUpdate();
    } catch (err) {
      console.log(err);
    } finally {
      setInAddMode(false);
      setNewCharacter("");
    }
  };

  const handleCancel = () => {
    setInAddMode(false);
    setNewCharacter("");
  };

  return (
    <>
      <div className="w-full relative px-7.5">
        <div className="max-h-70vh overflow-y-auto pb-5 space-y-5">
          {list?.length > 0 &&
            list?.map((item) => <Card item={item} onUpdate={onUpdate} />)}
        </div>

        {inAddMode && (
          <div className="px-5">
            <input
              placeholder="Add Character..."
              className="border-b w-full border-primary-gray-250 py-4 px-2"
              value={newCharacter}
              onChange={(e) => setNewCharacter(e.target.value)}
            />
          </div>
        )}

        {!inAddMode && (
          <div className="flex w-full justify-end px-5">
            <ChronosButton
              tertiary
              onClick={() => setInAddMode(true)}
              text={
                <>
                  <Plus />
                  <span className="underline">Add Character</span>
                </>
              }
            />
          </div>
        )}

        {inAddMode && (
          <div className="flex justify-between items-end p-5">
            <ChronosButton
              tertiary
              text={<span className="underline">Cancel</span>}
              onClick={handleCancel}
            />
            <ChronosButton
              disabled={newCharacter.length === 0}
              primary
              onClick={handleAdd}
              text={
                <p className="flex items-center gap-2">
                  <Check />
                  <span>Done</span>
                </p>
              }
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ListView;
