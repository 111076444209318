/**
 * Chronos Button Component
 * @param {String} text
 * @param {Function} onClick
 * @param {Boolean} primary
 * @param {Boolean} secondary
 * @param {Boolean} tertiary
 * @returns
 */

function ChronosButton({
  text = "Add",
  onClick,
  primary,
  secondary,
  tertiary,
  icon,
  loader,
  disabled,
  red,
  underline,
  iconReverse,
  light,
}) {
  return (
    <div>
      <button
        disabled={disabled}
        onClick={onClick}
        className={`py-2.5 flex flex-row items-center justify-center cursor-pointer ${
          tertiary ? "px-0" : "px-4"
        } ${underline ? "underline" : ""} ${
          primary
            ? light
              ? "primary-light-cta"
              : "primary-cta"
            : secondary
            ? "secondary-cta"
            : tertiary
            ? red
              ? "tertiary-red-cta"
              : "tertiary-cta"
            : "primary-cta"
        } `}
      >
        <p
          className={`flex ${
            iconReverse ? "flex-row-reverse" : "flex-row"
          } items-center`}
        >
          {text} {icon && icon}
        </p>
        {loader && (
          <div className="w-4 h-4 bg-transparent border-white border-2 rounded-full border-t-0 animate-spin ml-2" />
        )}
      </button>
    </div>
  );
}

export default ChronosButton;
