import { useEffect, useState } from "react";

//Redux
import { PlusIcon, XIcon } from "@heroicons/react/solid";

import { useDispatch, useSelector } from "react-redux";

import { DefaultAvatar } from "helpers/utils/common/icons";
import WideModalsWrapper from "components/Comman/Modals/ModalsWrapper/WideModalWrapper";
import SimpleInput from "components/Comman/Inputs/SimpleInput";
import AddTags from "./AddTags";
import AddContentAttributes from "./AddContentAttributes";
import SimpleRadioBoxesForObject from "components/Comman/Inputs/SimpleRadioBoxeForObject";
import { marketingStage } from "helpers/constants/marketing";
import SimpleTextArea from "components/Comman/Inputs/SimpleTextArea";
import AddMarketingAttributesModal from "components/Comman/Modals/MultipleAddModals/AddMarketingAttributes";
import { fetchWithType } from "redux/marketing";
import { Pencil, Plus } from "@phosphor-icons/react";

function Details({ details, setDetails }) {
  const dispatch = useDispatch();
  const [marketingModal, setMarketingModal] = useState({
    isOpen: false,
    type: "",
  });
  const contentType = useSelector((state) => state.marketing.contentTypes);
  const contentPlatform = useSelector(
    (state) => state.marketing.contentPlatform
  );
  const clusters = useSelector((state) => state.marketing.topicCluster);
  const pillars = useSelector((state) => state.marketing.contentPillars);
  const tags = useSelector((state) => state.marketing.tags);
  const buckets = useSelector((state) => state.marketing.contentBucket);
  useEffect(() => {
    if (contentType.length === 0) {
      dispatch(fetchWithType("contentTypes"));
    }
    if (contentPlatform.length === 0) {
      dispatch(fetchWithType("contentPlatform"));
    }
  }, []);

  return (
    <div className="theme-container py-5 relative">
      <AddMarketingAttributesModal
        isOpen={marketingModal?.isOpen}
        label={marketingModal?.label}
        onAdd={(val) => {
          let temp;
          console.log(val);
          switch (marketingModal?.type) {
            case "contentPlatforms":
              temp = contentPlatform.filter((item) => val.includes(item?.id));
              console.log(temp, contentPlatform);
              setDetails({ ...details, contentPlatforms: val });
              break;
            case "contentTypes":
              temp = contentType.filter((item) => val.includes(item?.id));
              console.log(temp, contentType);
              setDetails({ ...details, marketingContentTypes: val });
              break;

            case "topicClusters":
              temp = clusters.filter((item) => val.includes(item?.id));

              setDetails({ ...details, topicClusters: val });
              break;
            case "contentBuckets":
              temp = buckets.filter((item) => val.includes(item?.id));

              setDetails({ ...details, marketingContentBuckets: val });
              break;
            case "contentPillars":
              temp = pillars.filter((item) => val.includes(item?.id));

              setDetails({ ...details, contentPillars: val });
              break;
            case "tags":
              temp = tags.filter((item) => val.includes(item?.id));

              setDetails({ ...details, tags: val });
              break;
            default:
          }
          console.log(temp);

          setMarketingModal();
        }}
        length={marketingModal?.length}
        type={marketingModal?.type}
        selected={marketingModal?.selected || []}
        closeModal={() => {
          setMarketingModal();
        }}
      />

      <div className="flex space-y-5 flex-col w-full">
        <SimpleTextArea
          label="Name"
          field="name"
          details={details}
          setDetails={setDetails}
          placeholder="Enter your idea!"
        />
        <SimpleTextArea
          label="Description"
          field="description"
          rows={4}
          details={details}
          setDetails={setDetails}
        />
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-2">
              <h1 className="text-primary-neutral-800 font-karla text-sm font-medium">
                Choose your Content Type
              </h1>{" "}
              {details?.marketingContentTypes?.length > 0 && (
                <div
                  onClick={() => {
                    setMarketingModal({
                      isOpen: true,
                      selected: details?.marketingContentTypes,
                      type: "contentTypes",
                      length: 1,
                      label: "Content Type",
                    });
                  }}
                  className="flex flex-row items-center text-primary-aqua-medium space-x-2"
                >
                  <Pencil size={10} />
                </div>
              )}
            </div>
            <p className="text-primary-neutral-500 font-lato text-xs font-light">
              How you want to interact with your audience
            </p>
          </div>
          {details?.marketingContentTypes?.length > 0 ? (
            details?.marketingContentTypes?.map((item) => {
              return (
                <div className="py-1 px-1.5 rounded-full bg-white flex flex-row items-center max-w-max">
                  <img
                    src={item?.image?.url}
                    alt="tags"
                    className="w-3 h-3 object-cover bg-primary-gray-200 rounded-full"
                  />
                  <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs">
                    {item?.type}
                  </p>
                  <XIcon
                    onClick={() => {
                      let temp = details?.marketingContentTypes || [];
                      temp = temp.filter((i) => i?.id !== item?.id);
                      setDetails({
                        ...details,
                        marketingContentTypes: temp,
                      });
                    }}
                    className="w-3 h-3 text-primary-aqua-medium cursor-pointer"
                  />
                </div>
              );
            })
          ) : (
            <div
              onClick={() => {
                setMarketingModal({
                  isOpen: true,
                  selected: details?.marketingContentTypes,
                  type: "contentTypes",
                  length: 1,
                  label: "Content Type",
                });
              }}
              className="text-primary-aqua-medium font-lato text-xs font-semibold rounded-lg border border-primary-aqua-medium text-center w-full flex flex-row items-center justify-center cursor-pointer bg-primary-indigo-30 px-4 py-3"
            >
              Select Content Type
            </div>
          )}
        </div>
        {console.log(details)}
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-2">
              <h1 className="text-primary-neutral-800 font-karla text-sm font-medium">
                Choose the platform you want to use
              </h1>
              {details?.contentPlatforms?.length > 0 && (
                <div
                  onClick={() => {
                    setMarketingModal({
                      isOpen: true,
                      selected: details?.contentPlatforms,
                      type: "contentPlatforms",
                      length: 1,
                      label: "Content Platform",
                    });
                  }}
                  className="flex flex-row items-center text-primary-aqua-medium space-x-2"
                >
                  <Pencil size={10} />
                </div>
              )}
            </div>
            <p className="text-primary-neutral-500 font-lato text-xs font-light">
              Where you want to interact with your audience
            </p>
          </div>
          {details?.contentPlatforms?.length > 0 ? (
            details?.contentPlatforms?.map((item) => {
              return (
                <div className="py-1 px-1.5 rounded-full bg-white flex flex-row items-center max-w-max">
                  <img
                    src={item?.image?.url}
                    alt="tags"
                    className="w-3 h-3 object-cover bg-primary-gray-200 rounded-full"
                  />
                  <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs">
                    {item?.name}
                  </p>
                  <XIcon
                    onClick={() => {
                      let temp = details?.contentPlatforms || [];
                      temp = temp.filter((i) => i?.id !== item?.id);
                      setDetails({
                        ...details,
                        contentPlatforms: temp,
                      });
                    }}
                    className="w-3 h-3 text-primary-aqua-medium cursor-pointer"
                  />
                </div>
              );
            })
          ) : (
            <div
              onClick={() => {
                setMarketingModal({
                  isOpen: true,
                  selected: details?.contentPlatforms,
                  type: "contentPlatforms",
                  length: 1,
                  label: "Content Platform",
                });
              }}
              className="text-primary-aqua-medium font-lato text-xs font-semibold rounded-lg border border-primary-aqua-medium text-center w-full flex flex-row items-center justify-center cursor-pointer bg-primary-indigo-30 px-4 py-3"
            >
              Select Content Platform
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-2">
              <h1 className="text-primary-neutral-800 font-karla text-sm font-medium">
                Select a Content Bucket
              </h1>
              {details?.marketingContentBuckets?.length > 0 && (
                <div
                  onClick={() => {
                    setMarketingModal({
                      isOpen: true,
                      selected: details?.marketingContentBuckets,
                      type: "contentBuckets",
                      label: "Content Buckets",
                    });
                  }}
                  className="flex flex-row items-center text-primary-aqua-medium space-x-2"
                >
                  <Plus size={10} />
                </div>
              )}
            </div>
            <p className="text-primary-neutral-500 font-lato text-xs font-light">
              Think of the manner in which you would like to communicate this
              idea
            </p>
          </div>
          {details?.marketingContentBuckets?.length > 0 ? (
            <div className="flex flex-col space-y-1">
              {details?.marketingContentBuckets?.map((item) => {
                return (
                  <div className="py-1 px-1.5 rounded-full bg-white flex flex-row items-center max-w-max">
                    <img
                      src={item?.image?.url}
                      alt="tags"
                      className="w-3 h-3 object-cover bg-primary-gray-200 rounded-full"
                    />
                    <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs">
                      {item?.name}
                    </p>
                    <XIcon
                      onClick={() => {
                        let temp = details?.marketingContentBuckets || [];
                        temp = temp.filter((i) => i?.id !== item?.id);
                        setDetails({
                          ...details,
                          marketingContentBuckets: temp,
                        });
                      }}
                      className="w-3 h-3 text-primary-aqua-medium cursor-pointer"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              onClick={() => {
                setMarketingModal({
                  isOpen: true,
                  selected: details?.marketingContentBuckets,
                  type: "contentBuckets",
                  label: "Content Buckets",
                });
              }}
              className="text-primary-aqua-medium font-lato text-xs font-semibold rounded-lg border border-primary-aqua-medium text-center w-full flex flex-row items-center justify-center cursor-pointer bg-primary-indigo-30 px-4 py-3"
            >
              Select Content Buckets
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-2">
              <h1 className="text-primary-neutral-800 font-karla text-sm font-medium">
                Choose a Topic Cluster
              </h1>
              {details?.topicClusters?.length > 0 && (
                <div
                  onClick={() => {
                    setMarketingModal({
                      isOpen: true,
                      selected: details?.topicClusters,
                      type: "topicClusters",
                      label: "Topic Clusters",
                    });
                  }}
                  className="flex flex-row items-center text-primary-aqua-medium space-x-2"
                >
                  <Plus size={10} />
                </div>
              )}
            </div>
            <p className="text-primary-neutral-500 font-lato text-xs font-light">
              How you want to categorize your idea
            </p>
          </div>
          {details?.topicClusters?.length > 0 ? (
            <div className="flex flex-col space-y-1">
              {details?.topicClusters?.map((item) => {
                return (
                  <div className="py-1 px-1.5 rounded-full bg-white flex flex-row items-center max-w-max">
                    <img
                      src={item?.image?.url}
                      alt="tags"
                      className="w-3 h-3 object-cover bg-primary-gray-200 rounded-full"
                    />
                    <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs">
                      {item?.name}
                    </p>
                    <XIcon
                      onClick={() => {
                        let temp = details?.topicClusters || [];
                        temp = temp.filter((i) => i?.id !== item?.id);
                        setDetails({
                          ...details,
                          topicClusters: temp,
                        });
                      }}
                      className="w-3 h-3 text-primary-aqua-medium cursor-pointer"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              onClick={() => {
                setMarketingModal({
                  isOpen: true,
                  selected: details?.topicClusters,
                  type: "topicClusters",
                  label: "Topic Clusters",
                });
              }}
              className="text-primary-aqua-medium font-lato text-xs font-semibold rounded-lg border border-primary-aqua-medium text-center w-full flex flex-row items-center justify-center cursor-pointer bg-primary-indigo-30 px-4 py-3"
            >
              Select Topic Clusters
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-2">
              <h1 className="text-primary-neutral-800 font-karla text-sm font-medium">
                Select a Content Pillars
              </h1>
              {details?.contentPillars?.length > 0 && (
                <div
                  onClick={() => {
                    setMarketingModal({
                      isOpen: true,
                      selected: details?.contentPillars,
                      type: "contentPillars",
                      label: "Content Pillars",
                    });
                  }}
                  className="flex flex-row items-center text-primary-aqua-medium space-x-2"
                >
                  <Plus size={10} />
                </div>
              )}
            </div>
            <p className="text-primary-neutral-500 font-lato text-xs font-light">
              Think of the manner in which you would like to communicate this
              idea
            </p>
          </div>
          {details?.contentPillars?.length > 0 ? (
            <div className="flex flex-col space-y-1">
              {details?.contentPillars?.map((item) => {
                return (
                  <div className="py-1 px-1.5 rounded-full bg-white flex flex-row items-center max-w-max">
                    <img
                      src={item?.image?.url}
                      alt="tags"
                      className="w-3 h-3 object-cover bg-primary-gray-200 rounded-full"
                    />
                    <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs">
                      {item?.name}
                    </p>
                    <XIcon
                      onClick={() => {
                        let temp = details?.contentPillars || [];
                        temp = temp.filter((i) => i?.id !== item?.id);
                        setDetails({
                          ...details,
                          contentPillars: temp,
                        });
                      }}
                      className="w-3 h-3 text-primary-aqua-medium cursor-pointer"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              onClick={() => {
                setMarketingModal({
                  isOpen: true,
                  selected: details?.contentPillars,
                  type: "contentPillars",
                  label: "Content Pillars",
                });
              }}
              className="text-primary-aqua-medium font-lato text-xs font-semibold rounded-lg border border-primary-aqua-medium text-center w-full flex flex-row items-center justify-center cursor-pointer bg-primary-indigo-30 px-4 py-3"
            >
              Select Content Pillars
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-2">
              <h1 className="text-primary-neutral-800 font-karla text-sm font-medium">
                Select Tags
              </h1>
              {details?.tags?.length > 0 && (
                <div
                  onClick={() => {
                    setMarketingModal({
                      isOpen: true,
                      selected: details?.tags,
                      type: "tags",
                      label: "Tags",
                    });
                  }}
                  className="flex flex-row items-center text-primary-aqua-medium space-x-2"
                >
                  <Plus size={10} />
                </div>
              )}
            </div>
            <p className="text-primary-neutral-500 font-lato text-xs font-light">
              Think of the manner in which you would like to communicate this
              idea
            </p>
          </div>
          {details?.tags?.length > 0 ? (
            <div className="flex flex-col space-y-1">
              {" "}
              {details?.tags?.map((item) => {
                return (
                  <div className="py-1 px-1.5 rounded-full bg-white flex flex-row items-center max-w-max">
                    <img
                      src={item?.image?.url}
                      alt="tags"
                      className="w-3 h-3 object-cover bg-primary-gray-200 rounded-full"
                    />
                    <p className="font-lato mx-1.5 font-normal text-primary-gray-600 text-2xs">
                      {item?.name}
                    </p>
                    <XIcon
                      onClick={() => {
                        let temp = details?.tags || [];
                        temp = temp.filter((i) => i?.id !== item?.id);
                        setDetails({
                          ...details,
                          tags: temp,
                        });
                      }}
                      className="w-3 h-3 text-primary-aqua-medium cursor-pointer"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              onClick={() => {
                setMarketingModal({
                  isOpen: true,
                  selected: details?.tags,
                  type: "tags",
                  label: "Tags",
                });
              }}
              className="text-primary-aqua-medium font-lato text-xs font-semibold rounded-lg border border-primary-aqua-medium text-center w-full flex flex-row items-center justify-center cursor-pointer bg-primary-indigo-30 px-4 py-3"
            >
              Select Tags
            </div>
          )}
        </div>
        {/* <div className="space-y-2.5">
          {" "}
          <h1 className="inter font-normal text-sm text-primary-gray-600 text-left">
            Marketing Stage:
          </h1>
          <SimpleRadioBoxesForObject
            maxItems={5}
            minItems={4}
            field="marketingStage"
            details={details}
            setDetails={setDetails}
            list={marketingStage.map((item) => ({
              value: item,
              label: item,
            }))}
            darkSelect
            selectBlue
          />
        </div> */}

        {/* <SimpleInput
          label="Resolution"
          field="resolution"
          placeholder="Enter business resolution here"
          details={details}
          setDetails={setDetails}
          bigText
        /> */}
      </div>
    </div>
  );
}

export default Details;
