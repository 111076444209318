import { Trash } from "@phosphor-icons/react";
import React from "react";

function VerticalCard({ item, editable, selected, onClick, onRemove }) {
  return (
    <div
      onClick={onClick}
      className={`p-4 flex flex-col items-center space-y-4 relative min-w-[60%] max-w-[60%] lg:min-w-full rounded-2xl ${
        selected ? "bg-primary-aqua-50 border border-primary-aqua-light" : ""
      }`}
    >
      {item?.image ? (
        <img
          src={item?.image?.url}
          className="w-20 h-20 object-contain rounded-lg"
        />
      ) : (
        <div className="w-20 h-20 object-contain bg-primary-gray-100 rounded-lg" />
      )}
      <p className="font-lato text-center text-sm text-primary-gray-800">
        {item?.name}
      </p>
      {editable && (
        <Trash
          onClick={onRemove}
          size={16}
          className="text-secondary-red-550 absolute top-2 right-2"
        />
      )}
    </div>
  );
}

export default VerticalCard;
