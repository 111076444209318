//ICONS
import ChronosButton from "components/Comman/Buttons";
import React from "react";

function ConfirmationModal({
  closeModal,
  textComponent,
  noText,
  yesText,
  onYes,
  onNo,
}) {
  return (
    <>
      <div
        style={{ maxWidth: "560px" }}
        className="bg-white-br-9 w-full mx-auto g-white-br-9-shadow-laptop p-4.5 relative"
      >
        <h1 className="text-left mt-2 inter font-normal text-sm text-primary-gray-600">
          {React.cloneElement(textComponent, {})}
        </h1>

        <div className="flex flex-row items-center justify-around mt-6">
          <ChronosButton
            text={yesText}
            primary
            red
            onClick={() => {
              onYes();
              closeModal();
            }}
          />
          {noText && (
            <ChronosButton
              text={noText}
              tertiary
              outline
              onClick={() => {
                onNo();
                closeModal();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ConfirmationModal;
