import React, { useState, useEffect } from "react";
import { showToast } from "redux/toaster";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import EditDetails from "./EditCampaign";
import ViewDetails from "./ViewCampaign";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import * as MarketingApi from "config/APIs/marketing/index";
import ViewEditDetails from "./Details";

/**
 * TODO:
 * Show edit or view component
 * Update Campaign
 * Delete Campaign
 */
function Details({ CampaignDetails, onUpdate }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [editable, setEditable] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    setDetails(CampaignDetails);
  }, [CampaignDetails]);

  const deleteCampaign = async () => {
    setFetching(true);
    try {
      const response = await MarketingApi.deleteEntity("campaign", details.id);
      if (response.data) {
        dispatch(showToast({ message: "Campaign Deleted Sucessfully!" }));
        history.goBack();
      }
    } catch (err) {
      console.log("Delete task error", err);
      switch (err.response?.status) {
        case 401:
          dispatch(showToast({ message: "Unauthorized!", type: "error" }));
          break;
        default:
          dispatch(
            showToast({ message: "Something went wrong!", type: "error" })
          );
      }
    }
    setFetching(false);
  };

  const handleUpdate = async () => {
    let body = {
      ...details,
    };
    try {
      const response = await MarketingApi.updateEntity(
        "campaign",
        details.id,
        body
      );
      if (response.data) {
        onUpdate();
      }
    } catch (err) {
      switch (err.response?.status) {
        case 401:
          dispatch(showToast({ message: "Unauthorized!", type: "error" }));
          break;
        default:
          dispatch(
            showToast({ message: "Something went wrong!", type: "error" })
          );
      }
    }
    setDetails(CampaignDetails);
  };

  return (
    <div className="w-full">
      <ViewEditDetails
        details={details}
        setDetails={setDetails}
        onUpdate={() => handleUpdate()}
        onDelete={() => setDeleteModal(true)}
      />{" "}
      <ConfirmModal
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        onAccept={() => {
          deleteCampaign();
          setDeleteModal(false);
        }}
        text="Are you sure you want to delete the Task?"
      />
      {/*     {editable ? (
        <EditDetails
          setEditable={(val) => {
            setEditable(val);
          }}
          onUpdate={() => handleUpdate()}
          details={details}
          setDetails={setDetails}
          onDelete={() => deleteCampaign()}
        />
      ) : (
        <ViewDetails
          fetching={fetching}
          details={details}
          setEditable={(val) => {
            setEditable(val);
          }}
        />
      )} */}
    </div>
  );
}

export default Details;
