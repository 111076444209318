import { useEffect, useState } from "react";
//ICONS
import { XIcon, PlusIcon } from "@heroicons/react/solid";
//Redux
import { useDispatch, useSelector } from "react-redux";

import liIcon from "assets/imgimports/icons/li_icon.svg";
import ChronosButton from "components/Comman/Buttons";
import { fetchWithType } from "redux/marketing";
function EditCharacterConflict({
  closeModal,
  onChange,
  character,
  conflicts,
  onRemove,
}) {
  const dispatch = useDispatch();
  const characterList = useSelector((state) => state.marketing.character);
  const conflictList = useSelector((state) => state.marketing.conflict);
  const [selectedConflict, setSelectedConflict] = useState([]);

  useEffect(() => {
    if (characterList.length === 0) {
      dispatch(fetchWithType("character"));
    }
    if (conflictList.length === 0) {
      dispatch(fetchWithType("conflict"));
    }
  }, []);

  useEffect(() => {
    setSelectedConflict(conflicts);
  }, [conflicts]);

  const addOrRemove = (list, item) => {
    let tempSelected = list || [];

    if (tempSelected.find((e) => e?.id == item?.id)) {
      tempSelected = tempSelected.filter((e) => e?.id !== item?.id);
    } else {
      tempSelected.push(item);
    }

    return tempSelected;
  };

  return (
    <div className="p-7.5 w-full md:max-w-md mx-auto text-sm sm:h-full z-100 contentcreate-div1 relative">
      <div className="flex flex-row items-start md:items-center justify-between mb-2">
        <div className="flex flex-row items-center justify-start space-x-4">
          <h5
            className="inter font-bold text-lg text-primary-gray-600 flex flex-col items-start"
            id="exampleModalLiveLabel"
          >
            Edit Conflicts
          </h5>
        </div>
        <div className="flex flex-row items-center">
          <button aria-label="Close" type="button" onClick={() => closeModal()}>
            <XIcon className="h-4 w-4 text-primary-indigo-650" />
          </button>
        </div>
      </div>

      <div className="mt-6">
        <div className="character-card-container bg-white">
          <div className="upper-character-card bg-white border-active border-2 flex items-center px-4.5 py-2.5 justify-between">
            <h1 className="upper-character-card-h1 text-active inter font-bold text-sm cursor-pointer">
              {character?.character}
            </h1>

            <XIcon
              onClick={() => {
                onRemove(character);
                closeModal();
              }}
              className="cursor-pointer h-4 w-4 text-alert"
              alt="add-icon"
            ></XIcon>
          </div>
          <div className="px-4.5 py-2.5">
            <ul>
              {conflictList?.map((item) => {
                if (item?.marketingCharacterId === character?.id)
                  return (
                    <div className=" flex justify-between mb-2.5 character-card-left">
                      <div className="flex">
                        <div className="character-card-li-icon flex items-center mr-1.5">
                          <img
                            className="character-card-li-icon-img"
                            src={liIcon}
                            alt="li-icon"
                          ></img>
                        </div>
                        <li
                          className={`${
                            selectedConflict?.find((i) => i?.id == item?.id)
                              ? "text-active"
                              : "text-primary-gray-600"
                          } character-card-li inter text-sm font-normal `}
                        >
                          {item.name}
                        </li>
                      </div>
                      {selectedConflict?.find((i) => i?.id == item?.id) ? (
                        <XIcon
                          onClick={() => {
                            let temp = addOrRemove([...selectedConflict], item);
                            setSelectedConflict(temp);
                          }}
                          className="cursor-pointer h-4 w-4 text-alert"
                        ></XIcon>
                      ) : (
                        <PlusIcon
                          onClick={() => {
                            let temp = addOrRemove([...selectedConflict], item);
                            setSelectedConflict(temp);
                          }}
                          className="cursor-pointer h-4 w-4 font-chronos-grey-350"
                        ></PlusIcon>
                      )}
                    </div>
                  );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse items-center justify-around mt-6">
        <ChronosButton
          text="Update"
          primary
          onClick={() => {
            onChange(selectedConflict);
            closeModal();
          }}
        />
        <ChronosButton
          text="Cancel"
          tertiary
          outline
          onClick={() => {
            closeModal();
          }}
        />
      </div>
    </div>
  );
}

export default EditCharacterConflict;
