import { createSlice } from "@reduxjs/toolkit";
import * as MarketingAPI from "config/APIs/marketing";
import * as TagsAPI from "config/APIs/tags";
const initialState = {
  contentOffering: [],
  campaign: [],
  character: [],
  conflict: [],
  contentPillars: [],
  contentTypes: [],
  contentPlatform: [],
  topicCluster: [],
  contentBucket: [],
  tags: [],
  tagType: [],
};

export const marketing = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    updateMarketingState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { updateMarketingState } = marketing.actions;
export default marketing.reducer;

export function fetchAllTags() {
  return async (dispatch) => {
    try {
      const response = await TagsAPI.getTags("tag");

      if (response.status === 200) {
        let data = response.data.data?.tags;

        // Dispatching tags data to redux-store
        dispatch(
          updateMarketingState({
            key: "tags",
            value: data,
          })
        );
      }
    } catch (err) {
      console.log("tags fetching error", err);
    }
  };
}

export function fetchAllTagTypes() {
  return async (dispatch) => {
    try {
      console.log("hi");
      const response = await TagsAPI.getTags("tagType");

      if (response.status === 200) {
        let data = response.data.data?.data;

        // Dispatching tags data to redux-store
        dispatch(
          updateMarketingState({
            key: "tagType",
            value: data,
          })
        );
      }
    } catch (err) {
      console.log("tags type fetching error", err);
    }
  };
}

export function fetchAllOfferings() {
  return async (dispatch) => {
    let q = {};
    try {
      const response = await MarketingAPI.getAllEntity("contentOffering", q);

      if (response.status === 200) {
        let data = response.data.data;

        // Dispatching offerings data to redux-store
        dispatch(
          updateMarketingState({
            key: "contentOffering",
            value: data,
          })
        );
      }
    } catch (err) {
      console.log("offerings fetching error", err);
    }
  };
}

export function fetchWithType(type) {
  return async (dispatch) => {
    let q = {};
    try {
      const response = await MarketingAPI.getAllEntity(type, q);

      if (response.status === 200) {
        let data = response.data.data;
        dispatch(
          updateMarketingState({
            key: type,
            value: data,
          })
        );
      }
    } catch (err) {
      console.log("update type fetching error", err);
    }
  };
}
