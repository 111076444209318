import { useState } from "react";
import Campaigns from "components/Campaigns";
import SearchBox from "components/Comman/Inputs/SearchBox";

function Campaign() {
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  return (
    <>
      <div className="px-7.5 pt-2.5">
        <SearchBox
          placeholder="Find your campaign!"
          search={search}
          setSearch={setSearch}
        />
      </div>

      <div className="max-h-70vh overflow-x-hidden mt-5">
        <Campaigns search={search} />
      </div>
    </>
  );
}

export default Campaign;
