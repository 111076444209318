import { configureStore } from "@reduxjs/toolkit";

import navigationReducer from "./navigation";
import toasterReducer from "./toaster";
import userReducer from "./user";
import campaignReducer from "./campaign";
import contentIdeaReducer from "./contentIdea";
import mediaReducer from "./media";
import marketing from "./marketing";
import quarterlyGoalReducer from "./quarterlyGoals";
import dropdown from "./dropdown";

const store = configureStore({
  reducer: {
    sideNav: navigationReducer,
    toaster: toasterReducer,
    user: userReducer,
    campaign: campaignReducer,
    contentIdea: contentIdeaReducer,
    media: mediaReducer,
    marketing: marketing,
    quarterlyGoals: quarterlyGoalReducer,
    dropdown,
  },
});

export default store;
