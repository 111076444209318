import { useEffect, useState } from "react";
// APIs
import * as MarketingAPI from "config/APIs/marketing";

// Components
import ListView from "components/Character/Card";
import SearchBox from "components/Comman/Inputs/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { updateMarketingState } from "redux/marketing";
import CharacterListLoader from "components/Comman/Loaders/CharacterListLoader";
import NewMarketingManagement from "components/Character/Modal/MarketingCreateEdit";
import AddConflicts from "components/Character/Modal/AddConflicts";
import ChronosButton from "components/Comman/Buttons";

function Characters() {
  const dispatch = useDispatch();
  const controls = useSelector((state) => state.marketing);
  const [list, setList] = useState([]);
  const [newCharacterModal, setNewCharacterModal] = useState(false);

  const [selectedConflicts, setSelectedConflicts] = useState();
  const [selectedCharForCon, setSelectedCharForCon] = useState();

  const [selectedToDeleteConflict, setSelectedToDeleteConflict] = useState();

  const [newConflictModal, setNewConflictModal] = useState(false);
  const [CharCreated, setCharCreated] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchList();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const fetchList = async () => {
    setIsFetching(true);

    try {
      const response = await MarketingAPI.getAllEntity("character");
      if (response?.data) {
        const data = response.data.data;
        setList(data);
        dispatch(updateMarketingState({ key: "character", value: data }));
      }
    } catch (err) {
      console.log("Fetch marking get all error", err);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <NewMarketingManagement
        isOpen={newConflictModal}
        closeModal={() => setNewConflictModal(false)}
        onCreate={() => fetchList()}
        character={selectedCharForCon}
        type="conflict"
        label="Conflict"
      />

      <AddConflicts
        isOpen={CharCreated ? true : false}
        closeModal={() => setCharCreated(null)}
        onAddConflict={(e) => {
          setNewConflictModal(true);
          setSelectedCharForCon(e);
          setCharCreated(null);
        }}
        character={CharCreated}
      />

      <NewMarketingManagement
        isOpen={newCharacterModal}
        closeModal={() => setNewCharacterModal(false)}
        onCreate={(e) => {
          setCharCreated(e);
          fetchList();
        }}
        label="Character"
        type="character"
      />

      <div className="px-7.5 pt-2.5">
        <SearchBox
          placeholder="Find ..."
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div className="px-8 mt-3 flex flex-row items-center justify-between">
        <h1 className="text-xs font-lato font-normal text-primary-gray-900 flex flex-row items-center">
          Showing{" "}
          <p className="font-bold ml-1.5"> {list?.length || 0} characters</p>
        </h1>
        <ChronosButton
          text="Add New"
          primary
          onClick={() => setNewCharacterModal(true)}
        />
      </div>
      <div className="max-h-70vh overflow-x-hidden block mt-5">
        {isFetching ? (
          <CharacterListLoader />
        ) : (
          <ListView
            onUpdate={fetchList}
            list={
              search?.isSearch
                ? list.filter(
                    (e) =>
                      e?.name
                        ?.toLowerCase()
                        .includes(search?.searchText?.toLowerCase()) ||
                      e?.type
                        ?.toLowerCase()
                        .includes(search?.searchText?.toLowerCase()) ||
                      e?.character
                        ?.toLowerCase()
                        .includes(search?.searchText?.toLowerCase())
                  )
                : list
            }
          />
        )}
      </div>
    </>
  );
}

export default Characters;
