import React from "react";
import { UserIcon } from "@heroicons/react/solid";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TaskStatus } from "helpers/task";
import { PencilAltIcon } from "@heroicons/react/outline";

function ViewDetails({ setEditable, fetching, details }) {
  return (
    <div className="w-full">
      {!fetching && (
        <div className="pt-3 pb-4 px-7 space-y-4 relative">
          {details?.status && (
            <p
              className="px-3 py-1 max-w-max rounded-full font-lato font-normal text-2xs"
              style={{
                color: TaskStatus.find((e) => e.value == details?.status).color,
                backgroundColor: TaskStatus.find(
                  (e) => e.value == details?.status
                ).lightColor,
              }}
            >
              {details?.status}
            </p>
          )}
          <PencilAltIcon
            className="h-4 w-4 text-caarya-red-lighter absolute top-0 right-7"
            onClick={() => {
              setEditable(true);
            }}
          />
          <div className="w-full">
            <h1 className="font-normal text-black font-lato text-xs mb-2.5">
              Due Date
            </h1>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                open={false}
                label=""
                value={details?.date}
                // onChange={(value) => {
                //   setDetails({
                //     ...details,
                //     date: value,
                //   });
                //   setCalendarOpen(false);
                //   handleUpdate();
                // }}
                renderInput={({ inputRef, inputProps }) => (
                  <div
                    // onClick={() => setCalendarOpen(true)}
                    className="flex flex-col items-start"
                  >
                    {details?.date ? (
                      <input
                        ref={inputRef}
                        {...inputProps}
                        className="text-primary-gray-450 font-lato font-normal focus:border-0 focus:ring-0 focus:outline-none bg-transparent border-0 p-0 text-2xs"
                      ></input>
                    ) : (
                      <input
                        ref={inputRef}
                        {...inputProps}
                        placeholder="Add Due Date"
                        className="text-primary-gray-450 focus:border-0 focus:ring-0 focus:outline-none bg-transparent border-0 p-0 font-poppins text-xs"
                      ></input>
                    )}
                  </div>
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="w-full space-y-1">
            <h1 className="font-normal text-black font-lato text-xs mb-2.5">
              Description
            </h1>
            {details?.description ? (
              <p
                // onClick={() => {
                //   setEdit({ ...edit, description: true });
                // }}
                className="text-primary-gray-450 font-lato font-normal text-2xs"
              >
                {details?.description}
              </p>
            ) : (
              <p
                // onClick={() => {
                //   setEdit({ ...edit, description: true });
                // }}
                className="text-primary-gray-450 font-lato font-normal text-2xs"
              >
                Add a Description
              </p>
            )}
          </div>
          <div className="w-full space-y-1">
            <h1 className="font-normal text-black font-lato text-xs mb-2.5">
              Type
            </h1>
            {details?.type ? (
              <p
                // onClick={() => {
                //   setShowEditModal({ isOpen: true, type: "Task Type" });
                // }}
                className="px-3 py-1 max-w-max mt-2 rounded bg-slate-200 text-slate-800 font-lato font-normal text-2xs"
              >
                {details?.type?.name}
              </p>
            ) : (
              <p
                // onClick={() => {
                //   setShowEditModal({ isOpen: true, type: "Task Type" });
                // }}
                className="text-primary-gray-450 font-lato font-normal text-2xs"
              >
                Add Task Type
              </p>
            )}
          </div>
          {/* <div className="w-full space-y-1">
            <div className="grid grid-cols-1 gap-4">
              <div className="col-span-1 space-y-1">
                <h1 className="font-medium text-xs">Status</h1>
                {details?.status ? (
                  <p
                    onClick={() => {
                      setShowEditModal({ isOpen: true, type: "Status" });
                    }}
                    className="px-3 py-1 max-w-max mt-2 rounded font-poppins text-sm"
                    style={{
                      color: TaskStatus.find((e) => e.value == details?.status)
                        .color,
                      backgroundColor: TaskStatus.find(
                        (e) => e.value == details?.status
                      ).lightColor,
                    }}
                  >
                    {details?.status}
                  </p>
                ) : (
                  <p
                    onClick={() => {
                      setShowEditModal({ isOpen: true, type: "Status" });
                    }}
                    className="text-primary-gray-450 font-poppins text-xs"
                  >
                    Add Status
                  </p>
                )}
              </div>
            </div>
          </div> */}
          <div className="w-full space-y-1">
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-1 space-y-1">
                <h1 className="font-normal text-black font-lato text-xs mb-2.5">
                  T-Shirt Size
                </h1>
                {details?.t_shirt_size ? (
                  <p
                    // onClick={() => {
                    //   setShowEditModal({ isOpen: true, type: "T-Shirt Size" });
                    // }}
                    className="px-3 py-1 max-w-max mt-2 rounded bg-slate-200 text-slate-800 font-lato font-normal text-2xs"
                  >
                    {details?.t_shirt_size}
                  </p>
                ) : (
                  <p
                    // onClick={() => {
                    //   setShowEditModal({ isOpen: true, type: "T-Shirt Size" });
                    // }}
                    className="text-primary-gray-450 font-lato font-normal text-2xs"
                  >
                    Add T-Shirt Size
                  </p>
                )}
              </div>
              {/* <div className="col-span-1 space-y-1">
                <h1 className="font-normal text-black font-lato text-xs mb-2.5">
                  Priority
                </h1>
                {details?.priority ? (
                  <p
                    // onClick={() => {
                    //   setShowEditModal({ isOpen: true, type: "Priority" });
                    // }}
                    className="px-3 py-1 max-w-max mt-2 rounded bg-slate-200 text-slate-800 font-lato font-normal text-2xs"
                  >
                    {details?.priority}
                  </p>
                ) : (
                  <p
                    // onClick={() => {
                    //   setShowEditModal({ isOpen: true, type: "Priority" });
                    // }}
                    className="text-primary-gray-450 font-lato font-normal text-2xs"
                  >
                    Add Priority
                  </p>
                )}
              </div> */}
            </div>
          </div>
          {details?.project && (
            <div className="w-full space-y-1">
              <h1 className="font-normal text-black font-lato text-xs mb-2.5">
                Project
              </h1>
              <p className="px-3 py-1 max-w-max mt-2 rounded bg-slate-200 text-slate-800 font-lato font-normal text-2xs">
                {details?.project?.title}
              </p>
            </div>
          )}
          <div className="w-full">
            <h1 className="font-normal text-black font-lato text-xs mb-2.5">
              Members
            </h1>
            <div className="mt-2 grid grid-cols-2 gap-4">
              {details?.students?.length > 0 &&
                details?.students.map((person) => {
                  return (
                    <div
                      className="flex flex-row items-center"
                      key={person?.id}
                    >
                      {person?.image?.url ? (
                        <img
                          src={person?.image?.url}
                          className="rounded-full h-8 w-8 mr-2"
                          alt=""
                        />
                      ) : (
                        <UserIcon className="rounded-full h-8 w-8 mr-2 theme-gray-500" />
                      )}
                      <p className="text-gray-700 font-lato font-normal text-2xs">
                        {person?.first_name}
                      </p>
                    </div>
                  );
                })}
            </div>
            {details?.students?.length === 0 && (
              <p
                // onClick={() => {
                //   setShowAddMembersModal(true);
                // }}
                className="mt-1 text-primary-gray-450 font-lato font-normal text-2xs"
              >
                Assign Users to Task
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewDetails;
