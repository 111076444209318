import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

function TopBar() {
  const onLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("navList");
    localStorage.removeItem("selectedTab");
    window.location.href = window.location.origin + "/";
  };
  return (
    <div className="fixed w-full top-0 right-0 left-0 px-3 py-2 top-nav z-30 flex lg:hidden flex-row items-stretch justify-between">
      <a href="/">
        <div className="flex flex-row items-center space-x-1.5">
          <img
            src="/assets/caaryaLogos/logo.png"
            className="h-6 w-6 object-fill"
            alt="logo"
          />
          <h1 className="font-bold font-poppins text-xl text-primary-aqua-darkest">
            Peddle
          </h1>
        </div>
      </a>

      <div className="flex flex-row items-center space-x-4">
        <img
          src="/assets/svg/navigation/bell.svg"
          className="h-4 w-4 object-cover"
          alt="logo"
        />
        <Menu as="div" className="relative block text-left">
          <Menu.Button className="">
            <img
              src="/assets/svg/navigation/menu.svg"
              className="h-4 w-4 mt-1.5 object-cover"
              alt="logo"
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{ borderRadius: "20px 0px 20px 20px" }}
              className={`origin-top-right absolute right-0 w-24 z-10 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
            >
              <div className="py-1 max-h-64 overflow-y-auto">
                {[1].map((item) => {
                  return (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => {
                            onLogout();
                          }}
                          className="text-black font-lato font-normal block px-4 py-2 text-2xs cursor-pointer"
                        >
                          Logout
                        </div>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

export default TopBar;
