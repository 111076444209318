export default function CharacterListLoader() {
  return (
    <div className="space-y-5">
      {Array.from(Array(10).keys()).map((key) => (
        <div className="w-full px-7.5" key={key}>
          <div className="bg-white shadow-container rounded-20px">
            <div className="relative group py-5 px-5">
              <div className="flex flex-col items-start w-full">
                <div className="w-24 h-2 bg-gray-300 rounded-xl animate-pulse" />
                <div className="mt-4 border-t pt-2 w-full flex flex-row">
                  <div className="w-24 h-2 bg-gray-200 rounded-xl animate-pulse" />
                  <div className="h-2 w-2 rounded-full ml-4 bg-gray-200 animate-pulse" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
