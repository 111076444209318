// Just a normal grey dropdown for filtering

const FilterDropDown = ({ label, values, onSelect }) => {
  return (
    <div
      style={{
        boxShadow:
          "2px 1px 3px 0px rgba(0, 0, 0, 0.10), -1px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px -1px 2px 0px rgba(0, 0, 0, 0.04)",
      }}
      className="border-0 bg-white rounded-lg text-xs lg:text-base text-primary-neutral-800 font-lato font-semibold"
    >
      <select
        className="bg-transparent border-0 font-semibold text-xs w-36"
        onChange={(e) => {
          onSelect(e.target.value);
        }}
      >
        <option value={label}>{label}</option>
        {values.map((value) => (
          <option
            className="text-primary-gray-600"
            id={value.value}
            key={value.value}
            value={value.value}
            onClick={() => onSelect(value.value)}
          >
            {value.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterDropDown;
