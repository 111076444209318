import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { updateList } from "redux/media";
// APIs
import * as MediaAPI from "config/APIs/media";

function Medias({ search, tab }) {
  const dispatch = useDispatch();
  const [lists, setLists] = useState([]);
  const media = useSelector((state) => state.media[tab]);
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchList();
    }

    return () => {
      isMounted = false;
    };
  }, [tab]);
  const fetchList = async () => {
    if (media?.length > 0) {
      setLists(media);
      return;
    }
    setFetching(true);
    try {
      const response = await MediaAPI.getAllMedia(tab);
      if (response?.data) {
        const data = response.data.data.news;
        setLists(data);
        dispatch(updateList({ tab, list: data }));
        setFetching(false);
      }
    } catch (err) {
      setFetching(false);
      console.log("Fetch media get all error", err);
    }
  };

  return (
    search?.isSearch
      ? lists.filter(
          (e) =>
            e?.name
              ?.toLowerCase()
              .includes(search?.searchText?.toLowerCase()) ||
            e?.description
              ?.toLowerCase()
              .includes(search?.searchText?.toLowerCase())
        )
      : lists
  ).map((item) => {
    const date = moment(item?.createdAt).format("ll");
    return (
      <>
        <div className="w-full relative">
          <div className="px-7.5 relative">
            <div className="flex flex-col w-full bg-white rounded-2xl">
              <div class="flex flex-row items-center justify-between">
                <img className="rounded-t-2xl" src={item?.image?.url}></img>
              </div>
              <div className="p-3">
                <div className="mt-2 flex flex-row items-start justify-between relative">
                  <div className="flex flex-col items-start w-11/12 space-y-2">
                    <p className="text-sm font-lato text-black cursor-pointer break-words font-bold">
                      {item?.name}
                    </p>{" "}
                  </div>
                </div>
                <p className="text-xs font-lato text-black cursor-pointer break-words font-normal">
                  {date}
                </p>{" "}
                <div className="mt-2.5 border-t border-primary-gray-250 pt-3 flex flex-row items-center justify-between">
                  <p className="text-2xs mt-1 font-lato max-w-max cursor-pointer text-primary-gray-250 font-normal">
                    {item?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  });
}

export default Medias;
