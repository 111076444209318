import React, { useState } from "react";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
function DateInput({ date, onChange }) {
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          open={calendarOpen}
          label=""
          value={date}
          onChange={(value) => {
            onChange(value);
            setCalendarOpen(false);
          }}
          renderInput={({ inputRef, inputProps }) => (
            <div
              onClick={() => setCalendarOpen(true)}
              className="flex flex-col items-start"
            >
              {date ? (
                <input
                  ref={inputRef}
                  {...inputProps}
                  className="text-primary-gray-450 font-lato font-normal focus:border-0 focus:ring-0 focus:outline-none bg-transparent border-0 p-0 text-2xs"
                ></input>
              ) : (
                <input
                  ref={inputRef}
                  {...inputProps}
                  placeholder="Add Due Date"
                  className="text-primary-gray-450 focus:border-0 focus:ring-0 focus:outline-none bg-transparent border-0 p-0 font-poppins text-xs"
                ></input>
              )}
            </div>
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export default DateInput;
