import React from "react";
import ControlList from "components/Comman/Lists/ControlList";
import { controls } from "helpers/controls";

function Control({ search }) {
  return (
    <>
      <div className="w-full relative px-7.5">
        <div className="max-h-70vh overflow-y-auto pb-5 space-y-5">
          <ControlList
            controls={
              search?.isSearch
                ? controls.filter((e) =>
                    e?.label
                      .toLowerCase()
                      .includes(search?.searchText.toLowerCase())
                  )
                : controls
            }
          />
        </div>
      </div>
    </>
  );
}

export default Control;
