import React, { useEffect, useState } from "react";

import liIcon from "assets/imgimports/icons/li_icon.svg";
import { TrashIcon } from "@heroicons/react/solid";
import AddCharacterConflict from "./AddCharacterConflict";
import ChronosButton from "components/Comman/Buttons";
import ConfirmationModal from "components/Comman/Modals/ConfirmationModals/ConfirmationModal";
import WideModalsWrapper from "components/Comman/Modals/ModalsWrapper/WideModalWrapper";
import EditCharacterConflict from "./EditCharacterConflict";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function CharacterConflicts({
  onCancel,
  onSubmit,
  updating,
  creating,
  list,
  setList,
  onUpdate,
}) {
  const { type } = useParams();
  const [characters, setCharacters] = useState([]);
  const [conflicts, setConflicts] = useState({});
  const [add, setAdd] = useState(false);
  const [change, setChange] = useState(false);
  const [edit, setEdit] = useState(false);
  const [remove, setRemove] = useState();

  useEffect(() => {
    let tempChar = list?.map((i) => i?.character);
    let tempConflict = {};
    list?.map((i) => {
      tempConflict[i?.character?.id] = i?.conflicts || [];
    });
    setCharacters(tempChar);
    setConflicts(tempConflict);
  }, [list]);

  useEffect(() => {
    if (change) {
      onChange();
      setChange(false);
    }
  }, [change]);

  const onChange = () => {
    let temp = [];
    characters?.map((item) => {
      let obj = { character: item, conflicts: conflicts[item?.id] || [] };
      temp.push(obj);
    });

    setList(temp);
  };

  return (
    <>
      <WideModalsWrapper
        isOpen={add}
        closeModal={() => setAdd(false)}
        height="100vh"
        component={
          <AddCharacterConflict
            onAdd={(char, con) => {
              setCharacters(char);
              let temp = {};
              con?.length > 0 &&
                con?.map((i) => {
                  if (Object?.keys(temp)?.includes(i?.marketingCharacterId)) {
                    temp[i?.marketingCharacterId].push(i);
                  } else {
                    temp[i?.marketingCharacterId] = [i];
                  }
                });
              console.log(temp);
              setConflicts(temp);
              setChange(true);
            }}
          />
        }
      />

      <WideModalsWrapper
        isOpen={edit ? true : false}
        closeModal={() => setEdit()}
        height="100vh"
        component={
          <EditCharacterConflict
            character={edit}
            conflicts={conflicts[edit?.id]}
            onRemove={(char) => {
              setRemove(char);
            }}
            onChange={(con) => {
              if (con?.length > 0) {
                let charId = con[0]?.marketingCharacterId;
                let temp = { ...conflicts };
                temp[charId] = con;
                setConflicts(temp);
                setChange(true);
              }
            }}
          />
        }
      />

      <WideModalsWrapper
        isOpen={remove ? true : false}
        closeModal={() => setRemove()}
        height="100vh"
        component={
          <ConfirmationModal
            yesText="Delete"
            noText="Cancel"
            onYes={() => {
              let temp = characters?.filter((i) => i?.id !== remove?.id);
              setCharacters(temp);
            }}
            onNo={() => {}}
            textComponent={
              <>
                Are you sure you want to delete the character{" "}
                <b>“{remove?.character}”</b> ?
              </>
            }
          />
        }
      />

      {/* <div className="theme-container p-7.5 relative">
        <div className="flex flex-row items-center space-x-2">
          <h1 className="inter font-normal text-xs md:text-sm font-chronos-gray-350">
            Characters & conflicts:
          </h1>
          <p
            onClick={() => setAdd(true)}
            className="text-primary-indigo-650 text-xs md:text-sm font-bold cursor-pointer inter underline transition transform ease-in-out duration-150 hover:scale-105"
          >
            Add +
          </p>
        </div>
        <div className="sm:grid grid-cols-2 gap-7 space-y-7 sm:space-y-0 mt-6">
          {characters?.map((char) => {
            return (
              <div className="character-card-container bg-white">
                <div className="upper-character-card bg-primary-gray-600 flex items-center px-4.5 py-2.5 justify-between">
                  <h1 className="upper-character-card-h1 text-white inter font-bold text-sm cursor-pointer">
                    {char.character}
                  </h1>

                  <TrashIcon
                    onClick={() => {
                      let temp = [...characters];
                      temp = temp?.filter((i) => i?.id !== char?.id);

                      let tempObj = { ...conflicts };
                      delete tempObj[char?.id];

                      setCharacters(temp);

                      setConflicts(tempObj);
                      setChange(true);
                    }}
                    className="cursor-pointer h-4 w-4 text-alert"
                    alt="add-icon"
                  ></TrashIcon>
                </div>
                <div className="px-4.5 py-2.5">
                  <p
                    onClick={() => setEdit(char)}
                    className="text-primary-indigo-650 mb-2.5 text-xs md:text-sm font-bold cursor-pointer inter underline transition transform ease-in-out duration-150 hover:scale-105"
                  >
                    Edit +
                  </p>
                  <ul>
                    {conflicts[char?.id]?.map((item) => {
                      return (
                        <div className=" flex justify-between mb-2.5 character-card-left">
                          <div className="flex">
                            <div className="character-card-li-icon flex items-center mr-1.5">
                              <img
                                className="character-card-li-icon-img"
                                src={liIcon}
                                alt="li-icon"
                              ></img>
                            </div>
                            <li className="text-primary-gray-600 character-card-li inter text-sm font-normal ">
                              {item.name}
                            </li>
                          </div>

                          <TrashIcon
                            onClick={() => {
                              let temp = { ...conflicts };
                              temp[char?.id] = temp[char?.id]?.filter(
                                (c) => c?.id !== item?.id
                              );

                              setConflicts(temp);
                              setChange(true);
                            }}
                            className="cursor-pointer h-4 w-4 text-alert"
                            alt="add-icon"
                          ></TrashIcon>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}

      <div className="flex flex-row items-center justify-center lg:justify-end space-x-4 mt-3">
        <ChronosButton
          text="Cancel"
          secondary
          onClick={() => {
            onCancel();
          }}
        />
        {updating || creating ? (
          <div className="w-5 h-5 bg-transparent border border-color-indigo-650 rounded-full border-t-0 animate-spin" />
        ) : (
          <ChronosButton
            text={type == "edit" ? "Save" : "Add"}
            primary
            onClick={() => {
              type == "edit" ? onUpdate() : onSubmit();
            }}
          />
        )}
      </div>
    </>
  );
}

export default CharacterConflicts;
