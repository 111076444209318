import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// APIs
import * as MarketingAPI from "config/APIs/marketing";

// Components
import SearchBox from "components/Comman/Inputs/SearchBox";
import ListView from "components/Comman/Lists/ListView";
import { useDispatch, useSelector } from "react-redux";

// Assets
import { ChevronRightIcon } from "@heroicons/react/outline";
import { controls } from "helpers/controls";
import { updateMarketingState } from "redux/marketing";

function ControlList() {
  const { type } = useParams();
  const dispatch = useDispatch();
  const controlsList = useSelector((state) => state.marketing);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchList();
    }

    return () => {
      isMounted = false;
    };
  }, [type]);

  const fetchList = async () => {
    if (controlsList[type]?.length > 0) {
      setList(controlsList[type]);
      return;
    }

    try {
      const response = await MarketingAPI.getAllEntity(type);
      if (response?.data) {
        const data = response.data.data;
        setList(data);
        dispatch(updateMarketingState({ key: type, value: data }));
      }
    } catch (err) {
      console.log("Fetch marking get all error", err);
    }
  };

  return (
    <>
      <div
        className={`px-7.5 py-2.5 flex flex-row space-x-1 text-xs text-primary-gray-350 font-lato`}
      >
        <ChevronRightIcon
          className="flex-shrink-0 h-4 w-4  opacity-50"
          aria-hidden="true"
        />
        <p className="opacity-50">Controls</p>
        <ChevronRightIcon
          className={`flex-shrink-0 h-4 w-4 text-primary-aqua-darkest`}
          aria-hidden="true"
        />
        <p className={"text-primary-aqua-darkest font-bold"}>
          {controls?.find((e) => e?.value == type)?.label}
        </p>
      </div>
      <div className="px-7.5 pt-2.5">
        <SearchBox
          placeholder="Find ..."
          search={search}
          setSearch={setSearch}
        />
      </div>

      <div className="max-h-70vh overflow-x-hidden block mt-5">
        <ListView
          type={type}
          list={
            search?.isSearch
              ? list.filter(
                  (e) =>
                    e?.name
                      ?.toLowerCase()
                      .includes(search?.searchText?.toLowerCase()) ||
                    e?.type
                      ?.toLowerCase()
                      .includes(search?.searchText?.toLowerCase()) ||
                    e?.character
                      ?.toLowerCase()
                      .includes(search?.searchText?.toLowerCase())
                )
              : list
          }
        />
      </div>
    </>
  );
}

export default ControlList;
