import { useState, useRef, useEffect } from "react";

/*
const tabs = [
    { label: "Draft", value: "draft" },
    { label: "Review", value: "inReview" },
    { label: "Publish", value: "published" },
  ];
*/

function Tab({ tabs, tab }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tab);
  const tabsRef = useRef([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }
    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);
  return (
    <div>
      <div className="px-5 pt-5 pb-2">
        <div className="flex flex-row space-x-3 items-center justify-between px-3 overflow-x-auto">
          {tabs.map((item, idx) => {
            return (
              <div
                key={idx}
                id={`selected-${item.value}`}
                ref={(el) => (tabsRef.current[idx] = el)}
                onClick={() => {
                  setActiveTabIndex(idx);
                  setSelectedTab(item?.value);
                }}
                className="min-w-max"
              >
                <div
                  className={`flex px-3 pb-1 flex-row cursor-pointer text-xs lg:text-sm items-end justify-center text-primary-gray-1000 font-lato text-center hover:font-bold ${
                    selectedTab == item?.value ? "font-bold" : "font-normal"
                  }`}
                >
                  {item?.label}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <span
        className="absolute rounded-sm block h-[3px] bg-primary-gray-1000 transition-all duration-300"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
    </div>
  );
}

export default Tab;
