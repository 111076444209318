import React from "react";
import { TaskStatus } from "helpers/task";
import { PencilAltIcon } from "@heroicons/react/outline";

function ViewDetails({ setEditable, fetching, details }) {
  return (
    <div className="w-full">
      {!fetching && (
        <div className="pt-3 pb-4 px-7 space-y-4 relative">
          <PencilAltIcon
            className="h-4 w-4 text-caarya-red-lighter absolute top-0 right-7"
            onClick={() => {
              setEditable(true);
            }}
          />
          <div className="w-full space-y-1">
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-1 space-y-1">
                <h1 className="font-normal text-black font-lato text-xs mb-2.5">
                  Code
                </h1>
                {details?.code ? (
                  <p className="px-3 py-1 max-w-max mt-2 rounded bg-slate-200 text-slate-800 font-lato font-normal text-2xs">
                    {details?.code}
                  </p>
                ) : (
                  <p className="text-primary-gray-450 font-lato font-normal text-2xs">
                    Add code
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="w-full space-y-1">
            <h1 className="font-normal text-black font-lato text-xs mb-2.5">
              Character
            </h1>
            {details?.character ? (
              <p className="px-3 py-1 max-w-max mt-2 rounded bg-slate-200 text-slate-800 font-lato font-normal text-2xs">
                {details?.character}
              </p>
            ) : (
              <p className="text-primary-gray-450 font-lato font-normal text-2xs">
                Add character
              </p>
            )}
          </div>
          <div className="w-full space-y-1">
            <h1 className="font-normal text-black font-lato text-xs mb-2.5">
              Description
            </h1>
            {details?.description ? (
              <p className="text-primary-gray-450 font-lato font-normal text-2xs">
                {details?.description}
              </p>
            ) : (
              <p className="text-primary-gray-450 font-lato font-normal text-2xs">
                Add description
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewDetails;
