import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ideas: [],

  fetching: true,
  // owner: {
  //   fetching: true,
  //   tasks: [],
  //   page: 0,
  //   totalPage: null,
  // },
  // creator: {
  //   fetching: true,
  //   tasks: [],
  //   page: 0,
  //   totalPage: null,
  // },
  // collaborator: {
  //   fetching: true,
  //   tasks: [],
  //   page: 0,
  //   totalPage: null,
  // },
  // completed: {
  //   fetching: true,
  //   tasks: [],
  //   page: 0,
  //   totalPage: null,
  // },
};

// Storing User's Ideas
export const contentIdea = createSlice({
  name: "ideas",
  initialState,
  reducers: {
    updateIdeas: (state, action) => {
      if (action.payload?.list) {
        state.ideas = action.payload?.ideas;
      }
    },
    updateIdeasList: (state, action) => {
      let list = action.payload.list;
      let tab = action.payload.tab;

      switch (tab) {
        case "owner":
          state.owner.tasks = list;
          break;
        case "creator":
          state.creator.tasks = list;
          break;
        case "collaborator":
          state.collaborator.tasks = list;
          break;
        case "completed":
          state.completed.tasks = list;
          break;
        default:
      }

      state.ideas = list;
    },
    // updateOwnersList: (state, action) => {
    //   state.owner.page = action.payload.data.currentPage;
    //   state.owner.totalPage = action.payload.data.totalPages;
    //   let t = [...state.owner.tasks];
    //   t = t.concat(action.payload.data.response);
    //   t = [...new Map(t.map((item) => [item["id"], item])).values()];
    //   state.owner.tasks = t?.filter((i) => i?.status !== "Completed");
    //   state.owner.fetching = false;
    //   t = [...state.tasks];
    //   t = t.concat(action.payload.data.response);
    //   t = [...new Map(t.map((item) => [item["id"], item])).values()];
    //   state.tasks = t;
    //   let c = [...state.tasks];
    //   c = c.concat(t);
    //   c = [...new Map(c.map((item) => [item["id"], item])).values()];
    //   c = c?.filter((i) => i?.status == "Completed");
    //   state.completed.tasks = c;
    // },
    // updateCreatorsList: (state, action) => {
    //   state.creator.page = action.payload.data.currentPage;
    //   state.creator.totalPage = action.payload.data.totalPages;
    //   let t = [...state.creator.tasks];
    //   t = t.concat(action.payload.data.response);
    //   t = [...new Map(t.map((item) => [item["id"], item])).values()];
    //   state.creator.tasks = t?.filter((i) => i?.status !== "Completed");
    //   state.creator.fetching = false;
    //   t = [...state.tasks];
    //   t = t.concat(action.payload.data.response);
    //   t = [...new Map(t.map((item) => [item["id"], item])).values()];
    //   state.tasks = t;
    //   let c = [...state.tasks];
    //   c = c.concat(t);
    //   c = [...new Map(c.map((item) => [item["id"], item])).values()];
    //   c = c?.filter((i) => i?.status == "Completed");
    //   state.completed.tasks = c;
    // },
    // updateCollaboratorsList: (state, action) => {
    //   state.collaborator.page = action.payload.data.currentPage;
    //   state.collaborator.totalPage = action.payload.data.totalPages;
    //   let t = [...state.collaborator.tasks];
    //   t = t.concat(action.payload.data.response);
    //   t = [...new Map(t.map((item) => [item["id"], item])).values()];
    //   state.collaborator.tasks = t?.filter((i) => i?.status !== "Completed");
    //   state.collaborator.fetching = false;
    //   t = [...state.tasks];
    //   t = t.concat(action.payload.data.response);
    //   t = [...new Map(t.map((item) => [item["id"], item])).values()];
    //   state.tasks = t;
    //   let c = [...state.tasks];
    //   c = c.concat(t);
    //   c = [...new Map(c.map((item) => [item["id"], item])).values()];
    //   c = c?.filter((i) => i?.status == "Completed");
    //   state.completed.tasks = c;
    // },
    // updateCompletedList: (state, action) => {
    //   state.completed.page = action.payload.data.currentPage;
    //   state.completed.totalPage = action.payload.data.totalPages;
    //   let t = [...state.completed.tasks];
    //   t = t.concat(action.payload.data.response);
    //   t = [...new Map(t.map((item) => [item["id"], item])).values()];
    //   state.completed.tasks = t?.filter((i) => i?.status == "Completed");
    //   state.completed.fetching = false;
    //   t = [...state.tasks];
    //   t = t.concat(action.payload.data.response);
    //   t = [...new Map(t.map((item) => [item["id"], item])).values()];
    //   state.tasks = t;
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateIdeas,
  updateIdeasList,
  // updateOwnersList,
  // updateCreatorsList,
  // updateCollaboratorsList,
  // updateCompletedList,
} = contentIdea.actions;

export default contentIdea.reducer;

// export function fetchAllTasks(assignedAs, page) {
//   return async (dispatch) => {
//     try {
//       let q = {
//         page: page + 1,
//         size: 10,
//       };
//       if (assignedAs == "completed") {
//         q["status"] = "Completed";
//       } else {
//         q["assignedAs"] = assignedAs;
//       }

//       const response = await TaskAPI.getAllTasks(q);

//       if (response.status === 200) {
//         let data = response.data.data;
//         switch (assignedAs) {
//           case "owner":
//             dispatch(updateOwnersList({ data }));
//             break;
//           case "creator":
//             dispatch(updateCreatorsList({ data }));
//             break;
//           case "collaborator":
//             dispatch(updateCollaboratorsList({ data }));
//             break;
//           case "completed":
//             dispatch(updateCompletedList({ data }));
//             break;
//           default:
//         }
//       }
//     } catch (err) {
//       console.log("task fetching error", err);
//     }
//   };
// }
