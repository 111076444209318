import { ArrowRightIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";

function ControlList({ controls }) {
  const history = useHistory();
  return (
    <>
      <div className="grid gap-5">
        {controls.length > 0 &&
          controls.map((control) => {
            if (control.value == "character") return <></>;
            return (
              <div
                key={control?.value}
                onClick={() => {
                  history.push(`/control/${control?.value}`);
                }}
                className="p-5 flex flex-row items-center justify-between w-full bg-white"
                style={{
                  borderRadius: "20px",
                }}
              >
                <p className="text-base font-medium font-lato text-black cursor-pointer break-all text-center">
                  {control.label}
                </p>
                <ArrowRightIcon className="w-4 text-black" />
              </div>
            );
          })}
      </div>
    </>
  );
}

export default ControlList;
