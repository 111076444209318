import { createSlice } from "@reduxjs/toolkit";
import { getquarterlyGoal } from "config/APIs/targets";
const initialState = {
  quarterlyGoals: [],
  objectives: [],
};

export const quarterlyGoals = createSlice({
  name: "quarterlyGoals",
  initialState,
  reducers: {
    updateQuarterlyGoalsState: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { updateQuarterlyGoalsState } = quarterlyGoals.actions;
export default quarterlyGoals.reducer;

export function fetchQGWithType(type) {
  return async (dispatch) => {
    let q = {};
    try {
      const response = await getquarterlyGoal(type, q);

      if (response.status === 200) {
        let data = response.data.data;
        dispatch(
          updateQuarterlyGoalsState({
            key: type,
            value: data,
          })
        );
      }
    } catch (err) {
      console.log("update type fetching error", err);
    }
  };
}
