import { Check, Pencil, Plus } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import React from "react";
import Wrapper from "../Common/Wrapper";
import Tags from "./Tags";
import ContentPillar from "./ContentPillar";
import ContentPlatform from "./ContentPlatform";
import ContentBucket from "./ContentBucket";
import TopicCluster from "./TopicCluster";
import { useState } from "react";
import AddMarketingAttributesModal from "components/Comman/Modals/MultipleAddModals/AddMarketingAttributes";

function ContentStrategy({ details, setDetails, onUpdate }) {
  const [editMode, setEditMode] = useState(true);
  const [editModal, setEditModal] = useState({ isOpen: false, type: "" });
  return (
    <>
      <AddMarketingAttributesModal
        isOpen={editModal?.isOpen}
        closeModal={() => {
          setEditModal({ isOpen: false });
        }}
        label={editModal?.label}
        type={editModal?.type}
        onAdd={(val) => {
          setDetails({ ...details, [editModal?.type]: val });
          setEditModal({ isOpen: false });

          onUpdate({ ...details, [editModal?.type]: val });
        }}
        selected={editModal?.selected}
      />

      <div className="p-4 bg-white rounded-2xl space-y-10">
        <div className="px-0 lg:px-6 flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:items-center justify-between text-primary-aqua-medium text-lg font-poppins font-medium">
          <p className="font-lato text-primary-neutral-500 text-xs lg:text-sm font-light">
            Here you can define the content strategy for your campaign.
          </p>
          {/* {editMode ? (
            <div className="flex flex-row items-stretch space-x-4 lg:justify-end justify-between">
              <ChronosButton
                tertiary
                underline
                text="Cancel"
                onClick={() => {
                  setEditMode(!editMode);
                }}
              />
              <ChronosButton
                text="Save Changes"
                primary
                onClick={() => {
                  if (editMode) {
                    onUpdate();
                  }
                  setEditMode(!editMode);
                }}
                iconReverse
                icon={<Check size={16} className="mr-2.5" />}
              />
            </div>
          ) : (
            <ChronosButton
              light
              text={editMode ? "Save" : "Edit Campaign Goals"}
              primary
              onClick={() => {
                if (editMode) {
                  onUpdate();
                }
                setEditMode(!editMode);
              }}
              iconReverse
              icon={<Pencil size={16} className="mr-2.5" />}
            />
          )} */}
        </div>
        <Wrapper heading="Your Topics (Tags)">
          {editMode && (
            <ChronosButton
              text="Edit Topics"
              tertiary
              underline
              onClick={() => {
                setEditModal({
                  isOpen: true,
                  type: "tags",
                  selected: details?.tags,
                  label: "Topics",
                });
              }}
              iconReverse
              icon={<Pencil size={16} className="mr-2.5" />}
            />
          )}
          <Tags tags={details?.tags} />
        </Wrapper>
        <Wrapper heading="Your Content Pillars">
          {editMode && (
            <ChronosButton
              text="Edit Content Pillars"
              tertiary
              underline
              onClick={() => {
                setEditModal({
                  isOpen: true,
                  type: "contentPillars",
                  selected: details?.contentPillars,
                  label: "Content Pillars",
                });
              }}
              iconReverse
              icon={<Pencil size={16} className="mr-2.5" />}
            />
          )}{" "}
          <ContentPillar contentPillars={details?.contentPillars} />
        </Wrapper>
        <Wrapper heading="Your Content Buckets">
          {editMode && (
            <ChronosButton
              text="Edit Content Buckets"
              tertiary
              underline
              onClick={() => {
                setEditModal({
                  isOpen: true,
                  type: "contentBuckets",
                  selected: details?.contentBuckets,
                  label: "Content Buckets",
                });
              }}
              iconReverse
              icon={<Pencil size={16} className="mr-2.5" />}
            />
          )}{" "}
          <ContentBucket contentBuckets={details?.contentBuckets} />
        </Wrapper>
        <Wrapper heading="Your Topic Clusters">
          {editMode && (
            <ChronosButton
              text="Edit Topic Clusters"
              tertiary
              underline
              onClick={() => {
                setEditModal({
                  isOpen: true,
                  type: "topicClusters",
                  selected: details?.topicClusters,
                  label: "Topic Clusters",
                });
              }}
              iconReverse
              icon={<Pencil size={16} className="mr-2.5" />}
            />
          )}{" "}
          <TopicCluster topicClusters={details?.topicClusters} />
        </Wrapper>
        <Wrapper heading="Your Platforms">
          {editMode && (
            <ChronosButton
              text="Edit Platforms"
              tertiary
              underline
              onClick={() => {
                setEditModal({
                  isOpen: true,
                  type: "contentPlatforms",
                  selected: details?.contentPlatforms,
                  label: "Content Platforms",
                });
              }}
              iconReverse
              icon={<Pencil size={16} className="mr-2.5" />}
            />
          )}
          <ContentPlatform contentPlatforms={details?.contentPlatforms} />
        </Wrapper>
      </div>
    </>
  );
}

export default ContentStrategy;
